import React, { Children, useCallback, useEffect, useState } from 'react';
import { ConvertJsonV2Component } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import { LookupsRules } from '../../../../../Rule';

const ContactValuesViewer = ({
  errors,
  formData,
  contactDetails,
  isSubmitted,
  setFormData,
  setContactDetails,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [loadings, setLoadings] = useState([]);
  const onLoadingsChanged = useCallback((value, key) => {
    setLoadings((items) => {
      const itemIndex = items.findIndex((item) => item.key === key);
      if (value) {
        const addItem = {
          key,
          value,
        };
        if (itemIndex !== -1) items[itemIndex] = addItem;
        else items.push(addItem);
      } else if (itemIndex !== -1) items.splice(itemIndex, 1);
      return [...items];
    });
  }, []);
  const onValueChanged = (item) => (newValue, itemIndex) => {
    if (!!newValue || newValue === '') {
      setContactDetails((items) => {
        items[item.field.id] = newValue;
        return { ...items };
      });
    }
  };
  const onItemChanged =
    (item, index) => (newValue, itemIndex, itemKey, parentItemKey) => {
      setFormData((elements) => {
        if (parentItemKey && elements[0][itemIndex]) {
          if (itemIndex !== undefined)
            elements[0][itemIndex][parentItemKey][itemKey] = newValue;
          else elements[0][index][parentItemKey][itemKey] = newValue;
        } else if (itemIndex && elements[0][itemIndex])
          elements[0][itemIndex][itemKey] = newValue;
        return [...elements];
      });
    };

  const lookupInit = useCallback(() => {
    LookupsRules(formData.flat(), contactDetails, onLoadingsChanged);
  }, [formData, onLoadingsChanged, contactDetails]);

  useEffect(() => {
    if (formData?.flat()?.length > 0) lookupInit();
  }, [formData, lookupInit]);

  return (
    <>
      <div className='bulk-header-section'>
        {t(`${translationPath}new-values`)}
      </div>
      <div className='bulk-sub-header-section'>
        {t(`${translationPath}add-new-value-to-the-following-fields`)}
      </div>
      <div className='bulked-units-section new-value-section mt-3'>
        {Children.toArray(
          formData
            ?.flat()
            .filter((item) => item)
            .map((item, index) => (
              <div className='mb-3'>
                <ConvertJsonV2Component
                  item={item}
                  isSubmitted={isSubmitted}
                  allItems={formData.flat().filter((element) => element)}
                  allItemsValues={contactDetails}
                  onItemChanged={onItemChanged(item, index)}
                  itemValue={contactDetails[item?.field?.id] || ''}
                  onValueChanged={onValueChanged(item, index)}
                  isLoading={
                    loadings.findIndex(
                      (element) =>
                        item && element.key === item.field.id && element.value
                    ) !== -1
                  }
                  onLoadingsChanged={onLoadingsChanged}
                  helperText={
                    errors.find(
                      (element) => item && element.key === item.field.id
                    )?.message || ''
                  }
                  error={
                    errors.findIndex(
                      (element) => item && element.key === item.field.id
                    ) !== -1
                  }
                  hideDeposit={true}
                />
              </div>
            ))
        )}
      </div>
    </>
  );
};

export default ContactValuesViewer;
