import { config } from '../config';
import { HttpServices } from '../Helper';

export const GetAllAgentsServices = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/Agents/GetAllAgents`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAgentById = async (agentId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAgentById?agentId=${agentId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateAgentById = async (agentId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/Agents/UpdateAgentInfo?agentId=${agentId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const GetAgentRotationSchemes = async (agentId, rotationNameSearch, rotationStatusSearch) => {
  try {
    const response = await HttpServices.get(`${config.server_address}/CrmDfm/Agents/GetAgentRotationSchemes/${agentId}`, {
      params: {
        rotationName: rotationNameSearch,
        rotationStatus: rotationStatusSearch,
      }
    });
    return response;
  } catch (err) {
    
  }
}; 


export const GetAllListingAgentsServices = async ({ pageSize, pageIndex, search }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAllListingAgents?pageIndex=${pageIndex + 1
    }&pageSize=${pageSize}&search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllLeaseOrSaleAgentsServices = async ({ pageSize, pageIndex, search }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAllLeaseOrSaleAgents?pageIndex=${pageIndex + 1
    }&pageSize=${pageSize}&search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllSaleAgentsServices = async ({ pageSize, pageIndex, search }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAllSaleAgents?pageIndex=${pageIndex + 1
    }&pageSize=${pageSize}&search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllLeaseAgentsServices = async ({ pageSize, pageIndex, search }) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAllLeaseAgents?pageIndex=${pageIndex + 1
    }&pageSize=${pageSize}&search=${search}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAgentsForContactLeads = async (contactId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/Agents/GetAgentsForContactLeads?contactId=${contactId}`
)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAgentsInRotationLog = async (body) => {
  const result = await HttpServices.post(`${config.server_address}/CrmDfm/Agents/GetAllAgentsInRotationLog`, body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateAgentInRotationStatus = async (body) => {
 

  const result = await HttpServices.put(`${config.server_address}/CrmDfm/Agents/UpdateAgentInRotationStatus` , body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const UpdateBulkAgentsInRotationStatus = async (body) => {
  const result = await HttpServices.put(`${config.server_address}/CrmDfm/Agents/UpdateBulkAgentsInRotationStatus`,
   body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetConvoloRotationAgents = async () => {
  const url = `${config.server_address}/CrmDfm/ConvoloAgents/GetConvoloRotationAgents`;
  const result = await HttpServices.get(url)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAgentsForConvoloRotation = async () => {
  const url = `${config.server_address}/CrmDfm/ConvoloAgents/GetAgentsForConvoloRotation`;
  const result = await HttpServices.get(url)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const AddConvoloAgent = async (agentId) => {
  const url = `${config.server_address}/CrmDfm/ConvoloAgents/AddConvoloAgent/${agentId}`;
  const result = await HttpServices.post(url)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};


export const RemoveConvoloAgents = async (body) => {
  const url = `${config.server_address}/CrmDfm/ConvoloAgents/RemoveConvoloAgents`;
  const result = await HttpServices.put(url,body)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export  const GetConvoloCallsAnalysis = async (body) =>{
  try {
    const url = `${config.server_address}/CrmDfm/ConvoloAgents/GetConvoloCallsAnalysis`;
    const result = await HttpServices.post(url,body)
    return result
  } catch (error) {
    throw error.response
  }
}