import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { Spinner, Tables } from '../../../../../Components';
import { GetParams } from '../../../../../Helper';
import { GetAllContactUnitTransactions } from '../../../../../Services';

export const DeveloperProfileAgentsOverviewComponent = ({ 
  id,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [contactId, setContactId] = useState(+GetParams('id'));
  const [agentsOverview, setAgentsOverview] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
  });
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const getAllContactUnitTransactions = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllContactUnitTransactions(filter, contactId);
    if (!(res && res.status && res.status !== 200)) {
      setAgentsOverview({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setAgentsOverview({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter, contactId]);

  useEffect(() => {
    if (contactId) getAllContactUnitTransactions();
  }, [getAllContactUnitTransactions, contactId]);

  useEffect(() => {
    setContactId(+GetParams('id'));
  }, [location]);
  return (
    <div className='childs-wrapper p-relative mt-3 px-2'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section'>
        <span>{t(`${translationPath}agents-overview`)}</span>
      </div>
      <div className='w-100 px-2'>
        <div className='filter-sections-wrapper' />
        <Tables
          data={agentsOverview.result}
          headerData={[
            { id: 1, label: 'propertyId', input: 'propertyId' },
            {
              id: 2,
              label: 'agent-name',
              input: 'agent_name',
            },
            {
              id: 3,
              label: 'staff-id',
              input: 'staff-id',
            },
            {
              id: 4,
              label: 'mobile',
              input: 'mobile',
            },
            {
              id: 5,
              label: 'email',
              input: 'Email',
            },
         
            {
              id: 6,
              isSortable: true,
              label: 'branch',
              input: 'branch',
            },
            {
              id:7,
              label:'number-of-transacted-units',
              input:'number-of-transacted-units'
            } ,
            {
              id: 8,
              isSortable: true,
              label: 'specialty-scope',
              input: 'specialty-scope',
            },

          ]}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          dateFormat='DD, MMM, YYYY'
          defaultActions={[]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={agentsOverview.totalCount}
        />
      </div>
    </div>
  );
};

DeveloperProfileAgentsOverviewComponent.propTypes = {
  id: PropTypes.number.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
