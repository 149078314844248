import React, { useEffect, useState } from "react";
import "./TransactionSummary.scss";
import { useTranslation } from "react-i18next";
import PSI_logo_md from "../../../../../assets/images/psi_logo_md.png";
import downloadIcon from "../../../../../assets/images/Propxicon/download-01.svg";
import shareIcon from "../../../../../assets/images/Propxicon/share-04.svg";
import btnLoader from '../../../../../assets/images/GIF_images/btn-loader.gif';
import {
  ExportTransactionSummary,
  GetTransactionSummary,
  ShareTransactionSummary,
} from "../../../../../Services";
import {
  getDownloadableLink,
  GetParams,
  showError,
} from "../../../../../Helper";
import moment from "moment";
import { ButtonBase } from "@material-ui/core";
import { formatCommas } from "../../../../../Helper/formatCommas.Helper";
import { TransactionDataMap } from "./Utilities/TransactionDataMap";
import { PaymentDetailsHeaderData } from "./Utilities/PaymentDetailsHeaderData";
import { Tables } from "../../../../../Components";
import { ShareDocumentsDialog } from "../../../../../SharedComponents/ShareDocumentsDialog/ShareDocumentsDialog";

export const TransactionSummary = ({ parentTranslationPath, unitTransactionId }) => {
  const translationPath = "TransactionSummary.";
  const { t } = useTranslation(parentTranslationPath);

  const [data, setData] = useState({
    transactionDetails: null,
    paymentDetails: null,
  });
  const [isLoading, setIsLoading] = useState({
    exporting: false,
    sharing: false,
  });
  const [isOpenShareDialog, setIsOpenShareDialog] = useState(false);

  const downloadPdfFile = (pdfFile) => {
    try {
      const linkElement = document.createElement("a");
      linkElement.setAttribute("download", pdfFile?.fileName);
      linkElement.href = getDownloadableLink(pdfFile?.fileId);
      document.body.appendChild(linkElement);
      linkElement.click();
      linkElement.remove();
    } catch (error) { }
  };

  const getTransactionSummary = async () => {
    const res = await GetTransactionSummary(unitTransactionId);
    if (!(res && res.status && res.status !== 200)) {
      setData({
        transactionDetails: res?.transactionDetails || null,
        paymentDetails: res?.paymentDetails || null,
      });
    } else {
      setData({
        transactionDetails: null,
        paymentDetails: null,
      });
    }
  };

  const exportTransactionSummary = async () => {
    setIsLoading((prevState) => ({ ...prevState, exporting: true }));

    const res = await ExportTransactionSummary(unitTransactionId);
    if (!(res && res.status && res.status !== 200)) {
      const file = {
        fileId: res?.item1 || null,
        fileName: res?.item2 || null,
      };
      if (file.fileName && file.fileId) downloadPdfFile(file);
    } else showError(t(`${translationPath}exporting-summary-has-failed`));
    setIsLoading((prevState) => ({ ...prevState, exporting: false }));
  };

  const shareTransactionSummary = async (body) => {
    setIsLoading((prevState) => ({ ...prevState, sharing: true }));

    const res = await ShareTransactionSummary(unitTransactionId, body);
    // if (!(res && res.status && res.status !== 200)) {
    //   showError(t(`${translationPath}shared-summary-successfully`));
    // } else showError(t(`${translationPath}sharing-summary-has-failed`));

    setIsLoading((prevState) => ({ ...prevState, sharing: false }));
  };

  const getFormattedContent = (data, mapItem) => {
    switch (mapItem.type) {
      case "basic-text":
        return (
          <>
            {mapItem.startAddition ? (
              <span className="mr-2">{mapItem.startAddition}</span>
            ) : null}
            <span>{data[mapItem.key] || "-"}</span>
            {mapItem.endAddition ? (
              <span className="ml-2">{mapItem.endAddition}</span>
            ) : null}
          </>
        );
      case "number":
        return (
          <>
            {mapItem.startAddition ? (
              <span className="mr-2">{mapItem.startAddition}</span>
            ) : null}
            <span>
              {data[mapItem.key] ? formatCommas(data[mapItem.key]) : "0"}
            </span>
            {mapItem.endAddition ? (
              <span className="ml-2">{mapItem.endAddition}</span>
            ) : null}
          </>
        );
      case "date":
        return (
          <span>
            {data[mapItem.key] ? moment(data[mapItem.key]).format("LL") : "-"}
          </span>
        );
      case "sub-content":
        return (
          <>
            {mapItem.subContent?.map((subContentItem) => (
              <p className="fz-12 fw-simi-bold m-0">
                <span className="light-text">{t(`${translationPath}${subContentItem.label}`)}</span>
                <span>{" : "}</span>
                <span>{getFormattedContent(data, subContentItem)}</span>
              </p>
            ))}
          </>
        );
    }
  };

  useEffect(() => {
    if(unitTransactionId) getTransactionSummary();
  }, [unitTransactionId]);

  return (
    <div className="TransactionSummary d-flex-column gap-16 mt-3">
      <div className="hover-effect">
        <div className="d-flex-center mb-3">
          <ButtonBase
            onClick={exportTransactionSummary}
            className="btns theme-propx outlined px-3 p-1 action-button "
          >
            {t(`${translationPath}Export`)}
            {isLoading.exporting ? (
              <img
                src={btnLoader}
                className="dark-loader ml-1"
                width="12"
                height="12"
              />
            ) : (
              <img
                src={downloadIcon}
                className="action-icon"
                title="Export"
                alt="Export button"
              />
            )}
          </ButtonBase>
          <ButtonBase
            onClick={() => setIsOpenShareDialog(true)}
            className="btns theme-propx outlined px-3 p-1 action-button "
          >
            {t(`${translationPath}Share`)}
            {isLoading.sharing ? (
              <img
                src={btnLoader}
                className="dark-loader ml-1"
                width="12"
                height="12"
              />
            ) : (
              <img
                src={shareIcon}
                className="action-icon"
                title="Share"
                alt="Share button"
              />
            )}
          </ButtonBase>
        </div>
        <div className="hover-container d-flex-column gap-16 p-3">
          <div class="summary_banner flex-wrap">
            <div class="banner_content">
              <div class="banner_logo mr-3">
                <img src={PSI_logo_md} alt="psi-Logo" />
              </div>
              <div>
                <h2>Property Shop Investment</h2>
                <p>Leasing deal summary financial checklist (new lease)</p>
              </div>
            </div>

            {data.transactionDetails?.contractStartDate ? (
              <div class="banner_date">
                <span className="mx-1">Start</span>
                <span>
                  {moment(data.transactionDetails?.contractStartDate).format(
                    "LL"
                  )}
                </span>
              </div>
            ) : null}
            {data.transactionDetails?.contractEndDate ? (
              <div class="banner_date">
                <span className="mx-1">Exp</span>
                <span>
                  {moment(data.transactionDetails?.contractEndDate).format(
                    "LL"
                  )}
                </span>
              </div>
            ) : null}
          </div>
          {data.transactionDetails?.annualRent ? (
            <div>
              <p className="light-text fz-14">Annual rent</p>
              <p className="fw-simi-bold fz-18">
                {`${formatCommas(data.transactionDetails.annualRent)} AED`}
              </p>
            </div>
          ) : null}
          <div className="summary-cards-wrapper d-flex gap-12 flex-wrap">
            {data.transactionDetails &&
              Object.values(TransactionDataMap).map((item) => (
                <div className="summary-card" key={item.label}>
                  <p className="light-text fz-12 fw-simi-bold m-0">
                    {t(`${translationPath}${item.label}`)}
                  </p>
                  <div className="fz-14 fw-simi-bold m-0">
                    {getFormattedContent(data.transactionDetails, item)}
                  </div>
                </div>
              ))}
          </div>
          {data.paymentDetails?.length > 0 ? (
            <>
              <div className="d-flex mt-3">
                <p className="fw-simi-bold light-text m-0  fas-center  mx-2">
                  {t(`${translationPath}Payment-Details`)}
                </p>
              </div>
              <div className="d-flex-column gap-16">
                <Tables
                  data={data.paymentDetails || []}
                  headerData={PaymentDetailsHeaderData}
                  itemsPerPage={data.paymentDetails?.length || 0}
                  activePage={1}
                  totalItems={data.paymentDetails?.length || 0}
                  defaultActions={[]}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      {isOpenShareDialog && (
        <ShareDocumentsDialog
          open={isOpenShareDialog}
          theme="propx"
          shareViaExternalAPI
          onSave={(body) => {
            shareTransactionSummary(body);
            setIsOpenShareDialog(false);
          }}
          close={() => setIsOpenShareDialog(false)}
        />
      )}
    </div>
  );
};
