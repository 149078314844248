import React, { useState, useEffect, useRef } from "react";
import { TextField, Icon, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";

import { useSelectedTheme, useOutsideClick } from "../../../Hooks";
import { CloseXIcon, DropdownIcon } from "../../../assets/icons";

import useStyles from "./styles";

export default function CustomAutocomplete({
  variant = "default",
  LeftIcon = null,
  onChange,
  options = [],
  showDropdownIcon = true,
  showCloseIcon = true,
  hasNoBorder = false,
  hideInputValue = false,
  open = false,
  listOfInputs = [],
  placeholder = "Enter value",
  style = {},
}) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);
  const inputRef = useRef(null);

  const [selectedValue, setSelectedValue] = useState(null);
  const [isOpen, setIsOpen] = useState(open);

  const handleChange = (event, newValue) => {
    setSelectedValue(hideInputValue ? newValue.name : newValue);

    if (onChange) {
      onChange(newValue.name);
    }
  };

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
    iconRef,
  });

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Box ref={dropdownRef}>
      <Autocomplete
        className={clsx(
          styles.autoCompleteWrapper,
          hasNoBorder ? styles.noPadding : styles.withDefaultPadding,
          {
            [styles.iconVariant]: variant === "icon",
          }
        )}
        id="Autocomplete"
        options={
          listOfInputs?.length > 0
            ? listOfInputs?.map((field, index) => ({
                name: `Custom Input ${index + 1}`,
                isDatePicker: true,
                component: field,
              }))
            : options
        }
        open={isOpen}
        closeIcon={
          showCloseIcon ? (
            <CloseXIcon
              width="16"
              height="16"
              fill={palette.foreground.quarterary}
            />
          ) : null
        }
        popupIcon={
          showDropdownIcon ? (
            <DropdownIcon
              width="20"
              height="20"
              fill={palette.foreground.quarterary}
            />
          ) : null
        }
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          if (open) setIsOpen(open);
          else setIsOpen(false);
        }}
        getOptionLabel={
          (option) => (option?.isDatePicker ? "" : option?.name || "") // Empty string for CustomInput option
        }
        style={style}
        value={selectedValue}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              classes: {
                input: styles.placeholder,
              },
              className: clsx(params.InputProps.className, {
                [styles.selected]: selectedValue !== null,
                [styles.noBorder]: hasNoBorder,
              }),
              startAdornment: LeftIcon && variant === "icon" && LeftIcon,
            }}
          />
        )}
        renderOption={(option) =>
          option.isDatePicker ? option.component : option.name
        }
        classes={
          listOfInputs?.length > 0
            ? {
                paper: styles.paper,
                listbox: styles.listBox,
                option: styles.option,
              }
            : {}
        }
      />
    </Box>
  );
}
