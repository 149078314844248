import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { ButtonBase } from '@material-ui/core';
import { InputAdornment } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  CheckboxesComponent
} from "../../../../../Components";
import {
  GetParams,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../Helper";
import { CommissionDetailsValues  } from './CommissionDetailsComponent' ; 

export const CommissionDetailsView = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
   const  [isLoading , setIsLoading] = useState({allCommissionDetails : false , save : false  , info : false , properties : false }) ;
   const [isOpenEditDialog ,  setIsOpenEditDialog] = useState(false);

  const [items , setItems] = useState({
    properties :[] 
  })  ; 

  const [defaultSelected, setDefaultSelected] = useState({
    properties: null,
    commissions :  [
      {
      valueFrom: null,
      valueTo: null,
      commission  : null , 
      paymentTerms : null
     }

    ] , 
    isCommissionForAllPoperties : false  , 
    projectsNames: [],
    remarks: null,
    
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultSelected,
  });


  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <div className="w-100">
          {/* <div className="part1 mt-2 mb-2">
            
            <div className="px-2 item mt-1">
            <CheckboxesComponent
                idRef='isCommissionForAllPopertiesRef'
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                label='is-commission-for-all-poperties'
                singleChecked={state.isCommissionForAllPoperties}
                onSelectedCheckboxClicked={() => {
                }}
              />
            </div>

            <div className="px-2 item">
              <AutocompleteComponent
                key={`projectsNames`}
                selectedValues={state.projectsNames}
                idRef={`projectsNamesRef`}
                multiple
                inputPlaceholder={t("select-property")}
                data={items &&  items.projectsNames}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) => (option && option.name) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.properties}
                withLoader
                withoutSearchButton
                labelValue={"projectsNames"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.properties?.length === 0 ){

                  }
                }}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                idRef={`remarks`}
                labelClasses=""
                key={`remarks`}
                labelValue={"remarks"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="mt-3 addBtn">
            <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              //saveAgreementDetails() 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-commission-details`)}
          </ButtonBase>
          <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              //saveAgreementDetails() 
              setIsOpenEditDialog(true) ; 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-commission-details`)}
          </ButtonBase>
          </div>
          </div> */}
        </div>
      </div>
      {/* <AgreementDetailsTable 
       translationPath={translationPath}
       parentTranslationPath={parentTranslationPath}
      />
      
        {
          isOpenEditDialog && (
            <AgreementDetailsManagementDialog
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            isOpen ={isOpenEditDialog}
            onClose={()=> setIsOpenEditDialog(false)}
            onSave={()=> setIsOpenEditDialog(false)}
            />
          )
        
        } */}
    </div>
  );
};
