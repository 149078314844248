import React, { useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import Joi from "joi";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import {
  Spinner,
  Inputs,
  AutocompleteComponent,
  DatePickerComponent,
} from "../../../../../../Components";
import { showSuccess, showError } from "../../../../../../Helper";
import { GetLookupItemsByLookupTypeName } from '../../../../../../Services' ; 
import { FooterWrapperComponent } from '../../../DevelopersUtilities/Components' ; 


export const BranchLocationManagementDialog = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,

}) => {
  const { t } = useTranslation(parentTranslationPath);
  const  [isLoading , setIsLoading] = useState({save : false  , info : false}) ;
  const [items , setItems] = useState({
   countries :[]  , 
   cities : [] , 
   districts : [] , 
   communities : [] ,
   subCommunities : []

 })  ; 

 const [defaultSelected, setDefaultSelected] = useState({
   employeeName :  null , 
   jobTitle : null , 
   emailAddress : null ,  
   mobileNumber : null   , 
   linkedInProfile  : null , 
   employeePhoto : null 
  
 });

 const reducer = (select, action) => {
   if (action.id !== "edit") return { ...select, [action.id]: action.value };
   if (action.id === "edit") {
     return {
       ...action.value,
     };
   }
 };

 const [state, setState] = useReducer(reducer, {
   ...defaultSelected,
 });

 const getLookupItemsByName = async (lookUpName , key ) => {

   setIsLoading((item) => ({ ...item, [key]: true }));
   const result = await GetLookupItemsByLookupTypeName({
     lookUpName,
     pageSize: 100,
     pageIndex: 1,
   });
   if (!(result && result.status && result.status !== 200)) {
       setItems((item) => ({ ...item, [key]: (result && result.result) || []   }));
   } 
   else  setItems((item) => ({ ...item, [key]: []}));
   setIsLoading((item) => ({ ...item, [key]: false }));

 };

  const schema = Joi.object({
    employeeName: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}developer-Employees-name-is-required`),
        "string.empty": t(`${translationPath}developer-Employees-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const saveHandler = async () => 
  {

  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        className="activities-management-dialog-wrapper"
        maxWidth="sm"
        disableBackdropClick
      >
        <Spinner isActive={isLoading.info} isAbsolute />
        <DialogTitle id="alert-dialog-slide-title">
          <span>{t(`${translationPath}edit-branch-name`)}</span>
        </DialogTitle>
        <DialogContent>
          <div className="dialog-content-wrapper">
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"branchNameRef"}
                labelValue={"branch-name"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item">
            <AutocompleteComponent
                key={`country`}
                selectedValues={state.country}
                idRef={`countryRef`}
                multiple={false}
                inputPlaceholder={t("country")}
                data={items &&  items.countries}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.countries}
                withLoader
                withoutSearchButton
                labelValue={"country"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.countries?.length === 0 )
                 getLookupItemsByName('country' , 'countries');
                }}
              />
            </div>
            <div className="dialog-content-item">
            <AutocompleteComponent
                key={`city`}
                selectedValues={state.city}
                idRef={`cityRef`}
                multiple={false}
                inputPlaceholder={t("city")}
                data={items &&  items.cities}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.cities}
                withLoader
                withoutSearchButton
                labelValue={"city"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.cities?.length === 0 )
                 getLookupItemsByName('city' , 'cities');
                }}
              />
            </div>
            <div className="dialog-content-item">
            <AutocompleteComponent
                key={`district`}
                selectedValues={state.district}
                idRef={`districtRef`}
                multiple={false}
                inputPlaceholder={t("district")}
                data={items &&  items.districts}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.districts}
                withLoader
                withoutSearchButton
                labelValue={"district"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.districts?.length === 0 )
                 getLookupItemsByName('district' , 'districts');
                }}
              />
            </div>
            <div className="dialog-content-item">
            <AutocompleteComponent
                key={`community`}
                selectedValues={state.community}
                idRef={`communityRef`}
                multiple={false}
                inputPlaceholder={t("community")}
                data={items &&  items.communities}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.communities}
                withLoader
                withoutSearchButton
                labelValue={"community"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.communities?.length === 0 )
                 getLookupItemsByName('community' , 'communities');
                }}
              />
            </div>
            <div className="dialog-content-item">
            <AutocompleteComponent
                key={`subcommunities`}
                selectedValues={state.subcCommunity}
                idRef={`subcommunitiesRef`}
                multiple={false}
                inputPlaceholder={t("subcommunities")}
                data={items &&  items.communities}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.subcommunities}
                withLoader
                withoutSearchButton
                labelValue={"subCommunity"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.subCommunities?.length === 0 )
                 getLookupItemsByName('subcommunity' , 'subcommunities');
                }}
              />
            </div>
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"StreetRef"}
                labelValue={"Street"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"BuildingNameRef"}
                labelValue={"building-name"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"BuildingNumberRef"}
                labelValue={"building-number"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"FloorNumberRef"}
                labelValue={"floor-number"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"OfficeNumberRef"}
                labelValue={"office-number"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"MapCoordinatesRef"}
                labelValue={"map-coordinates"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"Postal-ZIPCodeRef"}
                labelValue={"postal-zip-code"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="dialog-content-item">
            <Inputs
                isAttachedInput
                idRef={"POBoxRef"}
                labelValue={"po-box"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <FooterWrapperComponent
             parentTranslationPath={parentTranslationPath} 
             translationPath={translationPath}
             onClose={onClose} 
             onSave={onSave}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

BranchLocationManagementDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editPortalConfiguration: PropTypes.instanceOf(Object),
  isLoading: PropTypes.instanceOf(Object),
};
BranchLocationManagementDialog.defaultProps = {
  editPortalConfiguration: undefined,
  isLoading: undefined,
};
