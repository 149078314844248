import React, {
  useCallback, useState, useReducer, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Joi from 'joi';
import {
   ButtonBase
} from '@material-ui/core';
import { HistoryTabsComponent } from '../../../../../../Components/HistoryComponent/HistoryTabsComponent';
import { Spinner, TabsComponent, DialogComponent } from '../../../../../../Components';
import {
 showError, showSuccess, getErrorByName, returnPropsByPermissions
} from '../../../../../../Helper';
import { RotationCriteriaDialogContent } from './RotationCriteriaDialogContent';
import { SchemaSetting } from './SchemaSetting';
import {
  CreateRotationSchemeServices,
  DeleteAgentByRotationScheme,
  UpdateRotationScheme,
} from '../../../../../../Services/RotaionSchemaService/RotationSchemaService';
import { RotationSchemaPermissions } from '../../../../../../Permissions';

export const RotationCriteriaDialog = ({
  parentTranslationPath,
  translationPath,
  open,
  close,
  onSave,
  rotationEdit,
  isEdit,
  setRotationEdit,
}) => {
  const [validation, setValidation] = useState(false);
  const [isOpenHistoryDialog, setIsOpenHistoryDialog] = useState(false);

  const [SelectedisBulk, setSelectedisBulk] = useState({ lookupItemName: 'No', lookupItemId: 0 });
  const [DoDeleteAPI, setDoDeleteAPI] = useState([]);
  const [currentSelected, setCurrentSelected] = useState({
    propertyCampaignIds: [],
    rotationSchemaMethodOfContacts: [],
    rotationSchemaLeadsType: [],
    rotationPreferredLanguages: [],
    rotationSchemeBranches: [],
    rotationSchemeTeams: [],
  });
  useEffect(() => {
    if (rotationEdit === undefined || rotationEdit === null) return;
    const currntState = {
      rotationSchemeId: null,
      label: '',
      rotationPreferredLanguages: [],
      rotationSchemeCountries: [],
      isForOwnerLeads: false,
      rotationSchemeCities: [],
      rotationSchemeCommunities: [],
      rotationSchemeDistricts: [],
      rotationSchemeSubCommunities: [],
      rotationSchemeUnitTypes: [],
      rotationSchemeRanges: [],
      rotationSchemaContactCLasses: [],
      rotationSchemeMedias: [],
      rotationSchemaDeveloperIds: [],
      rotationSchemaReferredBys: [],
      rotationSchemeProperties: [],
      rotationSchemaLeadsType: [],
      rotationSchemaMethodOfContacts: [],
      propertyCampaignIds: [],
      isActive: false,
      rotationSchemeTeams: [],
      rotationSchemeBranches: [],
      sla: null,
      isBulk: 0,
      isOverrideLeadCapacitySettingEnabled: true,
      rotationSchemeLeadDistributionType: 4,
    };
    currntState.rotationSchemeId = rotationEdit.rotationSchemeId;
    if (rotationEdit) {
      rotationEdit.languages.map((item) => {
        currntState.rotationPreferredLanguages.push({
          languageName: item.lookupItemName,
          languageId: item.lookupsId,
        });
      });
      rotationEdit.countries.map((item) => {
        currntState.rotationSchemeCountries.push({
          countryName: item.lookupItemName,
          countryId: item.lookupsId,
        });
      });
      rotationEdit.cities.map((item) => {
        currntState.rotationSchemeCities.push({
          cityName: item.lookupItemName,
          cityId: item.lookupsId,
        });
      });
      rotationEdit.districts.map((item) => {
        currntState.rotationSchemeDistricts.push({
          districtName: item.lookupItemName,
          districtId: item.lookupsId,
        });
      });
      rotationEdit.communities.map((item) => {
        currntState.rotationSchemeCommunities.push({
          communityName: item.lookupItemName,
          communityId: item.lookupsId,
        });
      });
      rotationEdit.subCommunities.map((item) => {
        currntState.rotationSchemeSubCommunities.push({
          subCommunityName: item.lookupItemName,
          subCommunityId: item.lookupsId,
        });
      });
      rotationEdit.leadClasses.map((item) => {
        currntState.rotationSchemaContactCLasses.push({
          contactClassId: item.lookupsId,
          contactClassName: item.lookupItemName
        });
      });
      rotationEdit.unitTypes.map((item) => {
        currntState.rotationSchemeUnitTypes.push({
          unitTypeName: item.lookupItemName,
          unitTypeId: item.lookupsId,
        });
      });
      rotationEdit.developers.map((item) => {
        currntState.rotationSchemaDeveloperIds.push({
          developerName: item.developerName,
          developerId: item.developerId,
        });
      });

      rotationEdit.properties.map((item) => {
        currntState.rotationSchemeProperties.push({
          propertyName: item.propertyName,
          propertyId: item.propertyId,
        });
      });

      rotationEdit.referredBys.map((item) => {
        currntState.rotationSchemaReferredBys.push({
          userId: item.userId,
          fullName: item.fullName
        });
      });

      rotationEdit.rotationSchemeTeams.map((item) => {
        currntState.rotationSchemeTeams.push({
          teamId: item.teamId,
          teamName: item.teamName
        });
      });

      rotationEdit.rotationSchemeBranches.map((item) => {
        currntState.rotationSchemeBranches.push({
          branchId: item.branchId,
          branchName: item.branchName
        });
      });

      rotationEdit.medias.map((item) => {
        currntState.rotationSchemeMedias.push({
          mediaName: item.mediaName,
          mediaNameId: item.mediaId === 0 ? null : item.mediaId,
          mediaDetailsName: item.mediaDetails,
          mediaDetailsId: item.mediaDetailsId === 0 ? null : item.mediaDetailsId,
        });
      });
      rotationEdit.rotationSchemeRanges.map((item) => {
        currntState.rotationSchemeRanges.push({
          ...item,
        });
      });
      rotationEdit.methodOfContact.map((item) => {
        currntState.rotationSchemaMethodOfContacts.push({
          methodOfContactName: item.methodOfContactName,
          methodOfContactId: item.methodOfContactId
        });
      });
      rotationEdit.rotationSchemeCampaigns.map((item) => {
        currntState.propertyCampaignIds.push(item.propertyCampaignId);
      });
      rotationEdit.leadsType.map((item) => {
        currntState.rotationSchemaLeadsType.push({
          leadClass: item.leadClass.toLowerCase()
        });
      });
      const isBulkValue = (rotationEdit && rotationEdit.isBulk === true && {
        lookupItemName: 'Yes',
        lookupItemId: 1
      } ||
        rotationEdit && rotationEdit.isBulk === false && {
          lookupItemName: 'No',
          lookupItemId: 0
        } ||
        null);

      setSelectedisBulk(
        isBulkValue
      );
      currntState.label = rotationEdit.label;
      currntState.sla = rotationEdit.sla;
      currntState.order = rotationEdit.order;
      currntState.isActive = rotationEdit.isActive;
      currntState.isBulk = rotationEdit.isBulk;
      currntState.isOverrideLeadCapacitySettingEnabled = rotationEdit.isOverrideLeadCapacitySettingEnabled;
      currntState.rotationSchemeLeadDistributionType = rotationEdit.rotationSchemeLeadDistributionType;
      setState({ id: 'edit', value: currntState });
      setCurrentSelected((e) => ({
        ...e,
        propertyCampaignIds: rotationEdit && rotationEdit.rotationSchemeCampaigns || [],
        rotationSchemaLeadsType: rotationEdit && rotationEdit.leadsType || [],
        rotationSchemaMethodOfContacts: rotationEdit && rotationEdit.methodOfContact || [],
        rotationPreferredLanguages: rotationEdit && rotationEdit.languages || [],
        rotationSchemeBranches: rotationEdit && rotationEdit.rotationSchemeBranches || [],
        rotationSchemeTeams: rotationEdit && rotationEdit.rotationSchemeTeams || [],
      }));
    }
  }, [rotationEdit]);
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsDisabled, setSaveIsDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [state, setState] = useReducer(reducer, {
    rotationSchemeId: null,
    label: '',
    order: 0,
    isForOwnerLeads: false,
    rotationPreferredLanguages: [],
    rotationSchemeCountries: [],
    rotationSchemeCities: [],
    rotationSchemeCommunities: [],
    rotationSchemeDistricts: [],
    rotationSchemeSubCommunities: [],
    rotationSchemeUnitTypes: [],
    rotationSchemeRanges: [],
    rotationSchemaContactCLasses: [],
    rotationSchemeMedias: [],
    rotationSchemaDeveloperIds: [],
    rotationSchemaReferredBys: [],
    rotationSchemeProperties: [],
    rotationSchemaLeadsType: [],
    rotationSchemaMethodOfContacts: [],
    propertyCampaignIds: [],
    rotationSchemeTeams: [],
    rotationSchemeBranches: [],
    sla: null,
    isActive: false,
    isBulk: 0,
    rotationSchemeLeadDistributionType: 4,
    isOverrideLeadCapacitySettingEnabled: true,
  });

  const schema = Joi.object({
    label: Joi.string()
      .required()
      .messages({
        'string.empty': t`${translationPath}label-is-required`,
      }),
    order: Joi.number()
      .required()
      .min(1)
      .messages({
        'number.base': t(`${translationPath}order-is-required`),
        'number.empty': t`${translationPath}order-is-required`,
      }),
    rotationSchemaReferredBys: Joi.array()
      .required()
      .min(1)
      .messages({
        'array.empty': t(`${translationPath}ReferredBy-is-required`),
        'array.min': t(`${translationPath}ReferredBy-is-required`),
      }),
    rotationSchemaContactCLasses: Joi.array()
      .required()
      .min(1)
      .messages({
        'array.empty': t(`${translationPath}lead-class-is-required`),
        'array.min': t(`${translationPath}lead-class-is-required`),
      }),
    rotationSchemaLeadsType: Joi.array()
      // .items(Joi.object({ leadClass: Joi.any() }))
      .required()
      .min(1)
      .messages({
        'array.empty': t(`${translationPath}lead-type-is-required`),
        'array.min': t(`${translationPath}lead-type-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const onStateChanged = (newValue) => {
    setState(newValue);
  };
  const onScemaChanged = (newValue, type) => {
    setDoDeleteAPI(newValue);
  };
  const saveHandler = useCallback(async () => {
    setValidation(true);
    setSaveIsDisabled(true);
    setIsSubmitted(true);
    setIsLoading(true);
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      setSaveIsDisabled(false);
      setIsLoading(false);
      return;
    }
    setTimeout(async () => {
      const rotationSchemeId = rotationEdit ? rotationEdit.rotationSchemeId : 0;
      const res = rotationEdit ?
        await UpdateRotationScheme(rotationSchemeId, state)
        && DoDeleteAPI === 'edit' && await DeleteAgentByRotationScheme(rotationSchemeId) :
        await CreateRotationSchemeServices(state);
      if (!(res && res.status && res.status !== 200)) {
        if (!rotationEdit) showSuccess(t`${translationPath}Create-Rotation-Scheme-successfully`);
        else showSuccess(t`${translationPath}Edit-Rotation-Scheme-successfully`);
        onSave();
        close();
      } else {
        setSaveIsDisabled(false);
        const errorMessage = (res && res.data && res.data.Message).split(': ')[1];
        if (errorMessage === '___DUPLICATE_ROTATION_SCHEME_ORDER___') showError(t(`${translationPath}${errorMessage}`));
        else showError(t('Shared:Server-Error'));
        setIsLoading(false);
      }
    }, 1000);
    setIsLoading(false);
  }, [state]);
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };

  const rotaionDataList = [
    {
      label: 'rotationCriteria',
      component: RotationCriteriaDialogContent,
    },
    {
      label: 'schemaSetting',
      component: SchemaSetting,
    },
  ];
  useCallback(() => {
    setValidation(true);
  }, []);
  return (
    <div>
      <Spinner isActive={isLoading} />
      <DialogComponent
        titleText={(!rotationEdit ? 'add-new-rotation-criteria' : 'Edit-rotation-criteria')}
        saveText='save'
        cancelText='cancel'
        saveType='button'
        maxWidth='lg'
        subTitle={(rotationEdit ? rotationEdit.label : '')}
        subTitleClass='sub-title-wrapper'
        wrapperClasses='rotationCriteriaDialog-dialog-wrapper'
        titleTextClasses='title-text'
        contentClasses='content-wrapper'
        dialogContent={(
          <div>
            {

              rotationEdit && returnPropsByPermissions(RotationSchemaPermissions.RotationSchemaHistory.permissionsId) && (
                <div className='d-flex-v-center-h-end flex-wrap'>
                  <span className='completed-history-wrapper'>
                    <ButtonBase onClick={() => setIsOpenHistoryDialog(true)} className='btns c-black-light history-button'>
                      <span className='mdi mdi-clock-time-four-outline' />
                      {t`${translationPath}history`}
                    </ButtonBase>
                  </span>
                </div>
             )
            }

            <TabsComponent
              data={rotaionDataList}
              labelInput='label'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              themeClasses='theme-curved'
              currentTab={activeTab}
              onTabChanged={onTabChanged}
              dynamicComponentProps={{
              parentTranslationPath,
              translationPath,
              setActiveTab,
              onStateChanged,
              onScemaChanged,
              state,
              schema,
              parentTranslationPath,
              translationPath,
              isSubmitted,
              rotationEdit,
              currentSelected,
              setCurrentSelected,
              SelectedisBulk,
              setSelectedisBulk,
              validation
            }}
            />

          </div>

        )}
        saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
        cancelClasses='btns cancel-btn-wrapper'
        isOpen={open}
        onSaveClicked={() => { saveHandler(); setIsLoading(true); setIsLoading(false); }}
        onCloseClicked={close}
        onCancelClicked={close}
        saveIsDisabled={saveIsDisabled}
        SmothMove
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        disableBackdropClick
      />
      <HistoryTabsComponent
        isOpen={isOpenHistoryDialog}
        isOpenChanged={() => setIsOpenHistoryDialog(false)}
        typeId='rotationSchema'
        rotationSchemeId={(rotationEdit && rotationEdit.rotationSchemeId)}
      />

    </div>
  );
};
RotationCriteriaDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  rotationEdit: PropTypes.instanceOf(Object),
  isEdit: PropTypes.bool,
};
RotationCriteriaDialog.defaultProps = {
  rotationEdit: null,
  isEdit: false,
  setRotationEdit: () => { },
};
