import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useReducer,
  } from "react";
  import { useSelector } from "react-redux";
  import moment from "moment";
  import { useTranslation } from "react-i18next";
  import {
    Tables
  } from "../../../../../Components";
  import {
    GetParams,
    showError,
    showSuccess,
    showWarn,
  } from "../../../../../Helper";
  import { TableActions } from '../../../../../Enums'; 
  import { MessageConfirmDialog } from "../../../../../SharedComponents";


  
  export const AgreementDetailsTable = ({
    translationPath,
    parentTranslationPath,
  }) => {
    const { t } = useTranslation(parentTranslationPath, "Shared");  
    const [allAgreementDetails , setAllAgreementDetails ] = useState({ result :  [] , totalCount : 0  }) ; 
    const [filter , setFilter] = useState({pageIndex  : 0 , pageSize :  25 ,  search :''});
    const [ activeItem ,  setActiveItem ] = useState(null) ; 
    const [isOpenConfirmDialog , setIsOpenConfirmDialog ] = useState(false);
    const [isMissingInfoMSGOpen , setIsMissingInfoMSGOpen] = useState(false) ; 


    const DeleteHandler = useCallback(async () => {  
        // const res = await DeleteGeneralPropertyCriteria(
        //   activeItem && activeItem.propertyRatingGeneralPropertyCriteriaScoreId
        // );
        // if (res && res.ErrorId) {
        //   showError(t(`${translationPath}failed-to-delete-property-age`));
        // } else if (!(res && res.status && res.status !== 200)) {
        //   setIsMissingInfoMSGOpen(false) ; 
        //   showSuccess(t(`${translationPath}property-age-deleted-sucessfuully`));
       
        // } else showError(t(`${translationPath}failed-to-delete-property-age`));
      },[activeItem]);


    const tableActionClicked = useCallback(
        (actionEnum, item, focusedRow, event) => {
          event.stopPropagation();
          event.preventDefault();
          setActiveItem(item);
          if (actionEnum === TableActions.deleteText.key) {
            setIsMissingInfoMSGOpen(true);
          } else if (actionEnum === TableActions.editText.key)
            {
            
           }
        },
        []
      );

      const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
      };
      const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
      };


  
    return (
      <div className="d-flex-column mt-2">
        <Tables
            data={allAgreementDetails ? allAgreementDetails.result : []}
            headerData={[
              {
                id: 1,
                label: t(`${translationPath}agreement-type`),
                input: 'agreement_type',
              },
              {
                id: 2,
                label: t(`${translationPath}agreement-start-date`),
                input: 'agreement_start_date',
                isDate : true  , 
                component: (item) => (
                  <div>
                    {item && item.agreement_start_date}
                  </div>
                ),
              },
              {
                id: 3,
                label: t(`${translationPath}agreement-duration-months`),
                input: 'agreement_duration_months',
              },
              {
                id: 4,
                label: t(`${translationPath}agreement-end-date`),
                input: 'agreement-end-date',
                isDate : true  , 
                component: (item) => (
                  <div>
                    {item && item.agreement_end_date}
                  </div>
                ),

              },
              {
                id: 5,
                label: t(`${translationPath}project-name`),
                input: 'project-name',
              },
              {
                id: 6,
                label: t(`${translationPath}signing-branch`),
                input: 'signing_branch',
              },
              {
                id: 7,
                label: t(`${translationPath}authorized-signatory`),
                input: 'authorized_signatory',
              },
              {
                id: 8,
                label: t(`${translationPath}psi-commission`),
                input: 'psi_commission',
              },
              {
                id: 9,
                label: t(`${translationPath}psi-commission-slabs`),
                input: 'psi_commission_slabs',
              },
              {
                id: 10,
                label: t(`${translationPath}marketing-budget-allocation`),
                input: 'marketing-budget-allocation',
              },
            ]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            defaultActions={[
              {
                enum: TableActions.editText.key,
                isDisabled: false,
                externalComponent: null,
              },
              {
                enum: TableActions.deleteText.key,
                isDisabled: false,
                externalComponent: null,
              },
            ]}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            itemsPerPage={filter.pageSize}
            totalItems={allAgreementDetails ? allAgreementDetails.totalCount : 0}
          />
        {isMissingInfoMSGOpen  && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "delete-agreement-details",
            body :(t(`${translationPath}are-you-sure-you-want-to-delete-delete-agreement-details`) + ' ' + ((activeItem && activeItem.lookupItemName && t(`${translationPath}property-age`)) || '') + `  ${(activeItem && activeItem.lookupItemName)||''}`)

          }}
          confirmButton={{
            label: (t(`${translationPath}confirm`)) , 
            handler: () => DeleteHandler(),
          }}
          cancelButton={{
            label  :(t(`${translationPath}discard`)),
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      </div>
    );
  };
  