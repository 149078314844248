import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  const gapSize = 32; // The gap size in pixels

  return {
    breadCrumbContainer: {
      marginInlineStart: '36px',
      marginBottom: '20px',
    },
    overviewWrapper: (props) => {
      return {
        display: "grid",
        gap: `${gapSize}px`,
        marginTop: "16px",
        gridTemplateColumns: `calc(24.89% - ${gapSize / 2}px) calc(75.11% - ${gapSize / 2}px)`, // Adjusted to subtract the gap
        gridTemplateRows: "auto",
  
        [theme.breakpoints.down(1615)]: {
          gridTemplateColumns: `calc(29% - ${gapSize / 2}px) calc(71% - ${gapSize / 2}px)`, // Adjusted to subtract the gap
        },
  
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: "1fr", // Stacks elements vertically on medium screens
          gridTemplateRows: "auto",
        },
      };
    },
    overviewWrapperExpanded: {
      [theme.breakpoints.down(1872)]: {
        gridTemplateColumns: `calc(45% - ${gapSize / 2}px) calc(55% - ${gapSize / 2}px) !important`, // Adjusted to subtract the gap
      },
  
      [theme.breakpoints.down(1280)]: {
        gridTemplateColumns: "1fr !important",
      },
    },
    profileSide: {
      gridColumn: "1 / 2", // Occupies the first column
      gridRow: "1 / 2", // Occupies the first row
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      minWidth: "350px", // Ensure a minimum width
      [theme.breakpoints.down('md')]: {
        gridColumn: "1 / 2", // Takes full width on medium screens
        gridRow: "1 / 2",
        minWidth: "100%",
      },
    },
    tabsSide: {
      gridColumn: "2 / 3", // Occupies the second column
      gridRow: "1 / 2", // Occupies the first row, next to profileSide
      minWidth: 0, // Prevent overflow issues
      [theme.breakpoints.down('md')]: {
        gridColumn: "1 / 2", // Takes full width on medium screens
        gridRow: "2 / 3",
      },
    },
    profileSideExpanded: {
      [theme.breakpoints.up('md')]: {
        minWidth: "350px",
      },
    },
    tabsSideExpanded: {
      // Additional styling for the expanded state
    },
  };
});
