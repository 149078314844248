import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { ButtonBase } from '@material-ui/core';
import { InputAdornment } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  PhonesComponent,
  CheckboxesComponent,
  DatePickerComponent,
} from "../../../../../Components";
import {ActivityFilesUploader  } from '../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader' ;

import {
  GetParams,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../Helper";
import { TableActions } from '../../../../../Enums';
import { AgreementDetailsTable } from './AgreementDetailsTable' ; 
import { AgreementDetailsManagementDialog } from './AgreementDetailsManagementDialog' ;
import { AuditAndComplianceTable , AuditAndComplianceManagementDialog } from './AuditAndComplianceComponents' ;



import {  GetLookupItemsByLookupTypeName} from '../../../../../Services' ; 

export const AuditAndCompliance = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
   const  [isLoading , setIsLoading] = useState({allAgreementTypes : false , save : false  , info : false}) ;
   const [isOpenEditDialog ,  setIsOpenEditDialog] = useState(false);

  const [items , setItems] = useState({
    allAgreementTypes :[] 
  })  ; 

  const [defaultSelected, setDefaultSelected] = useState({
    auditProcessComplete: null,
    auditDate: null,
    auditRemarks :null , 
    complianceDocuments: null,
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultSelected,
  });

  const getLookupItemsByName = async (lookUpName , key ) => {

    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(result && result.status && result.status !== 200)) {
        setItems((item) => ({ ...item, [key]: (result && result.result) || []   }));
    } 
    else  setItems((item) => ({ ...item, [key]: []}));
    setIsLoading((item) => ({ ...item, [key]: false }));

  };

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <div className="px-2 item">
              <AutocompleteComponent
                key={`auditProcessComplete`}
                selectedValues={state.auditProcessComplete}
                idRef={`auditProcessCompleteRef`}
                multiple={false}
                data={[{key :'yes' , name : 'yes' } , {key : 'no' , name : 'no'}]}
                inputClasses="inputs theme-form-builder"
                displayLabel={(option) => (option && t(`${translationPath}${option.name}`)) || ""}
                onChange={(e, v) => {}}
                withoutSearchButton
                labelValue={t(`${translationPath}audit-process-complete`)}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            
            <div className="px-2 item">
              <DatePickerComponent
                idRef={"AuditDateRef"}
                format="YYYY/MM/DD"
                labelValue={"audit-date"}
                 value={(state && state.auditDate && moment(state.auditDate).isValid() &&  moment(state.auditDate).format('YYYY/MM/DD'))}
                onDateChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item mt-1">
              <Inputs
                isAttachedInput
                idRef={"auditRemarksRef"}
                labelValue={"audit-remarks"}
                type={"text"}
                multiline
                rows={2}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className='px-2 w-100 mt-2'>
             <ActivityFilesUploader
              state={state}
              setState={setState}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              multiple={true}
              accept={'/*'}
           />
    
            </div>
            <div className="mt-3 addBtn">
            <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              //saveAgreementDetails() 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-agreement-details`)}
          </ButtonBase>
          <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              //saveAgreementDetails() 
              setIsOpenEditDialog(true) ; 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}edit-audit-and-compliance`)}
          </ButtonBase>
          </div>
          </div>
        </div>
      </div>
      <AuditAndComplianceTable 
       translationPath={translationPath}
       parentTranslationPath={parentTranslationPath}
      />
      
        {
          isOpenEditDialog && (
            <AuditAndComplianceManagementDialog
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            isOpen ={isOpenEditDialog}
            onClose={()=> setIsOpenEditDialog(false)}
            onSave={()=> setIsOpenEditDialog(false)}
            />
          )
        
        }
    </div>
  );
};
