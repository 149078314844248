import { config } from '../config/config';
import { GlobalTranslate, showError, HttpServices } from '../Helper';


const GetCourses = async ({ pageIndex, pageSize }) => {
    try {
        const result = await HttpServices.get(
            `${config.server_address}/CrmDfm/Courses/GetAllCourses?pageIndex=${pageIndex+1}&pageSize=${pageSize}`
        );
        return result;
    } catch (error) {
        showError(GlobalTranslate.t('LearningAndDevelopmentView:NotificationErrorView'));
        return undefined;
    }
};

const GetCoursesDetails = async (courseId) => {
    try {
        const result = await HttpServices.get(
            `${config.server_address}/CrmDfm/Courses/GetCourseDetailsById?courseId=${courseId}`
        );
        return result;
    } catch (error) {
        showError(GlobalTranslate.t('LearningAndDevelopmentView:NotificationErrorView'));
        return undefined;
    }
};

const GetInstructorDetails = async (courseId) => {
    try {
        const result = await HttpServices.get(
            `${config.server_address}/CrmDfm/Courses/GetInstructorDetailsByCourseId?courseId=${courseId}`
        );
        return result;
    } catch (error) {
        showError(GlobalTranslate.t('LearningAndDevelopmentView:NotificationErrorView'));
        return undefined;
    }
};

export {
  GetCourses,
  GetCoursesDetails,
  GetInstructorDetails,
};
