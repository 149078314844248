
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Spinner, Tables } from '../../../../Components';
import {
  GlobalHistory,
  showError,
  returnPropsByPermissions,
} from '../../../../Helper';
import { TableActions, TableFilterTypesEnum } from '../../../../Enums';
import { ActiveItemActions } from '../../../../store/ActiveItem/ActiveItemActions';


  export const DevelopersTable = ({translationPath , parentTranslationPath  , data  , filter , setSortBy , isLoading,
    activeItem,
    setActiveItem,  
  }) => { 

    const { t } = useTranslation(parentTranslationPath);
    const dispatch = useDispatch();
    const headerData = [
      {
        id: 1,
        isSortable: false,
        input: 'developerId',
        label: t(`${translationPath}developerId`),
        isFilterHidden: true,
      },
      {
        id: 1,
        isSortable: false,
        input: 'name',
        label: t(`${translationPath}name`),
        isFilterHidden: true,
      },
      {
        id: 4,
        isSortable: true,
        input: 'createdOn',
        isDate: true,
        label: t(`${translationPath}createdOn`),
        isFilterHidden: true,
      },
      {
        id: 5,
        isSortable: true,
        input: 'updateOn',
        label: t(`${translationPath}updateOn`),
        isDate: true,
        isFilterHidden: true,
      },
      {
        id: 6,
        isSortable: true,
        input: 'createdByName',
        isDate: false,
        label: t(`${translationPath}createdBy`),
        isFilterHidden: true,
      },
  
    ];

    const tableActionClicked = useCallback((actionEnum, item) => {
      setActiveItem(item);
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.edit.key) {
        GlobalHistory.push(
          `/home/zero-matching-sale/zero-matching-sale-profile-edit?formType=${44}&id=${
            item && item.inquiryId
          }&activeFormType=${1}`
        );
      }
    }, []);
  

    return (
      <div className='ZeroMatchingViewTable'>
        <Spinner isActive={isLoading.Inquiries} isAbsolute />
        <div className='filter-section-item' />
        <div className='w-100 px-2'>
          <Tables
            data={data?.result ?? []}
            isWithFilter
            filterData={headerData.map((item) => ({
              ...item,
              searchableKey: item.displayPath ?? item.input,
            }))}
            headerData={headerData}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            FilterDisabledButton
            itemsPerPage={filter.pageSize}
            activePage={filter.pageIndex}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            totalItems={data?.totalCount ?? 0}
            setSortBy={setSortBy}
            onFilterValuesChanged={(e) => 
            {
            }
            }
          />
        </div>

      </div>
    );
  } ; 
  