export const LeadOwnerUnitLeasePermissions = 
{
  AddNewUnit :{
    permissionsId: "4d21d5ba-c335-47d2-9ebe-006e71ddac76",
    permissionsName: "Add new unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewOpenHouseForUnit :{
    permissionsId: "a7b6a30d-330a-4473-8bee-042719e08510",
    permissionsName: "View open house for unit",
    description: null,
    componentsId: null,
    components: null
  },

  DeletParkingForUnit :{
    permissionsId: "1979900d-7994-4ea9-b223-1d6b7df4edaf",
    permissionsName: "Delete parking for unit",
    description: null,
    componentsId: null,
    components: null
  },

  EditMarketingDescription : {
     permissionsId: "7d8809fe-7f44-45ef-a600-20c304ee3fc9",
    permissionsName: "Edit Marketing Description",
    description: null,
    componentsId: null,
    components: null
  },

  EditPayablesForUnit :{
    permissionsId: "de60abd7-3e11-4a7c-9073-23e9d71ec187",
    permissionsName: "Edit payables for unit",
    description: null,
    componentsId: null,
    components: null
  },

  EditParkingForUnit :  {
    permissionsId: "a28ad9cb-00b5-4df0-981a-2662d879454b",
    permissionsName: "Edit parking for unit",
    description: null,
    componentsId: null,
    components: null
  },

  UpdateRemarksInUnit : {
    permissionsId: "178d620d-f400-40b0-9c14-26842d25d0f6",
    permissionsName: "Update remarks in unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewPricingDetailsForLeaseReservedTransactions : {
    permissionsId: "e149e726-87f6-4cb4-ba66-2764579b940f",
    permissionsName: " View Pricing Details For Lease/Reserved Transactions",
    description: null,
    componentsId: null,
    components: null
  },

  DeleteRemarksInUnit : {
    permissionsId: "bdcc9efe-e6f2-445a-84f0-2c57448aa4a5",
    permissionsName: "Delete remarks in unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewPaymentDetailsForUnit : {
    permissionsId: "9cae6df7-7f21-4ca8-a84b-33b62992f86a",
    permissionsName: "View Payment details for unit",
    description: null,
    componentsId: null,
    components: null
  },

  CreateUnitOwner : {
    permissionsId: "8f481b2f-c195-4a65-abba-3431b588c1bc",
    permissionsName: "create Unit Owner",
    description: null,
    componentsId: null,
    components: null
  },

  ViewUnitHistory : {
    permissionsId: "c935308c-13b2-4f7f-a9bc-42bc9f5bad05",
    permissionsName: "View Unit History",
    description: null,
    componentsId: null,
    components: null
  },

  ViewRemarksForUnit :{
    permissionsId: "3168aaf3-3e31-4be9-8ced-4a55317f3445",
    permissionsName: "View remarks for unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewInvoicesForLeaseReservedTransactions :{
    permissionsId: "09040ad8-838e-4381-852f-4c1c5b56e102",
    permissionsName: "View Invoices For Lease/Reserved Transactions",
    description: null,
    componentsId: null,
    components: null
  },

  UpdateUnitOwner :{
    permissionsId: "5084e1c9-9234-4612-8b8f-4d48961e8ec3",
    permissionsName: "Update Unit Owner",
    description: null,
    componentsId: null,
    components: null
  },

  ViewTheActivitiesForUnit : {
     permissionsId: "b7bb4b8f-2f07-4112-ad3e-5421be5bd138",
    permissionsName: "View the activities for unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewUnitDetails :{
     permissionsId: "80ee10ae-80f7-49eb-9cdc-5ac1431507e2",
    permissionsName: "View unit details",
    description: null,
    componentsId: null,
    components: null
  },

  ViewReminderForLeasedReservedTransaction : {
    permissionsId: "b34a7204-f4b2-482c-9b5e-601aef2a87b8",
    permissionsName: "View Reminder For Leased/Reserved Transaction",
    description: null,
    componentsId: null,
    components: null
  },

  DeleteOpenHouseForUnit : {
    permissionsId: "bb827c54-5d4c-4b9f-9fa4-6153a8f2feaf",
    permissionsName: "Delete open house for unit",
    description: null,
    componentsId: null,
    components: null
  },

  ShareUnit :  {
    permissionsId: "61009037-82a0-4d73-a01d-6829a16f9e3c",
    permissionsName: "Share unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewTenantLandlordNamesForLeaseReservedTransactions :{
    permissionsId: "ef6ac8de-73cf-4593-ae40-6e83a665de1b",
    permissionsName: "View Tenant /Landlord names for lease/reserved transactions",
    description: null,
    componentsId: null,
    components: null
  },

  ViewDocumentsForUnit : {
    permissionsId: "02e7dfb4-3515-4fe4-b812-75cd47793af9",
    permissionsName: "View documents for unit",
    description: null,
    componentsId: null,
    components: null
  },

  OpenFileForUnitOwner : {
     permissionsId: "dd634b02-d507-4174-a9af-769f49c4598d",
    permissionsName: "Open File For Unit Owner",
    description: null,
    componentsId: null,
    components: null
  },

  ViewManagemntForUnit : {
    permissionsId: "1522aacd-76e9-4726-ba6b-77b48f476a4c",
    permissionsName: "View Managemnt for unit",
    description: null,
    componentsId: null,
    components: null
  },

  EditUnitImageCategory : {
    permissionsId: "261e2a91-6bbe-4630-93c4-782a8a95d5b0",
    permissionsName: "Edit Unit Image Category",
    description: null,
    componentsId: null,
    components: null
  },

  EditUnitDetails : {
    permissionsId: "3c6bb86e-95e7-4c91-bcca-7a443c47d28d",
    permissionsName: "Edit unit details",
    description: null,
    componentsId: null,
    components: null
  },

  EditFeaturedUnitMarketing : {
    permissionsId: "6044a179-cf82-4b8a-93c8-7b01294eea0c",
    permissionsName: "Edit Featured Unit Marketing",
    description: null,
    componentsId: null,
    components: null
  },

  AddNewRemarksForUnit :{
    permissionsId: "711b7e6d-294b-48b2-b9d1-7e90a8cf733f",
    permissionsName: "Add new remarks for unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewReferenceDetailsForUnit : {
    permissionsId: "1e7ef1fa-05d1-4eae-a681-7ee60011fb09",
    permissionsName: "View Reference details for unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewUnitMatching : {
    permissionsId: "fae28868-989f-4c02-927b-81e271de9249",
    permissionsName: "View Unit Matching",
    description: null,
    componentsId: null,
    components: null
  },

  UnitBulkUpdate :  {
    permissionsId: "d4be9656-e8a5-4507-b32d-82954b90795d",
    permissionsName: "Unit bulk update",
    description: null,
    componentsId: null,
    components: null
  },

  ViewAndSearchInLeaseUnits :{
    permissionsId: "72ed37ac-22f9-4882-abc6-82eb0d8e09c6",
    permissionsName: "View and search in Lease units",
    description: null,
    componentsId: null,
    components: null
  },

  ImportUnits :  {
    permissionsId: "751e1546-737a-4451-a8f2-8311c5f48ddd",
    permissionsName: "Import units",
    description: null,
    componentsId: null,
    components: null
  },

  OpenUnitTemplate :{
    permissionsId: "9622068f-7139-46f6-89f5-982fa2245935",
    permissionsName: "Open Unit Template",
    description: null,
    componentsId: null,
    components: null
  },

  EditPublishedToWebsiteMarketing :{
    permissionsId: "ace98040-cbe7-4b10-9eff-9c81eb89590b",
    permissionsName: "Edit Published To Website Marketing",
    description: null,
    componentsId: null,
    components: null
  },

  ViewPayablesForUnit :  {
    permissionsId: "96feba8a-8bbb-40a6-88a3-a42d9175c5c3",
    permissionsName: "View payables for unit",
    description: null,
    componentsId: null,
    components: null
  },

  EditManagemntForUnit : {
    permissionsId: "37361cf7-5894-4f70-92d7-ab87aaf6604a",
    permissionsName: "Edit Managemnt for unit",
    description: null,
    componentsId: null,
    components: null
  },

  EditUnitMarketingInfo :{
     permissionsId: "6831c3ef-0126-414d-88df-b41f47847869",
    permissionsName: "Edit unit marketing info",
    description: null,
    componentsId: null,
    components: null
  },

  AddReminderForLeasedReservedTransaction : {
    permissionsId: "e160f368-648f-4060-a5ab-b57e945b7ef6",
    permissionsName: "Add Reminder For Leased/Reserved Transaction",
    description: null,
    componentsId: null,
    components: null
  },

  EditMarketingTitle :{
    permissionsId: "e0b786bb-6801-4041-925d-b73ff9af95e9",
    permissionsName: "Edit Marketing Title",
    description: null,
    componentsId: null,
    components: null
  },

  EditHotDealMarketing :{
    permissionsId: "5576e712-31ad-4832-8c1c-b7c789a4bb61",
    permissionsName: "Edit Hot Deal Marketing",
    description: null,
    componentsId: null,
    components: null
  },

  UpdateOpenHouseForUnit : {
    permissionsId: "d2f78fcf-77cd-4db8-9195-baeed784a092",
    permissionsName: "Update open house for unit",
    description: null,
    componentsId: null,
    components: null
  },

  EditUspMarketing : {
    permissionsId: "ab097a03-9ebc-4f8f-a296-be02c1046527",
    permissionsName: "Edit Usp Marketing",
    description: null,
    componentsId: null,
    components: null
  } ,

  ViewTransactionDetailsForUnit : {
    permissionsId: "857f44f7-a88d-455b-bcd2-bfe20d3c56bf",
    permissionsName: "View Transaction Details For Unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewParkingInfoForUnit : {
    permissionsId: "d1316786-37aa-4e52-ac32-c8f8a205c238",
    permissionsName: "View parking info for unit",
    description: null,
    componentsId: null,
    components: null
  },

  AddParkingForUnit :{
    permissionsId: "5a715856-75b3-4f75-b1d7-ceeac9362b98",
    permissionsName: "Add parking for unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewUnitTemplates :{
    permissionsId: "e59e687c-7cd5-4041-8791-e20a90a03381",
    permissionsName: "View Unit Templates",
    description: null,
    componentsId: null,
    components: null
  },

  EditPaymentDetailsForUnit : {
    permissionsId: "4d8b652d-e963-44db-b7fa-e2c1fd8c31ad",
    permissionsName: "Edit Payment details for unit",
    description: null,
    componentsId: null,
    components: null
  },

  GetAllOwnersByUnitId : {
    permissionsId: "0abf19e5-dfa8-4add-87e9-e6efdf453452",
    permissionsName: "Get All Owners By Unit-Id",
    description: null,
    componentsId: null,
    components: null
  },

  ViewTransactionsForUnit :{
    permissionsId: "ebd3973a-4385-4c6f-a797-ea0524593521",
    permissionsName: "View Transactions For Unit",
    description: null,
    componentsId: null,
    components: null
  },

  ViewUnitLocationOnMap :{
    permissionsId: "fdba88da-4305-4866-8353-ea668bc8137f",
    permissionsName: "View unit location on map",
    description: null,
    componentsId: null,
    components: null
  },

  SetUnitAsLease :{
    permissionsId: "0a28d6bb-f90d-4d12-9cf9-eaec25fc8344",
    permissionsName: "Set Unit As Lease",
    description: null,
    componentsId: null,
    components: null
  },

  ViewUnitImages : {
    permissionsId: "69f8b7e4-6a8d-46e4-8cf0-ed667f548961",
    permissionsName: "View unit images",
    description: null,
    componentsId: null,
    components: null
  },

  EditReferenceDetailsForUnit :{
    permissionsId: "12b50ab6-b57b-4b0b-96cb-ef118f9ed3da",
    permissionsName: "Edit reference details for unit",
    description: null,
    componentsId: null,
    components: null
  },

  MakeTransactionOnUnitLeaseToBeDraft :{
    permissionsId: "88477ab6-37ae-461f-8065-f1970ac0907e",
    permissionsName: "Make Transaction On Unit Lease To Be Draft",
    description: null,
    componentsId: null,
    components: null
  },

  ViewUnitMarketingInfo : {
    permissionsId: "7bb0f0a5-c063-444f-a42c-f405ca7a0b87",
    permissionsName: "View unit marketing info",
    description: null,
    componentsId: null,
    components: null
  },

  MakeTransactionOnUnitLeaseToBeAvailable :
  {
    permissionsId: "f5f4b8e0-6a2d-4248-8fe3-f58f3da3bfd2",
    permissionsName: "Make Transaction On Unit Lease To Be Available",
    description: null,
    componentsId: null,
    components: null
  },

  AddNewOpenHouseForUnit :{
    permissionsId: "e52b7857-8b4a-4172-b77c-f6586e492912",
    permissionsName: "Add new open house for unit",
    description: null,
    componentsId: null,
    components: null
  },

  SetUnitAsReserveForLease : {
    permissionsId: "c59f21ad-1ce3-43ae-8360-f69fb196c5e7",
    permissionsName: "Set Unit As Reserve For Lease",
    description: null,
    componentsId: null,
    components: null
  },

  DeleteUnitOwner : {
    permissionsId: "17c39905-db87-4d51-bf00-feb3ad38314a",
    permissionsName: "Delete Unit Owner",
    description: null,
    componentsId: null,
    components: null
  },
  AddNewActivity :  {
     permissionsId: "aede8066-eb5a-4e09-2875-08dbaf79a1bb",
    permissionsName: "Add New Activity",
    description: null,
    componentsId: null,
    components: null
  },
  EditReservedLeaseTransaction :  {
    permissionsId: "b7559ce6-e993-4d12-2b1b-08dbca2fdea8",
    permissionsName: "Edit Reserved Lease Transaction",
    description: null,
    componentsId: null,
    components: null
 },
 
 AddPublishedUnit :{
   permissionsId: "2ef0b027-888e-4b01-7043-08dbf65badbd",
   permissionsName: "Add Published Unit",
   description: null,
   componentsId: null,
   components: null
},
ShowWebPortals : {
   permissionsId: "64dd16ce-d4a5-4a01-7045-08dbf65badbd",
   permissionsName: "Show Web Portals",
   description: null,
   componentsId: null,
   components: null
},

};