import React, { useEffect, useState } from 'react';
import {
  DialogComponent,
  Inputs,
  SelectComponet,
  Tables,
} from '../../../../Components';
import { TableActions } from '../../../../Enums';
import { UpdateContactOpportunityStatus } from '../../../../Services';
import { showError } from '../../../../Helper';
import { useTranslation } from 'react-i18next';
import statues from './statuses';
import StatusSelector from './components/StatusSelector';

function ContactsOpportunityTable({
  data,
  filter,
  headerData,
  setFilter,
  loading,
  reload,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [activeItem, setActiveItem] = useState(null);
  const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
  const [targetedStatus, setTargetedStatus] = useState(null);
  const [reason, setReason] = useState('');

  const actionClickHandler = (actionEnum, item) => {
    if (actionEnum === TableActions.edit.key) {
      setActiveItem(item);
      setChangeStatusDialogOpen(true);
    }
  };
  const updateStatusHandler = async (e) => {
    e.preventDefault();
    try {
      await UpdateContactOpportunityStatus({
        contactOpportunityStatusType: targetedStatus,
        contactOpportunityId: activeItem.contactOpportunityId,
        reason,
      });
      setChangeStatusDialogOpen(false);
      reload();
    } catch (error) {
      showError(t('error-message'));
    }
  };
  return (
    <div>
      <Tables
        headerData={headerData}
        data={data.result}
        totalItems={data.totalCount}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex - 1}
        onPageIndexChanged={(index) =>
          setFilter({ ...filter, pageIndex: index + 1 })
        }
        onPageSizeChanged={(size) => setFilter({ ...filter, pageSize: size })}
        isLoading={loading}
        defaultActions={[
          { enum: TableActions.edit.key, title: 'update status' },
        ]}
        actionsOptions={{
          onActionClicked: actionClickHandler,
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={''}
      />
      {changeStatusDialogOpen && (
        <DialogComponent
          parentTranslationPath={parentTranslationPath}
          translationPath={''}
          maxWidth={'sm'}
          isOpen
          titleText={'change-status'}
          dialogContent={
            <div>
              <div className='d-flex gap-1'>
                <StatusSelector
                  label={'from'}
                  value={
                    statues.find((status) => status.name === activeItem.status)
                      ?.id
                  }
                  isDisabled
                  parentTranslationPath={parentTranslationPath}
                />
                <StatusSelector
                  label={'to'}
                  onChange={(e) => setTargetedStatus(e)}
                  value={targetedStatus}
                  parentTranslationPath={parentTranslationPath}
                  data={statues.filter(
                    (status) => status.name !== activeItem.status
                  )}
                />
              </div>
              <Inputs
                rows={4}
                inputPlaceholder={'enter-a-description'}
                multiline
                idRef='reason'
                labelValue={'reason'}
                value={reason}
                onInputChanged={(e) => setReason(e.target.value)}
                parentTranslationPath={parentTranslationPath}
                translationPath={''}
              />
            </div>
          }
          onCancelClicked={() => setChangeStatusDialogOpen(false)}
          onCloseClicked={() => setChangeStatusDialogOpen(false)}
          onSaveClicked={updateStatusHandler}
          saveIsDisabled={!targetedStatus}
        />
      )}
    </div>
  );
}

export default ContactsOpportunityTable;
