export const StaticLookupsIds = {
  PropertyType: 23,
  WeekDays: 1135,
  Cities: 17,
  UAECities: 26642,
  Country: 16,
  TourPolicies: 1145,
  NationalityCategory: 12,
  ActivityType: 1146,
  Activity: 1147,
  DRIVER: 'e3b4335b-0e5d-4f4f-4903-08d84f7cb24b',
  AGENTS: 'eb2d7443-590b-4e96-4904-08d84f7cb24b',
  LeadClosedReason: 1208,
  LeasingType: 1205,
  AvailableStatusReason: 1203,
  ReservationType: 1204,
  Banks: 26,
  RentFreePeriod: 1206,
  PeriodOfStay: 1207,
  PaymentType: 1209,
  PaymentMode: 1210,
  ContactTypes: 1211,
  WorkOrderCategory: 1218,
  ContractorType: 1219,
  WorkOrderPriority: 1220,
  ServicesOffered: 1217,
  Payableby: 1221,
  Termsofpayment: 1222,
  WorkOrderRequestedby: 1223,
  CommunicationMedia: 1224,
  Availabletime: 1225,
  quotationTypes: 1226,
  WeekDay: 1135,
  WorkOrderDriver: 1227,
  CommonAreas: 1228,
  MethodOfContact: 35,
  Servicesoffered: 1217,
  MaintenanceCompany: 20724,
  AttachmentCategory: 1215,
  AttachmentTitle: 1216,
  WorkOrderStatus: 1233,
  ActivityPerformedBy: 1234,
  Allparkingtype: 1235,
  ChequeType: 1264,
  ChequeStatus: 1265,
  LuxuryMarketing: 1258,
  FinanceMarketing: 1261,
  StyleMarketing: 1259,
  ViewsMarketing: 1260,
  TemplateCategory: 1256,
  UintType: 28,
  UintView: 142,
  UintFittingAndFixtures: 31,
  Amenities: 21,
  District: 18,
  Community: 19,
  SubCommunity: 20,
  ContactUploadDocumentsType: 73,
  CompanyUploadDocumentsType: 1253,
  UnitImageCategory: 1272,
  FlootPlanCategory: 1274,
  GenralImageCategory: 1273,
  OffPlan: 18079,
  MediaDetail: 1240,
  MediaName: 8,
  Completed: 18080,
  PreLaunch: 21969,
  ActivityRate: 1212,
  Closed: 458,
  UnitsDocuments: 1252,
  Sales: 430,
  Rent: 431,
  SaleAndRent: 20984,
  FittingAndFixtures: 2150,
  FacilitiesAndAmenities: 2151,
  JobTitle: 3174,
  invoiceId: 1066,
  receiptId: 1065,
  Negative: 20704,
  CampaignTypes: 3211,
  Languages: 114,
  VicinityDistance: 2154,
  Qualification: 3195,
  VicinityCategory: 3217,
  EventType: 3219,
  MPI: 3221,
  UnitSaleType: 30,
  UintOperationType: 29,
  ContactClass: 5,
  LeadType: 38,
  BranchDocumentCategory: 3230,
  ActivityTemplates: 1,
  UnitTemplates: 2,
  ActivityTemplates: 3,
  WithSpecificContact: 4,
  WithSpecificLead: 5,
  WithSpecificUser: 6,
  EmailBody: 7,
  PropertyTemplates: 8,
  TaskTemplates: 9,
  AutoCorrespondence: 10,
  WebProjectType: 3235,
  visaTypes: 47,
  ContactDNCR: 3247
};
