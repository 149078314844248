import { HttpServices } from "../../Helper";
import { config } from "../../config";

const GetDeveloperContactsAdvanceSearchAPI = async (
  { pageSize, pageIndex },
  body
) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/DeveloperContactsAdvanceSearch/${pageIndex}/${pageSize}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const CreateDeveloperContactAPI = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/DeveloperContact/CreateDeveloperContact`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const UpdateDeveloperContactAPI = async (developerContactId, body) => {
  const result = await HttpServices.put(
    `${config.server_address}/CrmDfm/DeveloperContact/UpdateDeveloperContact/${developerContactId}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

const GetDeveloperContactByIdAPI = async (developerContactId) => {
  const result = await HttpServices.get(
    `${config.server_address}/CrmDfm/DeveloperContact/GetDeveloperContactById/${developerContactId}`
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export {
  GetDeveloperContactsAdvanceSearchAPI,
  GetDeveloperContactByIdAPI,
  CreateDeveloperContactAPI,
  UpdateDeveloperContactAPI,
};
