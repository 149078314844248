import React, {
 useCallback, useState, useEffect, useReducer
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Joi from 'joi';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';
import {
  Spinner,
  DialogComponent,
  ConvertJsonV2Component,
  Inputs,
} from '../../../../../../../../Components';
import {
  showError,
  showSuccess,
  formItemsBuilder,
  FormErrorsHandler,
  GlobalHistory,
  getErrorByName,
} from '../../../../../../../../Helper';
import { LookupsRules } from '../../../../../../../../Rule';
import { GetAllFormFieldTabsByFormId, propertyPost } from '../../../../../../../../Services';
import { propertyInitValue } from './PropertyQuickAddInitialValue';
import {
 PropertyTypeComponent, PropertyPlanComponent, CountryComponent, CityComponent, DistrictComponent, CommunityComponent, SubCommunityComponent
} from './Components';
import { useSuccessDialog } from '../../../../../../../../SharedComponents';

const translationPath = '';
const parentTranslationPath = 'PropertiesView';
export const PropertQuickAddDialog = ({ open, onClose }) => {
  const [activeTab] = useState(0);
  const [errors, setErrors] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const [formData, setFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const { showDialog } = useSuccessDialog();
  const [formAndTabs, setFormAndTabs] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(
    JSON.parse(JSON.stringify(propertyInitValue))
  );
  const [addressLoadings, setAddressLoadings] = useState({
 country: false, city: false, district: false, community: false, subCommunities: false
});
  const [addressList, setAddressList] = useState({
 countries: [], cities: [], districts: [], communities: [], subCommunities: []
});

  const history = useHistory();
  const schema = Joi.object({
    property_plan: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}property_plan-is-required`),
      }),
    property_name: Joi.string()
    .required()
    .messages({
      'string.base': t(`${translationPath}property_name-is-required`),
      'string.empty': t(`${translationPath}property_name-is-required`),

    }),
    property_type: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}property_type-is-required`),
      }),

      country: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}country-is-required`),
      }),
      city: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}city-is-required`),
      }),
      district: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}district-is-required`),
      }),
      community: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}community-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(propertyDetails.property);

  const saveHandler = async (isContinue) => {
    setSaveDisabled(true);
    setIsLoading(true);
    setIsSubmitted(true);
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      setIsLoading(false);
      return;
    }
    const totalItems = formData.reduce((total, items) => {
      // eslint-disable-next-line no-param-reassign
      total += items.length;
      return total;
    }, 0);
    const totalValues = Object.values(propertyDetails.property).filter(
      (item) => item !== null && item !== '' && item !== undefined
    ).length;
    const total = (totalValues / totalItems) * 100;
    // propertyDetails.property.data_completed = Math.round(total);
    propertyDetails.property.data_completed = 16;
    const postResponse = await propertyPost({
      propertyJson: propertyDetails,
    });
    setSaveDisabled(true);
    setIsLoading(true);

    if (!(postResponse && postResponse.status && postResponse.status !== 200)) {
      setPropertyDetails({
        property: {
          city: null,
          country: null,
          district: null,
          community: null,
          property_name: null,
          property_plan: null,
          property_type: null,
          property_owner: null,
          sub_community: null
        },
      });
      // showSuccess(t(`${translationPath}property-Created-successfully`));
      showDialog(t('GlobalSuccessDialog:property-successfully'), {
        name: postResponse?.propertyName,
        id: postResponse?.propertyId,
      });
      
      if (isContinue)
      history.push(`/home/Properties-CRM/property-profile-edit?formType=1&id=${postResponse.propertyId}`);
      setSaveDisabled(false);
      setIsLoading(false);
      onClose();
    } else {
      setSaveDisabled(false);
      setIsLoading(false);
      const errorMsg = postResponse &&
      postResponse.data &&
      postResponse.data.Message &&
      postResponse.data.Message.split(':') &&
      postResponse.data.Message.split(':').pop();
    showError(`${t(`${translationPath}${errorMsg.substring(0, errorMsg.lastIndexOf(' '))}`)}
      ${errorMsg.substring(errorMsg.lastIndexOf(' '), errorMsg.length)}`);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        className='activities-management-dialog-wrapper'
        disableBackdropClick
      >
        <form noValidate>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(`${translationPath}add-new-property`)}
          </DialogTitle>
          <DialogContent>
            <div className='dialog-content-wrapper'>
              <Spinner isActive={isLoading} isAbsolute />
              <div className='dialog-content-item'>
                <PropertyPlanComponent
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'property_plan').message}
                  error={getErrorByName(schema, 'property_plan').error}
                  onUpdateValue={(returnValue) => {
                     const property = { ...propertyDetails.property, property_plan: returnValue };
                      setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='propertyNameRef'
                  labelValue='property_name'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'property_name').message}
                  error={getErrorByName(schema, 'property_name').error}
                  isWithError
                  onInputChanged={(e) => {
                    const property = { ...propertyDetails.property, property_name: e && e.target && e.target.value };
                      setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <PropertyTypeComponent
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'property_type').message}
                  error={getErrorByName(schema, 'property_type').error}
                  onUpdateValue={(returnValue) => {
                     const property = { ...propertyDetails.property, property_type: returnValue };
                      setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <CountryComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  helperText={getErrorByName(schema, 'country').message}
                  error={getErrorByName(schema, 'country').error}
                  isSubmitted={isSubmitted}
                  onUpdateValue={(returnValue) => {
                    const property = {
 ...propertyDetails.property, country: returnValue, city: null, district: null, community: null, sub_community: null
};
                      setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <CityComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  helperText={getErrorByName(schema, 'city').message}
                  error={getErrorByName(schema, 'city').error}
                  isSubmitted={isSubmitted}
                  onUpdateValue={(returnValue) => {
                    const property = {
 ...propertyDetails.property, city: returnValue, district: null, community: null, sub_community: null
};
                      setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <DistrictComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  helperText={getErrorByName(schema, 'district').message}
                  error={getErrorByName(schema, 'district').error}
                  isSubmitted={isSubmitted}
                  onUpdateValue={(returnValue) => {
                    const property = {
 ...propertyDetails.property, district: returnValue, community: null, sub_community: null
};
                      setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <CommunityComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  helperText={getErrorByName(schema, 'community').message}
                  error={getErrorByName(schema, 'community').error}
                  isSubmitted={isSubmitted}
                  onUpdateValue={(returnValue) => {
                    const property = { ...propertyDetails.property, community: returnValue, sub_community: null };
                      setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <SubCommunityComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onUpdateValue={(returnValue) => {
                    const property = { ...propertyDetails.property, sub_community: returnValue };
                      setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className='form-builder-wrapper'>
              <div className='form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between'>
                <div className='MuiGrid-root-left'>
                  <ButtonBase
                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns c-danger'
                    onClick={() => onClose()}
                  >
                    <span className='MuiButton-label'>
                      <span className='mx-2'>
                        {t(`${translationPath}cancel`)}
                      </span>
                    </span>
                    <span className='MuiTouchRipple-root' />
                  </ButtonBase>
                </div>
                <div className='MuiGrid-root-right'>
                  <ButtonBase
                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns'
                    onClick={() => saveHandler(true)}
                  >
                    <span className='MuiButton-label'>
                      <span className='mx-2'>
                        {t(`${translationPath}Save and Continue`)}
                      </span>
                    </span>
                    <span className='MuiTouchRipple-root' />
                  </ButtonBase>
                  <ButtonBase
                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns theme-solid bg-primary'
                    id='Submit-bbt'
                    onClick={() => saveHandler()}
                  >
                    <span className='MuiButton-label'>
                      <span className='mx-2'>
                        {t(`${translationPath}save`)}
                      </span>
                    </span>
                    <span className='MuiTouchRipple-root' />
                  </ButtonBase>
                </div>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
