import { DeveloperProfile , AgreementsAndComplianceView   , PeopleAndLocationsView , SocialMediaLink      , DeveloperProfileProjectOverviewComponent , DeveloperProfileAgentsOverviewComponent , DeveloperProfileLeadsOverviewComponent } from '../../DeveloperTabsSections' ;

export const DeveloperVerticalTabsData = [
 {
   label: 'developer-profile',
   component: DeveloperProfile ,
 },
 {
   label: 'agreements-and-compliance',
   component: AgreementsAndComplianceView ,

 },
 
 {
   label: 'people-and-locations',
   component: PeopleAndLocationsView ,
     
 },
 {
   label: 'social-media-link',
   component: SocialMediaLink , 
 },
{
  label: "project-overview",
 component: DeveloperProfileProjectOverviewComponent,
},
{
  label: "agents-overview",
  component: DeveloperProfileAgentsOverviewComponent,
},
{
  label: "leads-overview",
  component: DeveloperProfileLeadsOverviewComponent,
},

] ;



