/* eslint-disable no-unused-expressions */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase, DialogActions } from '@material-ui/core';
import {
  AutocompleteComponent,
  CheckboxesComponent,
  DialogComponent, Inputs, Spinner,
} from '../../../../../Components';
import {
  GetActivities, LeadsAdvanceSearchTest, RunSystemTemplateForUnit, UnitsAdvanceSearchTest
} from '../../../../../Services';
import 'suneditor/dist/css/suneditor.min.css';
import { TemplatesPreviewDialogUnitMapper } from './TemplatesPreviewDialogUnitMapper';
import { getDownloadableLink, showError } from '../../../../../Helper';
import { LeadsClassTypesEnum, LeadsTypesEnum } from '../../../../../Enums';

export const TemplatesTemplatebuilderComponentPreviewDialog = ({
  activeItem,
  isOpen,
  isOpenChanged,
  parentTranslationPath,
  translationPath,
}) => {
  const data = JSON.parse(activeItem.templateParams);
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const [state, setState] = useState(
    {
      templateId: activeItem.systemTemplateId,
      unitId: null,
      unitIds : null ,  
      leadId: null,
      contactId: null,
      activityId: null,
      userId: null
    }
  );
  const [Content, setContent] = useState('');
  const [DetailsLeadsList, setDetailsLeadsList] = useState(false);
  const [IsLoadingUnits, setIsLoadingUnits] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [withsize, setwithsize] = useState('lg');
  const [IsLoadingActivities, setIsLoadingActivities] = useState(false);
  const [IsLoadingRunSystemTemplate, setIsLoadingRunSystemTemplate] = useState(false);
  const [Activities, setActivities] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [isCheckedHeader, setIsCheckedHeader] = useState(false);
  const [isCheckedfooter, setIsCheckedfooter] = useState(false);

  const [detailsUnitsList, setDetailsUnitsList] = useState({
    result: [],
    totalCount: 0
  });
  const maxHeight = '137px';
  const mediaprint = '@page {margin: 2mm;}@media print {.page-header,.page-header-space {height: 137px;max-height: 137px;}.page-footer,.page-footer-space {height: 137px;max-height: 137px;}.page-footer {position: fixed;bottom: 0;width: 100%;}.page-header {position: fixed;top: 0mm;width: 100%;}.page {page-break-after: always;}thead {display: table-header-group;}tfoot {display: table-footer-group;}button {display: none;}body {margin: 0;}}';
  /// / old style const mediaprint = '@media print {footer {position: fixed !important;bottom: 0 !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: 1cm 1cm 1cm 1cm !important;}}@media print {header {position: fixed !important;top: 0 !important;left: 0 !important;right: 0 !important;text-align:center !important;width: 100% !important;}strong, b {font-weight: bold !important;}@page {size:8.27in 11.69in !important;margin: .5cm !important;}}';
  const [HeaderAndFooter, setHeaderAndFooter] = useState(
    {
      documentsFooterImageId: null,
      documentsHeaderImageId: null
    }
  );
  const RunSystemTemplateHtmlFis = useCallback(async (files, check) => {
    setIsLoadingRunSystemTemplate(true);
    const res = await RunSystemTemplateForUnit(files);

    if (!(res && res.status && res.status !== 200)) {
      setContent(res && res.templateContent);
      if (!check) {
      setHeaderAndFooter({
        documentsFooterImageId: (res && res.documentsFooterImageId) || null,
        documentsHeaderImageId: (res && res.documentsHeaderImageId) || null,
      });
      // setIsCheckedHeader(((res && res.documentsHeaderImageId) && true) || false);
      // setIsCheckedfooter(((res && res.documentsFooterImageId) && true) || false);
    }
      setFile(res && res.templateContent);
      const localFile = new File([res.content], `${'malek'}.pdf` || 'unit-template-file.pdf', {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(localFile);
      setFileUrl(url);
      const content = document.getElementById('divcontents');
      const pri = document.getElementById('ifmcontentstoprint').contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
    } else showError(t(`${translationPath}RunSystemTemplate-failed`));

    setIsLoadingRunSystemTemplate(false);
  }, []);

  const getActivities = useCallback(async (search) => {
    setIsLoadingActivities(true);

    const body = {
      pageSize: 25,
      pageIndex: 1,
      search,
      fromDate: null,
      toDate: null,
      filterBy: null,
      orderBy: null,
      agentId: null,
      activityTypesIds: [],
      relatedUnitNumberId: null,
      relatedLeadNumberId: null,
      relatedWorkOrderId: null,
      relatedMaintenanceContractId: null,
      relatedPortfolioId: null,
      isWithoutRelatedActivities: false,
      relationType: null
    };

    const result = await GetActivities(body);
    if (!(result && result.status && result.status !== 200)) setActivities(result.result);
    else setActivities([]);
    setIsLoadingActivities(false);
  }, []);

  useEffect(() => {

  }, []);

  const getAllAvailableUnitsData = useCallback(
    async (search) => {
      setIsLoadingUnits(true);
      const body = {
        criteria: {
          UnitName: [
            {
              searchType: 2,
              value: search || ''
            }
          ]
        },
        filterBy: 'createdOn',
        orderBy: 2,
        operationType: 'Lease'
      };
      const res = await UnitsAdvanceSearchTest({ pageIndex: 0, pageSize: 10 }, body);

      if (!(res && res.status && res.status !== 200)) {
        const newArray = res && res.result && (res.result);

        setDetailsUnitsList({
          result: (newArray || []).map((item) => item && TemplatesPreviewDialogUnitMapper(item, JSON.parse(item.unitJson))),
          totalCount: (newArray && newArray.length) || 0,
        });
      } else {
        setDetailsUnitsList({
          result: [],
          totalCount: 0,
        });
      }
      setIsLoadingUnits(false);
    }, []
  );

  const getLeadsData = useCallback(async (search) => {
    setIsLoading((item) => ({ ...item, allLeads: true }));

    const res = await LeadsAdvanceSearchTest({
      pageSize: 25,
      pageIndex: 0,

    }, {

      criteria: {
        'contact_name.name': [
          {
            searchType: 2,
            value: search || ''
          }
        ]
      },
      filterBy: 'createdOn',
      orderBy: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      setDetailsLeadsList({
        result: ((res && res.result) || []).map((item) => {
          const lead = item.leadJson && JSON.parse(item.leadJson).lead;
          const leadTypeIndex = Object.values(LeadsClassTypesEnum).findIndex(
            (element) => element.key === item.leadClass
          );
          const leadClass =
            (leadTypeIndex !== -1 &&
              Object.values(LeadsClassTypesEnum)[leadTypeIndex].name) ||
            null;

          if (lead) {
            return {
              ...lead,
              leadClass,
              id: item.leadId,
              leadTypeId: lead.lead_type_id,
              imagePath: null,
              lastActivtyTypeName: item.lastActivtyTypeName,
              lastActivityComment: item.lastActivityComment,
              unitType: item.leadUnitType,
              country: item.country,
              city: item.city,
              isFavorite: item.isFavorite,
              district: item.district,
              community: item.community,
              subCommunity: item.subCommunity,
              createdBy: item.createdBy,
              updatedBy: item.updatedBy,
              budgetTo: item.budgetTo,
              budgetFrom: item.budgetFrom,
              sizeAreaTo: item.sizeAreaTo,
              sizeAreaFrom: item.sizeAreaFrom,
              sale_type :lead.sale_type ,
              lastActivityCreatedByName: item.lastActivityCreatedByName,
              name: `${(lead.contact_name && lead.contact_name.name) || ''}`,
              matchingUnits: lead.matching_units || [],
              matchingUnitsNumber: item.matchUnit || 0,
              updateDate: item.updateOn,
              leadType:
                (lead.lead_type_id === 1 &&
                  ((LeadsTypesEnum.Owner && LeadsTypesEnum.Owner.value) || 'N/A')) ||
                (lead.lead_type_id === 2 &&
                  ((LeadsTypesEnum.Seeker && LeadsTypesEnum.Seeker.value) || 'N/A')) ||
                'N/A',
              rating: lead.rating || '',
              fitting_and_fixtures:
                lead.fitting_and_fixtures && lead.fitting_and_fixtures.length > 0 ?
                  lead.fitting_and_fixtures.map((el) => `${el.lookupItemName}, `) :
                  [],
              size_sqft: lead.size_sqft && lead.size_sqft.join(' * ') || '',

              budget: lead.budget && lead.budget.join(' - ') || '',

              view: lead.view && lead.view.map((el) => `${el.lookupItemName}, `) || '',
              developer: lead.developer && lead.developer.map((el) => `${el.name}, `) || '',

              progress:
                lead.data_completed && typeof lead.data_completed === 'string' && lead.data_completed.includes('%') ?
                  +lead.data_completed.substr(0, lead.data_completed.length - 1) :
                  +lead.data_completed,
              progressWithPercentage:
                lead.data_completed && typeof lead.data_completed !== 'string' ?
                  `${lead.data_completed}%` :
                  lead.data_completed,
              status: (lead && lead.status) || 'N/A',
              flatContent: lead.lead_type_id === 2 && [
                {
                  iconClasses: 'mdi mdi-cash-multiple',
                  title: null,
                  value: lead.budget ?
                    lead.budget.map(
                      (element, index) =>
                        `${element}${(index < lead.budget.length - 1 && ',') || ''} `
                    ) :
                    'N/A',
                },
                {
                  iconClasses: 'mdi mdi-ruler-square',
                  title: 'sqf',
                  value: lead.size_sqft ?
                    lead.size_sqft.map(
                      (element, index) =>
                        `${element}${(index < lead.size_sqft.length - 1 && ',') || ''} `
                    ) :
                    'N/A',
                },
              ],
              details: [
                {
                  iconClasses: 'mdi mdi-clipboard-account-outline',
                  title: 'lead-type',
                  value:
                    lead.lead_type_id === 1 ?
                      t(`${translationPath}owner`) :
                      t(`${translationPath}seeker`),
                },
                {
                  iconClasses: 'mdi mdi-account-circle',
                  title: 'stage',
                  value: lead.lead_stage ? lead.lead_stage.lookupItemName : 'N/A',
                },
                {
                  iconClasses: 'mdi mdi-account-box',
                  title: 'contact-name',
                  value: lead.contact_name ? lead.contact_name.name : 'N/A',
                },

                {
                  iconClasses: 'mdi mdi-table-furniture',
                  title: 'equipments-and-fixtures',
                  value:
                    (lead.fitting_and_fixtures &&
                      lead.fitting_and_fixtures.map(
                        (element, index) =>
                          `${element.lookupItemName}${(index < lead.fitting_and_fixtures.length - 1 && ',') || ''
                          } `
                      )) ||
                    'N/A',
                },
                {
                  iconClasses: 'mdi mdi-window-open-variant',
                  title: 'views',
                  value:
                    (lead.view &&
                      ((Array.isArray(lead.view) &&
                        lead.view.map(
                          (element, index) =>
                            `${element.lookupItemName}${(index < lead.view.length - 1 && ',') || ''} `
                        )) ||
                        (typeof lead.view === 'object' && lead.view.lookupItemName) ||
                        'N/A')) ||
                    'N/A',
                },
                {
                  iconClasses: 'mdi mdi-laptop-windows',
                  title: 'developers',
                  value:
                    (lead.developers &&
                      lead.developers.map(
                        (element, index) =>
                          `${element.lookupItemName}${(index < lead.developers.length - 1 && ',') || ''
                          } `
                      )) ||
                    'N/A',
                },
              ],

            };
          }
        }),
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setDetailsLeadsList({
        result: [],
        totalCount: 0,
      });
    }

    setIsLoading((item) => ({ ...item, allLeads: false }));
  }, []);

  useEffect(() => {
    data.findIndex((element) => element === 'ActivityId') !== -1 && (getActivities());
    data.findIndex((element) => element === 'LeadId') !== -1 && (getLeadsData());
    data.findIndex((element) => element === 'UnitId') !== -1 && (getAllAvailableUnitsData());
  }, []);

  const onSizeClickedFun = useCallback(async (size) => {
    if (size === 'lg')
      setwithsize('xl');
    else setwithsize('lg');
  }, []);

  const printPartOfPage = async () => {
    const pri = document.getElementById('ifmcontentstoprint').contentWindow;
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <DialogComponent
      titleText={`${t(`${translationPath}preview-template`)} ${activeItem.templateName}`}
      maxWidth={withsize}
      reSize
      dialogContent={( 
        <div className='templates-preview-dialog'>
          {data.findIndex((element) => element ===  'UnitIds') !== -1 && (
            <div className='d-flex mb-2'>
              <Inputs
                idRef='unitIdRef'
                labelValue='unitId'
                value={state.unitIds || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setState((items) => ({
                    ...items,
                    unitIds: `${value}` || null,
                  }));
                }}
              />
            </div>
          ) || ''}
           {data.findIndex((element) => element === 'UnitId' ) !== -1 && (
            <div className='d-flex mb-2'>
              <Inputs
                idRef='unitIdRef'
                labelValue='unitId'
                value={state.unitId || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setState((items) => ({
                    ...items,
                    unitId: value || null,
                  }));
                }}
              />
              <AutocompleteComponent
                idRef='assignInquiryToUnitRef'
                data={detailsUnitsList && detailsUnitsList.result}
                inputPlaceholder='type-to-search-in-unit'
                labelValue='all-unit'
                multiple={false}
                displayLabel={(option) =>
                  (option && option.name) || ''}
                chipsLabel={(option) => (option && option.name) || ''}
                withoutSearchButton
                renderOption={
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>
                        {' '}
                        {option.refNo}
                        {' '}
                      </span>
                    </div>
                  )
                  )
                }
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllAvailableUnitsData(value);
                  }, 700);
                }}
                onChange={(e, newValue) => {
                  setState((items) => ({
                    ...items,
                    unitId: newValue && newValue.id || null,
                  }));
                }}
                isLoading={IsLoadingUnits}
                withLoader
              />
            </div>
          ) || ''}
          {data.findIndex((element) => element === 'LeadId') !== -1 && (
            <div className='d-flex'>
              <Inputs
                idRef='leadIdRef'
                labelValue='leadId'
                value={state && state.leadId || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setState((items) => ({
                    ...items,
                    leadId: value || null,
                  }));
                }}
              />
              <AutocompleteComponent
                idRef='assignInquiryTleadRef'
                data={DetailsLeadsList && DetailsLeadsList.result || ''}
                inputPlaceholder='type-to-search-in-lead'
                labelValue='all-lead'
                multiple={false}
                displayLabel={(option) =>
                  (option && option.name) || ''}
                chipsLabel={(option) => (option && option.name) || ''}
                withoutSearchButton
                renderOption={
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        {option.name}
                      </div>
                      <span className='c-gray-secondary'>
                        {' '}
                        {option.refNo}
                        {' '}
                      </span>
                    </div>
                  )
                  )
                }
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getLeadsData(value);
                  }, 700);
                }}
                onChange={(e, newValue) => {
                  setState((items) => ({
                    ...items,
                    leadId: newValue && newValue.id || null,
                  }));
                }}
                isLoading={IsLoading.allLeads}
                withLoader
              />
            </div>
          ) || ''}
          {data.findIndex((element) => element === 'ContactId') !== -1 && (
            <Inputs
              idRef='contactIdRef'
              labelValue='contactId'
              value={state && state.contactId || ''}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const { value } = event.target;
                setState((items) => ({
                  ...items,
                  contactId: value || null,
                }));
              }}

            />
          ) || ''}
          {data.findIndex((element) => element === 'ActivityId') !== -1 && (
            <div className='d-flex'>
              {' '}
              <Inputs
                idRef='activityIdRef'
                labelValue='activityId'
                value={state && state.activityId || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  setState((items) => ({
                    ...items,
                    activityId: value || null,
                  }));
                }}
              />
              <AutocompleteComponent
                idRef='assignActivitiesitRef'
                data={Activities && Activities || []}
                inputPlaceholder='type-to-search-in-Activities'
                labelValue='all-Activities'
                multiple={false}
                displayLabel={(option) =>
                  (option && option.activityType && option.activityType.activityTypeName) || ''}
                chipsLabel={(option) => (option && option.activityType && option.activityType.activityTypeName) || ''}
                withoutSearchButton
                renderOption={
                  ((option) => (
                    <div className='d-flex-column'>
                      <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                        activity Id :
                        {' '}
                        {' '}
                        {option.activityId || ''}
                      </div>
                      <span className='c-gray-secondary'>
                        {' '}
                        {option.agentName}
                        {' '}
                        /
                        {' '}
                        {option.contactName}
                        {' '}
                        /
                        {' '}
                        {option.createdByName}
                        /
                        {' '}
                        {option.activityType.activityTypeName || ''}
                        {' '}
                        {option.activityDate}
                        /
                        {' '}
                      </span>
                    </div>
                  )
                  )
                }
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getActivities(value);
                  }, 700);
                }}
                onChange={(e, newValue) => {
                  setState((items) => ({
                    ...items,
                    activityId: newValue && newValue.activityId || null,
                  }));
                }}
                isLoading={IsLoadingActivities}
                withLoader
              />
            </div>

          ) || ''}
          {data.findIndex((element) => element === 'UserId') !== -1 && (
            <Inputs
              idRef='UserIddRef'
              labelValue='UserId'
              value={state && state.UserId || ''}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const { value } = event.target;
                setState((items) => ({
                  ...items,
                  UserId: value || null,
                }));
              }}

            />
          ) || ''}
          <div style={{ position: 'relative' }}>
            <Spinner isActive={IsLoadingRunSystemTemplate} />
            <div className='PrintorsavePDF'>
              {/* <ButtonBase
                className='btns theme-outline mb-2 '

              >
                <span className='mdi mdi-printer' />
                {t(`${translationPath}Printorsave`)}
                <span className='mdi mdi-file-pdf-box' />
              </ButtonBase> */}

            </div>
            <div id='divcontents' style={{ display: 'none' }}>
              {/* {HeaderAndFooter.documentsHeaderImageId !== null && (
                <header className='header'>
                  <img alt='HeaderPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsHeaderImageId) || null} style={{ width: '100%', maxHeight }} />
                </header>
              )} */}

             <style>
             {mediaprint}
              </style> 
              {isCheckedHeader && HeaderAndFooter.documentsHeaderImageId !== null && (
                <div className='page-header'>
                  <img alt='FooterPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsHeaderImageId) || null} style={{ width: '100%', maxHeight }} />
                </div>
              )}

              <table>

                {isCheckedHeader && HeaderAndFooter.documentsHeaderImageId !== null && (
                  <thead>
                    <tr>
                      <td>
                        <div className='page-header-space' />
                      </td>
                    </tr>
                  </thead>
                )}

                <tbody>
                  <tr>
                    <td>
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: file }}
                      />
                    </td>
                  </tr>
                </tbody>
                {isCheckedfooter && HeaderAndFooter.documentsFooterImageId !== null && (
                  <tfoot>
                    <tr>
                      <td>
                        <div className='page-footer-space' />
                      </td>
                    </tr>
                  </tfoot>
                )}

              </table>
              {isCheckedfooter && HeaderAndFooter.documentsFooterImageId !== null && (
                <div className='page-footer'>
                  <img alt='FooterPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsFooterImageId) || null} style={{ width: '100%', maxHeight }} />
                </div>

              )}
              {/* {HeaderAndFooter.documentsFooterImageId !== null && (
                <footer>
                  <img alt='FooterPSITemplet' src={getDownloadableLink(HeaderAndFooter.documentsFooterImageId) || null} style={{ width: '100%', maxHeight }} />
                </footer>
              )} */}
            </div>
            <iframe
              id='ifmcontentstoprint'
              title='ifmcontentstoprint'
              style={{ minHeight: 500 }}
              width='100%'
              height='100%'
            />

          </div>

          {/* <SunEditor
              idRef='SunEditorMIANview'
              disableToolbar={false}
              disable
              setOptions={{
                showPathLabel: false,
                minHeight: '50vh',
                maxHeight: '50vh',
                buttonList: [
                  ['fullScreen', 'preview', 'print']],
              }}
              setContents={Content}
            /> */}

          <DialogActions>
            <div className='unit-template-action-btns'>
              <CheckboxesComponent
                idRef='isCheckedHeader'
                translationPathForData={translationPath}
                translationPath={translationPath}
                label='With Header'
                singleChecked={isCheckedHeader}
                onSelectedCheckboxClicked={() => {
                  setIsCheckedHeader(!isCheckedHeader);
                  RunSystemTemplateHtmlFis(state, true);
                }}
              />
              <CheckboxesComponent
                idRef='isCheckedfooter'
                translationPathForData={translationPath}
                translationPath={translationPath}
                label='With footer'
                singleChecked={isCheckedfooter}
                onSelectedCheckboxClicked={() => {
                  setIsCheckedfooter(!isCheckedfooter);
                  RunSystemTemplateHtmlFis(state, true);
                }}
              />
            </div>
          </DialogActions>
          <div className='print-icon-wwww'>
            <ButtonBase
              className='print-button'
              onClick={() => {
                printPartOfPage();
              }}
            >
              <span className='print-icon' />

            </ButtonBase>
          </div>
        </div>
      )}
      saveType='button'
      isOpen={isOpen}
      onCancelClicked={isOpenChanged}
      onSizeClicked={() => onSizeClickedFun(withsize)}
      saveText='view template'
      onSaveClicked={() => RunSystemTemplateHtmlFis(state)}
      onCloseClicked={isOpenChanged}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};

TemplatesTemplatebuilderComponentPreviewDialog.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  isOpen: PropTypes.bool.isRequired,
  isOpenChanged: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  maintitleText: PropTypes.string,
};
TemplatesTemplatebuilderComponentPreviewDialog.defaultProps = {
  activeItem: null,
  maintitleText: 'preview-template',
};
