import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from "@material-ui/core";
import {
  Spinner,
  AutocompleteComponent,
  CheckboxesComponent,
} from "../../../../../../Components";
import {
  GetRemainingLeadAssignmentCapacity,
  OrganizationUserSearch,
} from "../../../../../../Services";
import { LeadsClassTypesEnum, AgentRoleEnum } from "../../../../../../Enums";
import { UserStatusEnum } from "../../../../../../Enums";
import { showError } from "../../../../../../Helper";

export const LeadsReassignDialog = ({
  isOpen,
  onSave,
  translationPath,
  parentTranslationPath,
  onClose,
  leadType,
  isLoadingReassign,
  value,
  isAssignToMe,
  CheckAgentLeadsInPoolWithoutRecentActivityAPICall,
  isPass,
  checkedCardsIds,
  fetchLeadPoolSummary,
  setFetchLeadPoolSummary
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [saveDisable, setSaveDisable] = useState(false);
  const [remainingLeadCapacity, setRemainingLeadCapacity] = useState(null);
  const [reachedMoreThanCapacity, setReachedMoreThanCapacity] = useState(null);
  const searchTimer = useRef(null);
  const [state, setState] = useState({
    referredToId: null,
    isCopyTo: false,
  });
  const [agentList, setAgentList] = useState({
    result: [],
    totalCount: 0,
  });
  const [selectedValue, setSelectedValue] = useState(null);
  const getSaleOrLeaseAgents = useCallback(async ({ value }) => {
    setIsLoading(true);
    let userTypeId = null;
    if (leadType === "Buyer" || leadType === LeadsClassTypesEnum.buyer.key)
      userTypeId = AgentRoleEnum.SaleAgent.value;
    if (leadType === "Tenant" || leadType === LeadsClassTypesEnum.tenant.key)
      userTypeId = AgentRoleEnum.LeaseAgent.value;
    if (leadType === "Seller" || leadType === LeadsClassTypesEnum.seller.key)
      userTypeId = AgentRoleEnum.SaleListingAgent.value;
    if (
      leadType === "Landlord" ||
      leadType === LeadsClassTypesEnum.landlord.key
    )
      userTypeId = AgentRoleEnum.LeaseListingAgent.value;
    const res = await OrganizationUserSearch({
      pageSize: 20,
      pageIndex: 0,
      name: value,
      userTypeId: userTypeId,
      userStatusId: UserStatusEnum.Active.key,
    });
    if (!(res && res.status && res.status !== 200)) {
      setAgentList({
        result: res.result,
        totalCount: res && res.totalCount,
      });
      if (isAssignToMe) {
        setSelectedValue(res.result?.[0]);
        setState((item) => ({ ...item, referredToId: res.result?.[0]?.id }));
      }
    } else {
      setAgentList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, []);

  async function checkIsPass() {
    isPass = await CheckAgentLeadsInPoolWithoutRecentActivityAPICall(
      "sendOrReassign",
      state.referredToId
    );
  }

  async function GetRemainingLeadAssignmentCapacityForLead() {
    setIsLoading(true);
    const res = await GetRemainingLeadAssignmentCapacity(state.referredToId);
    if (!(res && res.status && res.status !== 200)) {
      setRemainingLeadCapacity(Number(res));
      if (checkedCardsIds.length > Number(res)){
        showError(`${t("you-have-reached-your-lead-assignment-capacity-limit-you-can-only-assign")} ${Number(res)}`);
        setReachedMoreThanCapacity(true);
      } else {
        setReachedMoreThanCapacity(false);
      }
    } else {
      showError(t("error-fetching-data"));
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getSaleOrLeaseAgents({ value: value });
  }, []);

  useEffect(() => {
    if (
      state.referredToId &&
      CheckAgentLeadsInPoolWithoutRecentActivityAPICall
    ) {
      checkIsPass();
      GetRemainingLeadAssignmentCapacityForLead();
    }
  }, [state.referredToId]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        disableBackdropClick
        className="activities-management-dialog-wrapper"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {t(`${translationPath}reassign-leads`)}
        </DialogTitle>
        <DialogContent>
          <div className="dialog-content-wrapper">
            <Spinner isActive={isLoadingReassign} isAbsolute />
            <div className="dialog-content-item w-100">
              <AutocompleteComponent
                idRef="referredToIdRef"
                multiple={false}
                labelValue={t(`${translationPath}referredTo`)}
                isLoading={isLoading}
                withoutSearchButton
                data={(agentList && agentList.result) || []}
                chipsLabel={(option) => (option && option.agentName) || ""}
                displayLabel={(option) =>
                  (option && option.fullName) || option.name || ""
                }
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                inputPlaceholder={t(`${translationPath}referredTo`)}
                onChange={(event, newValue) => {
                  setSelectedValue(newValue);
                  setState((item) => ({
                    ...item,
                    referredToId: newValue && newValue.id,
                  }));
                }}
                selectedValues={selectedValue}
                isDisabled={isAssignToMe}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getSaleOrLeaseAgents({ value: value });
                  }, 500);
                }}
              />
            </div>
            <div className="dialog-content-item">
              <CheckboxesComponent
                idRef="rwithCopyAllActivitesRef"
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                label="withCopyAllActivites"
                singleChecked={state.isCopyTo}
                onSelectedCheckboxClicked={() => {
                  setState((items) => ({
                    ...items,
                    isCopyTo: !state.isCopyTo,
                  }));
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="form-builder-wrapper">
            <div className="form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between">
              <div className="MuiGrid-root-right">
                <ButtonBase
                  disabled={
                    saveDisable ||
                    !state.referredToId ||
                    isPass === false ||
                    reachedMoreThanCapacity
                  }
                  className="MuiButtonBase-root MuiButton-root MuiButton-text btns theme-solid"
                  onClick={async () => {
                    setSaveDisable(true);
                    if (isPass) {
                      setSaveDisable(false);
                      onSave(state);
                    } else {
                      onSave(state);
                    }
                  }}
                >
                  <span className="MuiButton-label">
                    <span className="mx-2">{t(`${translationPath}save`)}</span>
                  </span>
                  <span className="MuiTouchRipple-root" />
                </ButtonBase>
              </div>
              <div className="MuiGrid-root-right">
                <ButtonBase
                  className="MuiButtonBase-root MuiButton-root MuiButton-text btns"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="MuiButton-label">
                    <span className="mx-2">
                      {t(`${translationPath}cancel`)}
                    </span>
                  </span>
                  <span className="MuiTouchRipple-root" />
                </ButtonBase>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

LeadsReassignDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
};
