import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DialogComponent, AutocompleteComponent, Spinner, DataFileAutocompleteComponent } from '../../../../../../../Components';
// import { ButtonBase } from '@material-ui/core';
import { GetContacts, GetAllContactRelationships, CreateAssociatedContact, UpdateAssociatedContact } from '../../../../../../../Services';
import { showError, showSuccess, GetParams } from '../../../../../../../Helper';

export const AssociatedDiloag = ({
    openAddDialog,
    t,
    translationPath,
    parentTranslationPath,
    setFilter,
    setOpenAddDialog,
    filter,
    activeItem,
    reloadData,
    allAssociated
}) => {
    const searchTimer = useRef(null);
    const [loading, setLoading] = useState(false);
    const [saveIsDisabled, setSaveIsDisabled] = useState(true);
    const [associatedContactData, setAssociatedContactData] = useState([]);
    const [selectedAssociatedContact, setSelectedAssociatedContact] = useState(null);
    const [selectedRelationship, setSelectedRelationship] = useState(null);
    const [relationshipData, setrelationshipData] = useState([]);
    const saveHandler = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);
        let payload = {
            contactId: GetParams('id'),
            contactRelationshipId: selectedRelationship && selectedRelationship.contactRelationshipId || null,
            relatedAssociatedContactsId: selectedAssociatedContact && selectedAssociatedContact.contactsId || null,
        }
        let res = activeItem && await UpdateAssociatedContact(payload, activeItem.associatedContactId) || await CreateAssociatedContact(payload);
        if (!(res && res.status && res.status !== 200)) {
            if (activeItem && activeItem.associatedContactId) showSuccess(t(`${translationPath}edit-associated-successfully`))
            else showSuccess(t(`${translationPath}add-associated-successfully`));
        }
        else {
            if (activeItem && activeItem.associatedContactId) showError(t(`${translationPath}edit-associated-failed`))
            else showError(t(`${translationPath}add-associated-failed`));
        }
        reloadData()
        setLoading(false);
        setSaveIsDisabled(true);
        setOpenAddDialog(false);
    });

    const searchHandler = (e) => {
        const { value } = e.target;
        if (searchTimer.current) {
            clearTimeout(searchTimer.current);
        }
        searchTimer.current = setTimeout(() => {
            setFilter((f) => ({ ...f, search: value }))
        }, 700);
    }
    const getAllAssociatedContact = useCallback(async () => {
        setLoading(true);
        let res = await GetContacts({ ...filter, search: filter.search, isAdvance: false, contactsType: GetParams('formType') });
        if (!(res && res.status && res.status !== 200)) {
            let filterAssociated = res.result.filter((el) => allAssociated.findIndex((item) => item.relatedAssociatedContactsId === el.contactsId) === -1)
            setAssociatedContactData(filterAssociated);
        }
        else setAssociatedContactData(null);
        setLoading(false);
    }, [filter]);

    const getAllContactRelationships = useCallback(async () => {
        setLoading(true);
        const res = await GetAllContactRelationships(GetParams('formType'));
        if (!(res && res.status && res.status !== 200)) setrelationshipData(res);
        else setrelationshipData([]);
        setLoading(false);
    }, [])
    useEffect(() => {
        getAllAssociatedContact();
    }, [filter]);
    useEffect(() => {
        getAllContactRelationships();
    }, []);
    useEffect(() => {
        if (selectedRelationship && selectedAssociatedContact) setSaveIsDisabled(false);
        else setSaveIsDisabled(true);
    }, [selectedRelationship, selectedAssociatedContact]);


    return (
        <>
            <Spinner isActive={loading} />
            <div className='add-view-wrapper d-flex'>
                <DialogComponent
                    isOpen={openAddDialog}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    titleText={activeItem && 'edit-associated' || 'add-associated'}
                    maxWidth='sm'
                    onSaveClicked={saveHandler}
                    saveText={activeItem && 'edit-associated' || 'add-associated'}
                    onCancelClicked={() => setOpenAddDialog(false)}
                    disableBackdropClick
                    saveIsDisabled={saveIsDisabled}
                    dialogContent={(
                        <>
                            <div className='add-content'>
                                <div className='auto-wrapper'>
                                    <div className='mb-3'>
                                        <DataFileAutocompleteComponent
                                            idRef='associatedContactRef'
                                            labelValue='associatedContact'
                                            multiple={false}
                                            data={associatedContactData || []}
                                            withoutSearchButton
                                            isLoading={loading}
                                            chipsLabel={(option) =>
                                                option.contact &&
                                                option.contact.contact_type_id &&
                                                (option.contact.contact_type_id === 2 ?
                                                    `${option.contact.company_name}` :
                                                    `${option.contact.first_name} ${option.contact.last_name}` || '')
                                            }
                                            filterOptions={(options) => {

                                                // const isFind = (id) => allAssociated.findIndex((item) => item.relatedAssociatedContactsId === id) === -1;
                                                return options.filter((el) => parseInt(GetParams('id')) !== el.contactsId );
                                            }}
                                            displayLabel={(option) =>
                                                option.contact &&
                                                option.contact.contact_type_id &&
                                                (option.contact.contact_type_id === 2 ?
                                                    `${option.contact.company_name}` :
                                                    `${option.contact.first_name} ${option.contact.last_name}` || '')
                                            }
                                            onInputKeyUp={searchHandler}
                                            onChange={(event, newInput) => {
                                                setSelectedAssociatedContact(newInput);
                                            }}
                                            translationPath={translationPath}
                                            parentTranslationPath={parentTranslationPath}
                                        />
                                    </div>
                                    <AutocompleteComponent
                                        idRef='relationshipRef'
                                        labelValue='relationship'
                                        multiple={false}
                                        data={relationshipData || []}
                                        withoutSearchButton
                                        chipsLabel={(option) => option.contactRelationshipName && `${option.contactRelationshipName}` || ''}
                                        displayLabel={(option) => option.contactRelationshipName && `${option.contactRelationshipName}` || ''}
                                        isLoading={loading}
                                        onChange={(event, newInput) => {
                                            setSelectedRelationship(newInput);
                                        }}
                                        translationPath={translationPath}
                                        parentTranslationPath={parentTranslationPath}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                />
            </div>
        </>
    )
}
AssociatedDiloag.prototype = {
    parentTranslationPath: PropTypes.string.isRequired,
    translationPath: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    setOpenAddDialog: PropTypes.func.isRequired,
    openAddDialog: PropTypes.bool.isRequired,
};
