export const PowerBiEnumDubai = {
  salesToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8c81de09-4fc8-4d21-bb77-4728702afb93',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8c81de09-4fc8-4d21-bb77-4728702afb93&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newsalesToken: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '0e3b410d-2869-491d-9133-81bac92bf9e5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=0e3b410d-2869-491d-9133-81bac92bf9e5&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  teamleadToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f5b41fe0-31bb-4731-bd6d-88f7e149f46e',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f5b41fe0-31bb-4731-bd6d-88f7e149f46e&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  agentToken: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '3002f583-091e-473a-9ff2-2c3e96d14e39',
    url: 'https://app.powerbi.com/reportEmbed?reportId=3002f583-091e-473a-9ff2-2c3e96d14e39&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  leaseAgentToken: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'b787e3a2-6742-4afc-8d5b-bfc548aff9a7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b787e3a2-6742-4afc-8d5b-bfc548aff9a7&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  leasingToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '2c42857e-2ed6-4e51-8bbd-e5670ec2df7d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2c42857e-2ed6-4e51-8bbd-e5670ec2df7d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  NewleasingToken: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '88294212-bbad-40ff-b709-3f20fc628ba7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=88294212-bbad-40ff-b709-3f20fc628ba7&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  callCenter: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '0667ec04-e216-4397-a91a-7e910126f0c2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=0667ec04-e216-4397-a91a-7e910126f0c2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newcallCenter: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '39db80a3-4efe-44ad-85c7-64dc1b905e32',
    url: 'https://app.powerbi.com/reportEmbed?reportId=39db80a3-4efe-44ad-85c7-64dc1b905e32&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  marketing: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8bfa5689-bc70-4027-a57d-98100119ab29',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8bfa5689-bc70-4027-a57d-98100119ab29&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newmarketing: {
    groupid: "e912c014-0b6c-4c28-bbea-42472b6776d9",
    reportid: "908bbeea-a0c0-4a57-909f-f35c87a03e10",
    url: "https://app.powerbi.com/reportEmbed?reportId=908bbeea-a0c0-4a57-909f-f35c87a03e10&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19",
  },
  psidaily: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '9fc2cabd-c713-4ff5-a26f-e114d7b0e915',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9fc2cabd-c713-4ff5-a26f-e114d7b0e915&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  Newpsidaily: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '09069a6c-92a9-4a74-8793-0429dcfdec47',
    url: 'https://app.powerbi.com/reportEmbed?reportId=09069a6c-92a9-4a74-8793-0429dcfdec47&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  saleslistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '34e7829b-ed37-4501-b6c1-4bccdcb812c6',
    url: 'https://app.powerbi.com/reportEmbed?reportId=34e7829b-ed37-4501-b6c1-4bccdcb812c6&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newsaleslistingmanager: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '08d51a5a-701e-45ea-b181-cc443650cac1',
    url: 'https://app.powerbi.com/reportEmbed?reportId=08d51a5a-701e-45ea-b181-cc443650cac1&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesListingUnitsDate: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '8b2765ad-ca4c-46c4-902b-e2fe72be58a0',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8b2765ad-ca4c-46c4-902b-e2fe72be58a0&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  LeaseListingUnitsDate: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '91532298-31ca-4faf-a7dc-a6e0be59dd15',
    url: 'https://app.powerbi.com/reportEmbed?reportId=91532298-31ca-4faf-a7dc-a6e0be59dd15&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  leaselistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f923e598-53d8-4195-ac75-25e6c90ce3ed',
    url: 'https://app.powerbi.com/reportEmbed?reportId=ff378a3e-c53d-4211-b77a-9d3630ddb4d7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  Newleaselistingmanager: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'b5db4cfb-f60d-438f-89aa-a1896385fdef',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b5db4cfb-f60d-438f-89aa-a1896385fdef&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  accounts: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'a72078c0-930f-41cf-99e9-9769d68ee295',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a72078c0-930f-41cf-99e9-9769d68ee295&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  intentionalsales: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '7eb18dc4-9f6c-450f-8001-6172296b1d45',
    url: 'https://app.powerbi.com/reportEmbed?reportId=7eb18dc4-9f6c-450f-8001-6172296b1d45&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  dubai_lease_listing_admin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6f4bc7fa-d9c7-42dd-b3b3-afeb0021ce9d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6f4bc7fa-d9c7-42dd-b3b3-afeb0021ce9d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_lease_listing_agent: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'c6dfbed6-2122-4590-ae8a-a272fd4b42e5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=c6dfbed6-2122-4590-ae8a-a272fd4b42e5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_sales_listing_admin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '98da1945-916c-495e-9f4a-93b751c67788',
    url: 'https://app.powerbi.com/reportEmbed?reportId=98da1945-916c-495e-9f4a-93b751c67788&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_sales_listing_agent: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'a4ef57e8-568f-4eea-ad5e-97261b8a05b5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a4ef57e8-568f-4eea-ad5e-97261b8a05b5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_1: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '9e4b4927-25ba-465e-8ec9-958f117d000c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9e4b4927-25ba-465e-8ec9-958f117d000c&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  tab_2: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'b89a7df7-2029-4757-b8d1-3244fb75ab8c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b89a7df7-2029-4757-b8d1-3244fb75ab8c&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_3: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8d99ee48-042c-4853-977b-bbc587770ac2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8d99ee48-042c-4853-977b-bbc587770ac2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_4: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '78cee25c-b284-4187-8ef8-4705ae48d709',
    url: 'https://app.powerbi.com/reportEmbed?reportId=78cee25c-b284-4187-8ef8-4705ae48d709&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  tab_5: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '1b96fedf-1b21-4e4e-8e1b-99798828cd34',
    url: 'https://app.powerbi.com/reportEmbed?reportId=1b96fedf-1b21-4e4e-8e1b-99798828cd34&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  tab_6: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'd4e89b95-38f0-403b-9200-5e0d91d347e1',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d4e89b95-38f0-403b-9200-5e0d91d347e1&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  tab_7: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'fdfc7e38-bc6d-437e-83d0-c777e0192e98',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fdfc7e38-bc6d-437e-83d0-c777e0192e98&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_8: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '3cb4ef98-043e-49bb-8c7f-9dfde1d493af',
    url: 'https://app.powerbi.com/reportEmbed?reportId=3cb4ef98-043e-49bb-8c7f-9dfde1d493af&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_9: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '85ad52b7-bd56-4f81-9d8b-5e35d2af7fa7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=85ad52b7-bd56-4f81-9d8b-5e35d2af7fa7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_10: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '3277b670-1f6e-4d21-b60d-5beb2fabb925',
    url: 'https://app.powerbi.com/reportEmbed?reportId=3277b670-1f6e-4d21-b60d-5beb2fabb925&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  MarketingClientsSegmentation: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '82b16933-7676-4c7e-8291-97036ca43ae5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=82b16933-7676-4c7e-8291-97036ca43ae5&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  Branches: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'acb48682-6a72-408e-9758-360667bdc20a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=acb48682-6a72-408e-9758-360667bdc20a&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  Salesdirector: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6ca9e9db-9a0b-40f1-9262-82380d5a387b',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6ca9e9db-9a0b-40f1-9262-82380d5a387b&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  Leasedirector: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'bb5e7954-b3cc-4a5f-aeb7-1d4930a2dc73',
    url: 'https://app.powerbi.com/reportEmbed?reportId=bb5e7954-b3cc-4a5f-aeb7-1d4930a2dc73&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  Leasedetails: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'c5a1a5e3-8c21-4040-9148-a2e39d188c2d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=c5a1a5e3-8c21-4040-9148-a2e39d188c2d&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  LeadOwner: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '74c8694e-45ad-4ec1-8ed7-18a906d4b7b2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=74c8694e-45ad-4ec1-8ed7-18a906d4b7b2&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  UserLogin: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '32699d2b-497a-4db3-a11c-ed965df9492c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=32699d2b-497a-4db3-a11c-ed965df9492c&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  ActivatedLeadid: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '5a8b29d9-857c-40d7-8c39-5656fd2e1762',
    url: 'https://app.powerbi.com/reportEmbed?reportId=5a8b29d9-857c-40d7-8c39-5656fd2e1762&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  CustomersRisk: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '857a3528-7db1-4cc1-b7c0-37ec69471913',
    url: 'https://app.powerbi.com/reportEmbed?reportId=857a3528-7db1-4cc1-b7c0-37ec69471913&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  PropertyFinder: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'f1f7cb4f-40e5-4be4-8a1c-af9707820b1d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f1f7cb4f-40e5-4be4-8a1c-af9707820b1d&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  Bayut: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '470566f9-b059-494d-994b-61bd5682ef07',
    url: 'https://app.powerbi.com/reportEmbed?reportId=470566f9-b059-494d-994b-61bd5682ef07&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesAgentplus: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'feeef787-ce7d-49ae-9d2d-05423927b9bb',
    url: 'https://app.powerbi.com/reportEmbed?reportId=feeef787-ce7d-49ae-9d2d-05423927b9bb&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesTeamLeadplus: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'cd34cc8d-f591-4854-b74d-c29b42b7337d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=cd34cc8d-f591-4854-b74d-c29b42b7337d&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  SalesDirectorplus: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'd8933fdc-5eb9-4c8c-b70d-220becbf5a7f',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d8933fdc-5eb9-4c8c-b70d-220becbf5a7f&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  LeaseDirectorplus: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '16095914-8246-4331-8486-65a299bf6b39',
    url: 'https://app.powerbi.com/reportEmbed?reportId=16095914-8246-4331-8486-65a299bf6b39&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  SalesDirectorplus_Date: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '46ebb0a9-269f-4b98-a0c0-6b4f2bf0311c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=46ebb0a9-269f-4b98-a0c0-6b4f2bf0311c&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseDirectorplus_Date: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '34aa21e1-eb91-4423-a4a6-d4a9e72df1db',
    url: 'https://app.powerbi.com/reportEmbed?reportId=34aa21e1-eb91-4423-a4a6-d4a9e72df1db&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseAgentplus: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '100ede61-fc2d-427d-a47b-d1effe88097c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=100ede61-fc2d-427d-a47b-d1effe88097c&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  LeaseTeamLeadplus: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '2641f9bc-d76c-414d-a273-83694ce63a39',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2641f9bc-d76c-414d-a273-83694ce63a39&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  SalesTeamLeadDate: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '0945b572-c44d-4ef1-970f-7f8927e4dfba',
    url: 'https://app.powerbi.com/reportEmbed?reportId=0945b572-c44d-4ef1-970f-7f8927e4dfba&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseTeamLeadDate: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '304a8b13-bc0e-4f34-8d62-488743f58963',
    url: 'https://app.powerbi.com/reportEmbed?reportId=304a8b13-bc0e-4f34-8d62-488743f58963&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  RotationScheme: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '64614fc0-5f5d-4f9b-b47e-3fb2ed5f8f6c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=64614fc0-5f5d-4f9b-b47e-3fb2ed5f8f6c&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  CampaignCost: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '2cb8e997-54e1-453b-9be6-9e668f0b58cd',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2cb8e997-54e1-453b-9be6-9e668f0b58cd&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  SegmentationLeadBranch: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '16f734f5-159f-4c3c-b117-bc79f2f34330',
    url: 'https://app.powerbi.com/reportEmbed?reportId=16f734f5-159f-4c3c-b117-bc79f2f34330&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  SegmentationContactBranch: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '7f485d18-388f-4bd0-a431-9a593dd4a33e',
    url: 'https://app.powerbi.com/reportEmbed?reportId=7f485d18-388f-4bd0-a431-9a593dd4a33e&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  SegmentationOwnerBranch: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'de7c0e2a-dc9d-4c8d-95e1-e7d16d77f1af',
    url: 'https://app.powerbi.com/reportEmbed?reportId=de7c0e2a-dc9d-4c8d-95e1-e7d16d77f1af&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  callCenterDate: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '39db80a3-4efe-44ad-85c7-64dc1b905e32',
    url: 'https://app.powerbi.com/reportEmbed?reportId=39db80a3-4efe-44ad-85c7-64dc1b905e32&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  LeadAutomation: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'b68297c8-ece9-47e6-889d-f6c7a849763a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b68297c8-ece9-47e6-889d-f6c7a849763a&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  UnqualifiedLeads: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '86dc7ce4-b699-4ec6-b4bc-97bc29f02314',
    url: 'https://app.powerbi.com/reportEmbed?reportId=86dc7ce4-b699-4ec6-b4bc-97bc29f02314&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  InternationalSalesYTDMTD: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '34fc95ab-c84e-43d2-a9b7-18147ceac61d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=34fc95ab-c84e-43d2-a9b7-18147ceac61d&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  UsersDetails: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'c301bf66-b572-4134-af22-7a38937ae15a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=c301bf66-b572-4134-af22-7a38937ae15a&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  PortalsCampaignChart: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '9fcc1e52-5f6c-4fe9-bce4-4d7b003633b8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9fcc1e52-5f6c-4fe9-bce4-4d7b003633b8&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  ListingAllUnits: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '5f5adb5c-f5f0-475d-b3a7-db1db0c79aeb',
    url: 'https://app.powerbi.com/reportEmbed?reportId=5f5adb5c-f5f0-475d-b3a7-db1db0c79aeb&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  AML: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'd4af7e13-0426-4d9a-96c8-2c0fb18fec45',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d4af7e13-0426-4d9a-96c8-2c0fb18fec45&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Projecttask: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'fee46b33-afc0-4ed7-9016-b32cb7e6978f',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fee46b33-afc0-4ed7-9016-b32cb7e6978f&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  CampaignRequest: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '234e5fba-8dd3-42dc-b92a-a52e3f434fa0',
    url: 'https://app.powerbi.com/reportEmbed?reportId=234e5fba-8dd3-42dc-b92a-a52e3f434fa0&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadPool: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '98f0bb22-7ff1-4f51-967e-e5bd54d3516a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=98f0bb22-7ff1-4f51-967e-e5bd54d3516a&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadFromPool: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'fd648a4c-6e7d-4702-9bf6-16e692aec52a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fd648a4c-6e7d-4702-9bf6-16e692aec52a&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  AdminLeadFromPool: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'fa33c2df-542d-4116-af49-eda17c3d5bba',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fa33c2df-542d-4116-af49-eda17c3d5bba&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  zeromatching: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '3eba1b15-06b6-438b-b168-582f5e9c38c9',
    url: 'https://app.powerbi.com/reportEmbed?reportId=3eba1b15-06b6-438b-b168-582f5e9c38c9&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  developerProperty: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '93d3aed8-1018-44fc-98cc-082e364745da',
    url: 'https://app.powerbi.com/reportEmbed?reportId=93d3aed8-1018-44fc-98cc-082e364745da&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  activity: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: '083c6cda-756c-4e1d-af8f-9a6077cc915d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=083c6cda-756c-4e1d-af8f-9a6077cc915d&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Ai_Call: {
    groupid: 'e912c014-0b6c-4c28-bbea-42472b6776d9',
    reportid: 'c8577f8d-548a-442a-b89d-4cf527cec78f',
    url: 'https://app.powerbi.com/reportEmbed?reportId=c8577f8d-548a-442a-b89d-4cf527cec78f&groupId=e912c014-0b6c-4c28-bbea-42472b6776d9&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
};