import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { ButtonBase } from '@material-ui/core';
import { InputAdornment } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  PhonesComponent,
  CheckboxesComponent,
  DatePickerComponent,
} from "../../../../../Components";
import {
  GetParams,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../Helper";
import { BranchesLocationTable    } from './BranchesLocationComponents' ; 
import { BranchLocationManagementDialog  } from './BranchesLocationComponents' ; 

import {  GetLookupItemsByLookupTypeName} from '../../../../../Services' ; 

export const BranchesLocation = ({
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
   const  [isLoading , setIsLoading] = useState({save : false  , info : false}) ;
   const [isOpenEditDialog ,  setIsOpenEditDialog] = useState(false);

  const [items , setItems] = useState({
    countries :[]  , 
    cities : [] , 
    districts : [] , 
    communities : [] ,
    subCommunities : []

  })  ; 

  const [defaultSelected, setDefaultSelected] = useState({
    employeeName :  null , 
    jobTitle : null , 
    emailAddress : null ,  
    mobileNumber : null   , 
    linkedInProfile  : null , 
    employeePhoto : null 
   
  });

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };

  const [state, setState] = useReducer(reducer, {
    ...defaultSelected,
  });

  const getLookupItemsByName = async (lookUpName , key ) => {

    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(result && result.status && result.status !== 200)) {
        setItems((item) => ({ ...item, [key]: (result && result.result) || []   }));
    } 
    else  setItems((item) => ({ ...item, [key]: []}));
    setIsLoading((item) => ({ ...item, [key]: false }));

  };

  return (
    <div className="agreement-details-deveoloper-wrapper childs-wrapper d-flex-column">
      <div className="w-100 agreement-details-add">
        <div className="w-100">
          <div className="part1 mt-2 mb-2">
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"branch-NameRef"}
                labelValue={"branch-name"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            
            <div className="px-2 item">
            <AutocompleteComponent
                key={`country`}
                selectedValues={state.country}
                idRef={`countryRef`}
                multiple={false}
                inputPlaceholder={t("country")}
                data={items &&  items.countries}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.countries}
                withLoader
                withoutSearchButton
                labelValue={"country"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.countries?.length === 0 )
                 getLookupItemsByName('country' , 'countries');
                }}
              />
            </div>
            <div className="px-2 item">
            <AutocompleteComponent
                key={`city`}
                selectedValues={state.country}
                idRef={`cityRef`}
                multiple={false}
                inputPlaceholder={t("city")}
                data={items &&  items.cities}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.cities}
                withLoader
                withoutSearchButton
                labelValue={"city"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.cities?.length === 0 )
                 getLookupItemsByName('city' , 'cities');
                }}
              />
            </div>
            <div className="px-2 item">
            <AutocompleteComponent
                key={`district`}
                selectedValues={state.district}
                idRef={`districtRef`}
                multiple={false}
                inputPlaceholder={t("district")}
                data={items &&  items.districts}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.districts}
                withLoader
                withoutSearchButton
                labelValue={"district"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.districts?.length === 0 )
                 getLookupItemsByName('district' , 'districts');
                }}
              />
            </div>
            <div className="px-2 item">
            <AutocompleteComponent
                key={`community`}
                selectedValues={state.community}
                idRef={`communityRef`}
                multiple={false}
                inputPlaceholder={t("community")}
                data={items &&  items.communities}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.communities}
                withLoader
                withoutSearchButton
                labelValue={"community"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.communities?.length === 0 )
                 getLookupItemsByName('community' , 'communities');
                }}
              />
            </div>
            <div className="px-2 item">
            <AutocompleteComponent
                key={`SubCommunity`}
                selectedValues={state.subCommunity}
                idRef={`subCommunityRef`}
                multiple={false}
                inputPlaceholder={t("select-sub-community")}
                data={items &&  items.subCommunities}
                displayLabel={(option) => (option && option.lookupItemName) || ""}
                onChange={(e, v) => {}}
                isLoading={isLoading.subCommunities}
                withLoader
                withoutSearchButton
                labelValue={"subCommunity"}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                onOpen={() => {
                  if(items?.subCommunities?.length === 0 )
                 getLookupItemsByName('subcommunity' , 'subCommunities');
                }}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"StreetRef"}
                labelValue={"street"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"BuildingNameRef"}
                labelValue={"building-name"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"BuildingNumberRef"}
                labelValue={"building-number"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"FloorNumberRef"}
                labelValue={"floor-number"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"OfficeNumberRef"}
                labelValue={"office-number"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"MapCoordinatesRef"}
                labelValue={"map-coordinates"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"Postal-ZIPCodeRef"}
                labelValue={"postal-zip-code"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="px-2 item">
            <Inputs
                isAttachedInput
                idRef={"PO BoxRef"}
                labelValue={"po-box"}
                type={"text"}
                onInputChanged={(e) => {}}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className="mt-3 addBtn">
            <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              //saveAgreementDetails() 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}add-branch-location`)}
          </ButtonBase>
          <ButtonBase
            className='btns theme-solid'
            onClick={() => 
            {
              setIsOpenEditDialog(true) ; 
            }}
          >
            <span className='mdi mdi-plus' />
            {t(`${translationPath}edit-branch-location`)}
          </ButtonBase>
          </div>
          </div>
        </div>
      </div>
      <BranchesLocationTable 
         translationPath={translationPath}
         parentTranslationPath={parentTranslationPath}
      />
      
        {
           isOpenEditDialog && (
             <BranchLocationManagementDialog
             translationPath={translationPath}
             parentTranslationPath={parentTranslationPath}
            isOpen ={isOpenEditDialog}
            onClose={()=> setIsOpenEditDialog(false)}
            onSave={()=> setIsOpenEditDialog(false)}
             />
          )
        
         }
    </div>
  );
};
