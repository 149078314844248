import React, { Children, useEffect, useRef, useState } from 'react';
import {
  GetAllRoles,
  GetAllSystemTemplateByCategoryId,
} from '../../../../../../Services';
import {
  AutocompleteComponent,
  CheckboxesComponent,
} from '../../../../../../Components';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import {
  RecipientTypeCoreEnum,
  ReminderTypeEnum,
  TemplateCategoryEnum,
} from '../../../../../../Enums';
import { useTranslation } from 'react-i18next';
import { WithFieldset } from './WithFieldset';

export const ActivityNotifications = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const recipientTypes = Object.values(RecipientTypeCoreEnum);
  const [notifications, setNotifications] = useState(
    state.activityTypeTemplateRecipientTypes ?? [
      {
        templateId: null,
        recipientTypeId: null,
        activityTypeTemplateRecipientTypeSources: [],
      },
    ]
  );
  const [loadings, setLoadings] = useState(false);

  const [templates, setTemplates] = useState([]);

  const [selected, setSelected] = useState({
    templates: {},
    recipientType: [],
  });
  const getAllSystemTemplateByCategoryId = async () => {
    setLoadings(true);
    const res = await GetAllSystemTemplateByCategoryId(
      TemplateCategoryEnum.Activity.key
    );
    if (!(res?.status && res.status !== 200)) {
      const mappedTemplatesData = res.map((item) => ({
        templateName: item.systemTemplateName,
        templateId: item.systemTemplateId,
      }));
      setTemplates(mappedTemplatesData);
    }
    setLoadings(false);
  };
  useEffect(() => {
    getAllSystemTemplateByCategoryId();
  }, []);

  const handleChange = (type, notification) => () => {
    if (
      notification.activityTypeTemplateRecipientTypeSources?.find(
        (source) => source.notifyBy === type || source.notifyById === type
      )
    ) {
      const updated =
        notification.activityTypeTemplateRecipientTypeSources.filter(
          (item) => ![item.notifyBy, item.notifyById].includes(type)
        );
      notification.activityTypeTemplateRecipientTypeSources = updated;
    } else {
      notification.activityTypeTemplateRecipientTypeSources =
        notification.activityTypeTemplateRecipientTypeSources.concat({
          notifyBy: type,
        });
    }
    setNotifications((prevNotifications) => {
      return prevNotifications.map((not) => {
        if (notification.recipientTypeId === not.recipientTypeId) {
          return notification;
        }
        return not;
      });
    });
  };

  useEffect(() => {
    setState({
      id: 'activityTypeTemplateRecipientTypes',
      value: notifications,
    });
  }, [notifications]);
  useEffect(() => {
    if (state.activityTypeTemplateRecipientTypes?.length > 0) {
      setSelected((currentSelected) => ({
        ...currentSelected,
        recipientType: state.activityTypeTemplateRecipientTypes.map(
          (notification) =>
            recipientTypes.find(
              (recipientType) =>
                recipientType.key === notification.recipientTypeId
            )
        ),
      }));
    }
  }, []);
  return (
    <div className="my-3">
      <CheckboxesComponent
        idRef="IsImportantRef"
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        label="Important Notification"
        singleChecked={state.isImportant}
        wrapperClasses={"mb-3"}
        themeClass="theme-secondary"
        onSelectedCheckboxClicked={() => {
          setState({
            id: "isImportant",
            value: !state.isImportant,
          });
        }}
      />
      <AutocompleteComponent
        idRef="activityTypeTemplateRecipientTypesRef"
        data={recipientTypes}
        displayLabel={(option) => option?.value ?? ""}
        chipsLabel={(option) => option?.value ?? ""}
        selectedValues={selected.recipientType ?? []}
        withoutSearchButton
        wrapperClasses="mb-3"
        labelValue="recipient-type"
        inputPlaceholder="recipient-type"
        onChange={(_event, newValue) => {
          setNotifications((currentNotifications) => {
            const newNotifications = newValue?.map((item) => {
              return (
                currentNotifications.find(
                  (value) => value.recipientTypeId === item.key
                ) ?? {
                  templateId: null,
                  recipientTypeId: item.key,
                  activityTypeTemplateRecipientTypeSources: [],
                }
              );
            });
            return newNotifications;
          });
          setSelected((current) => ({
            ...current,
            recipientType: newValue,
          }));
        }}
        getOptionSelected={(option) =>
          selected.recipientType.find(
            (recipient) => recipient.value === option.value
          )
        }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      {notifications?.map((notification) =>
        Children.toArray(
          <WithFieldset
            legend={
              recipientTypes.find(
                (recipient) => recipient.key === notification.recipientTypeId
              )?.value
            }
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          >
            <AutocompleteComponent
              idRef="Notification-template-Created-from-AdminRef"
              selectedValues={
                templates.find(
                  (template) =>
                    template?.templateId === notification?.templateId
                ) ?? {}
              }
              multiple={false}
              data={templates ?? []}
              displayLabel={(option) => t(`${option.templateName || ""}`)}
              getOptionSelected={(option) =>
                option.templateId === notification.templateId
              }
              wrapperClasses="my-1"
              withoutSearchButton
              inputPlaceholder={t(`${translationPath}notification-template`)}
              labelValue={t(`${translationPath}notification-template`)}
              isLoading={loadings}
              isWithError
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(event, newValue) => {
                setNotifications((currentNotifications) => {
                  const newNotifications = [...currentNotifications].map(
                    (item) => {
                      if (
                        notification?.recipientTypeId === item?.recipientTypeId
                      ) {
                        return {
                          ...item,
                          templateId: newValue?.templateId || null,
                        };
                      } else return item;
                    }
                  );
                  return newNotifications;
                });
              }}
            />
            <FormGroup row className="my-2">
              <label className="label-wrapper">created from</label>
              {Children.toArray(
                Object.values(ReminderTypeEnum).map((item) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          notification.activityTypeTemplateRecipientTypeSources?.findIndex(
                            (source) =>
                              source.notifyBy === item.key ||
                              source.notifyById === item.key
                          ) !== -1
                        }
                        onChange={handleChange(item.key, notification)}
                        name="created-from"
                      />
                    }
                    label={item.value}
                  />
                ))
              )}
            </FormGroup>
          </WithFieldset>
        )
      )}
    </div>
  );
};
