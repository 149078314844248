import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { Spinner, Tables } from '../../../../../Components';
import { GetParams } from '../../../../../Helper';
import { GetAllContactUnitTransactions } from '../../../../../Services';

export const DeveloperProfileLeadsOverviewComponent = ({ 
  id,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [contactId, setContactId] = useState(+GetParams('id'));
  const [leadsOverview, setLeadsOverview] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
  });
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const transactionLinksActionClicked = (item , key) => 
  {
    if(key === 'lead' && item.leadClass === 'Owner' )
      window.open(`/home/leads/lead-profile-edit?formType=${1}&id=${item.leadId}`, '_blank').focus(); 
   else if(key === 'contact'  && item.contactType)
      window.open(`home/Contacts-CRM/contact-profile-edit?formType=${item.contactType}&id=${item.contactId}`, '_blank').focus();

    else if(key === 'lead' && item.leadClass === 'Seeker' )
      window.open(`/home/leads/lead-profile-edit?formType=${2}&id=${item.leadId}`, '_blank').focus(); 
   else  if(key === 'unit' && item.operationType === 'Sale')
      window.open(`/home/units-sales/unit-profile-edit?formType=${1}&id=${item.unitId}&operationType=${item.operationType}`, '_blank').focus();
    else if(key === 'unit' && item.operationType === 'Lease')
      window.open(`/home/units-sales/unit-profile-edit?formType=${1}&id=${item.unitId}&operationType=${item.operationType}`, '_blank').focus();
    else if(key === 'unit' && item.operationType === 'SaleAndRent')
      window.open(`/home/units-lease/unit-profile-edit?formType=${1}&id=${item.unitId}&operationType=${item.operationType}`, '_blank').focus();
    else if(key === 'property')
      window.open(`/home/Properties-CRM/property-profile-edit?formType=${1}&id=${item.propertyId}`, '_blank').focus();
   
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  const getAllContactUnitTransactions = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllContactUnitTransactions(filter, contactId);
    if (!(res && res.status && res.status !== 200)) {
      setLeadsOverview({
        result: (res && res.result) || [],
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setLeadsOverview({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, [filter, contactId]);

  useEffect(() => {
    if (contactId) getAllContactUnitTransactions();
  }, [getAllContactUnitTransactions, contactId]);

  useEffect(() => {
    setContactId(+GetParams('id'));
  }, [location]);
  return (
    <div className='childs-wrapper p-relative mt-3 px-2'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section'>
        <span>{t(`${translationPath}leads-overview`)}</span>
      </div>
      <div className='w-100 px-2'>
        <div className='filter-sections-wrapper' />
        <Tables
          data={leadsOverview.result}
          headerData={[
            { id: 1, label: 'lead-id', input: 'leadId'  , 
              component: (item) => (
                <a className='c-primary newTabActivites' onClick={transactionLinksActionClicked(item, 'lead')}>
                  {(item.leadName) || 'N/A'}
                </a>
              ),


            },
            {
              id: 3,
              label: 'interested-property',
              input: 'interested-property',
              component: (item) => (
                <a className='c-primary newTabActivites' onClick={transactionLinksActionClicked(item, 'property')}>
                  {(item.interested_property) || 'N/A'}
                </a>
              ),
            },
            {
              id: 4,
              label: 'interested-developer',
              input: 'interested_developer',
              
            },
            {
              id: 5,
              label: 'lead-stage',
              input: 'lead_stage',
             
            },
         
            {
              id: 6,
              isSortable: true,
              label: 'lead-status',
              input: 'lead_status',
              component: (item, index) => (
                (item.lead_status && <div className='globel-open-style' index={index}>{t(`${translationPath}open`)}</div>) || <div className='globel-closed-style' index={index}>{t(`${translationPath}closed`)}</div>
              ),
            },
            {
              id:7,
              label:'assigned-agent',
              input:'assigned_agent' ,
            } ,
            {
              id: 8,
              isSortable: true,
              label: 'transaction-value',
              input: 'transaction_value',
            
            },
            {
              id: 8,
              isSortable: true,
              label: 'project-name',
              input: 'project_name',
              component: (item) => (
                <a className='c-primary newTabActivites' onClick={transactionLinksActionClicked(item, 'property')}>
                  {(item.project_name) || 'N/A'}
                </a>
              ),
            },
            {
              id: 8,
              isSortable: true,
              label: 'unit-type',
              input: 'unit_type',
            },
            {
              id: 8,
              isSortable: true,
              label: 'unitRef',
              input: 'unitRef',
              component: (item) => (
                <a className='c-primary newTabActivites' onClick={transactionLinksActionClicked(item, 'unit')}>
                  {(item.unitId) || 'N/A'}
                </a>
              ),
            },

          ]}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          dateFormat='DD, MMM, YYYY'
          defaultActions={[]}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          totalItems={leadsOverview.totalCount}
        />
      </div>
    </div>
  );
};

DeveloperProfileLeadsOverviewComponent.propTypes = {
  id: PropTypes.number.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
