import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import GoogleMapReact from 'google-map-react';
import { PropTypes } from 'prop-types';
import { Inputs } from '../Controls/Inputs/Inputs';
import { GoogleMapsStyles } from './Styles/GoogleMapsStyles';
import { RadiosGroupComponent } from '../Controls';
import { GlobalTranslate, showError } from '../../Helper';
import { validateCoordinatesRegex } from '../../Utils';
import { config } from '../../config';
import { getCurrentLocation } from '../../Rule';
import { ButtonBase, Button, Badge } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CopyToClipboardComponents } from '../../ReusableComponents/UtilityComponents';

const MapPoint = () => (
  <div className='map-marker'>
    <span className='mdi mdi-map-marker c-danger' />
  </div>
);

const PropertyPin = ({
  name,
  id,
  propertyTypeId,
  createdOn,
  propertyStatus,
  index,
  onPinClick,
  noOfUnits,
  forUnits,
}) => {
  const parentTranslationPath = 'PropertiesView';
  const translationPath = '';
  const [visibleBadge, setVisibleBadge] = useState(false);
  const { t, i18n } = useTranslation(parentTranslationPath);

  return (
    <div
      onMouseEnter={() => setVisibleBadge(true)}
      onMouseLeave={() => setVisibleBadge(false)}
    >
      <div className='badge-container'>
        {visibleBadge && (
          <div className='badge-content'>
            <div className='body-item-flex w-100 px-2'>
              <div className='body-title-wrapper'>
                <span className='body-title'>{name}</span>
              </div>
              <div>
                <span className='details-text'>
                  {t(`${translationPath}ID`)}:
                </span>
                <CopyToClipboardComponents
                  data={id}
                  childrenData={id}
                  CustomizationClassName='id'
                />
              </div>
            </div>
            <div className='body-item-flex w-100'>
              <div className='created-on px-2'>
                <span className='details-icon mdi mdi-calendar mdi-16px' />
                <span>
                  <span className='details-text'>
                    {t(`${translationPath}created`)}:
                  </span>
                  <span className='px-1'>
                    {(createdOn &&
                      moment(createdOn)
                        .locale(i18n.language)
                        .format('DD/MM/YYYY')) ||
                      'N/A'}
                  </span>
                </span>
              </div>
              <div>
                <span
                  className={`card-tooltip card-badge ${propertyStatus?.class}`}
                >
                  <span>
                    {propertyStatus.value
                      ? t(propertyStatus?.value)
                      : t(propertyStatus)}
                  </span>
                </span>
              </div>
            </div>
            <div className=' d-flex fj-center'>
              <p>
                Number of Units: <strong>{noOfUnits}</strong>
              </p>
            </div>
            {propertyTypeId && (
              <div className='cards-footer-wrapper'>
                <ButtonBase
                  className={`btns theme-transparent mx-0 w-100 `}
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `/home/Properties-CRM/property-profile-edit?formType=${propertyTypeId}&id=${id}`
                    );
                  }}
                  id='ActionsClickedfolder'
                >
                  <span className='mdi mdi-folder-outline' />
                  <span className='px-1'>{t('open-file')}</span>
                </ButtonBase>
              </div>
            )}
          </div>
        )}
        <div onClick={onPinClick({ id, name }, index)}>
          {forUnits ? (
            <Badge color='primary' max={9999} badgeContent={noOfUnits}>
              <span />
            </Badge>
          ) : (
            <LocationOn fontSize='large' color='secondary' />
          )}
        </div>
      </div>
    </div>
  );
};

export const GoogleMapsComponent = ({
  defaultCenter,
  hoverDistance,
  defaultZoom,
  onClick,
  location: _location,
  onChange,
  center,
  locations,
  searchLocationValue,
  hideLatitudeLongitude,
  setState,
  propertyView,
  onScaleChange,
  searchPlace,
  onPinClick,
  reCenterOption,
  loadingIndicator,
  loadingOnContent,
  loadingOffContent,
  isLoading,
  stopChangeCapturing,
  forUnits
}) => {
  const [google, setGoogle] = useState(null);
  const [StyleMode, setStyleMode] = useState(null);
  const [viewType, setViewType] = useState(1);
  const [searchLocation, setSearchLocation] = useState(null);
  const [premadeChildren] = useState(null);
  const [currentLocations, setCurrentLocations] = useState(locations);
  const [SearchLocations, setSearchLocations] = useState('');
  const [isReCentering, setIsReCentering] = useState(false)
  const searchTimer = useRef(null);
  const [currentCenter, setCenter] = useState(
    defaultCenter ?? { lat: 24.414833592365972, lng: 54.59777364239554 }
  );
  const [location, setLocation] = useState(_location);
  const onClickHandler = (clickValue) => {
    if (propertyView) return;
    setLocation(`${clickValue.lat}, ${clickValue.lng}`);
    if (searchLocation) setSearchLocation(null);
    if (onClick) onClick(clickValue);
    if (setState && clickValue) {
      setState({
        latitude: clickValue.lat,
        longitude: clickValue.lng,
      });
    }
  };
  useEffect(() => {
    setSearchLocation(searchLocationValue);
  }, [searchLocationValue]);
  useEffect(() => {
    if (center && premadeChildren) premadeChildren.setCenter(center);
  }, [center, premadeChildren]);

  const handleMap = (map, maps) => {
    setGoogle({ map, maps });
    if (currentLocations.length > 0) {
      const bounds = new maps.LatLngBounds();
      currentLocations.forEach((location) => {
        bounds.extend(new maps.LatLng(location.latitude, location.longitude));
      });
      map.fitBounds(bounds);
      map.setZoom(map.getZoom() - 5);
    }
  };

  const reCenter = () => {
    setIsReCentering(true)
    const bounds = new google.maps.LatLngBounds();
    currentLocations.forEach((location) => {
      bounds.extend(
        new google.maps.LatLng(location.latitude, location.longitude)
      );
    });
    google.map.fitBounds(bounds);
    google.map.setZoom(google.map.getZoom());
  };

  const onViewTypeChangedHandler = (event, newValue) => {
    setViewType(+newValue);
    localStorage.setItem('GoogleMapsViewType', JSON.stringify(+newValue));
  };
  useEffect(() => {
    if (location) {
      const [lat, lng] = location.split(',');
      const newCenter = { lat: parseFloat(lat), lng: parseFloat(lng) };
      setCenter(newCenter);
      setCurrentLocations([
        { latitude: parseFloat(lat), longitude: parseFloat(lng) },
      ]);
    }
  }, [location]);
  useEffect(() => {
    const data = localStorage.getItem('GoogleMapsViewType');
    if (data === null) {
      localStorage.setItem('GoogleMapsViewType', JSON.stringify(viewType));
      setStyleMode(GoogleMapsStyles.default);
    } else setViewType(+data);
    switch (+viewType) {
      case 1:
        setStyleMode(GoogleMapsStyles.default);
        break;
      case 2:
        setStyleMode(GoogleMapsStyles.night);
        break;
      case 3:
        setStyleMode(GoogleMapsStyles.retro);
        break;
      case 4:
        setStyleMode(GoogleMapsStyles.silver);
        break;
      case 5:
        setStyleMode(GoogleMapsStyles.hiding);
        break;
      case 6:
        setStyleMode(GoogleMapsStyles.graa);
        break;
      case 7:
        setStyleMode(GoogleMapsStyles.Hopper);
        break;
      default:
        setStyleMode(GoogleMapsStyles.default);
    }
  }, [onViewTypeChangedHandler]);

  const mapOptions = {
    scrollwheel: true,
    zoomControlOptions: {
      //   position: 'RIGHT_CENTER',    // as long as this is not set it works
      style: 'SMALL',
    },
    mapTypeControlOptions: {
      mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'map'],
      // position: "BOTTOM_RIGHT", // this makes the map type control disappear
    },
    types: ['hospital', 'health'],
    draggable: true,
    panControl: true,
    zoomControl: true,
    mapTypeControl: true,
    rotateControl: true,
    scaleControl: true,
    styles: StyleMode,
    streetViewControl: true,
    libraries: ['places', 'geometry', 'drawing', 'visualization'],
    overviewMapControl: true,
  };
  function validateCoordinates(coordinates) {
    return validateCoordinatesRegex.test(coordinates);
  }

  useEffect(() => {
    setCurrentLocations(locations);
  }, [locations]);

  const getCurrentLocationAPI = useCallback(async () => {
    const res = await getCurrentLocation(propertyView);
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setCurrentLocations([res]);
      setLocation(`${res.latitude}, ${res.longitude}`);
      if (onClick) {
        onClick({
          lat: res?.latitude,
          lng: res?.longitude,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (google && currentLocations.length > 0 && !propertyView) {
      const bounds = new google.maps.LatLngBounds();
      currentLocations.forEach((location) => {
        bounds.extend(
          new google.maps.LatLng(location.latitude, location.longitude)
        );
      });
      google.map.fitBounds(bounds);
      google.map.setZoom(google.map.getZoom() - 5);
    } else if (google && currentLocations.length > 0 && propertyView && stopChangeCapturing) {
      reCenter();
    }
  }, [google, currentLocations]);

  const searchHandler = () => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getCurrentLocationAPI();
    }, 700);
  };

  const changeHandler = ({ center, zoom }) => {
    if (stopChangeCapturing) {
      return;
    }
    if (propertyView && isReCentering) {
      return setIsReCentering(false);
    }
    const calculateZoomScale = (zoom) => {
      return Math.pow(1.09, 1 / Math.pow(2, zoom - 13));
    };
    const newCenter = {
      lat: center.lat.toString(),
      long: center.lng.toString(),
      zoomScale: calculateZoomScale(zoom).toString(),
    };
    if (onScaleChange) {
      onScaleChange(newCenter);
    }
  };
  useEffect(() => {
    if (!!searchPlace) {
      localStorage.setItem('CurrentAddress', searchPlace);
      setSearchLocations(searchPlace);
      searchHandler();
    } else {
      localStorage.removeItem('CurrentAddress');
      setSearchLocations(searchPlace ?? '');
    }
  }, [searchPlace]);

  return (
    <div className='google-maps-wrapper'>
      {!hideLatitudeLongitude && (
        <div className='Manual-Location'>
          <Inputs
            labelValue={GlobalTranslate.t('Shared:latitude-longitude')}
            inputPlaceholder='location'
            value={location}
            getOptionLabel={(option) => option.formatted_address || ''}
            // getOptionSelected={(option) => option.id === state.agentId}
            multiple={false}
            withoutSearchButton
            isDisabled
            onInputChanged={onChange}
            startAdornment={
              <>
                <Button
                  title={GlobalTranslate.t('Shared:paste-copied-coordinates')}
                  onClick={async () => {
                    try {
                      const text = (
                        await navigator.clipboard.readText()
                      ).replaceAll(' ', '');
                      if (validateCoordinates(text)) {
                        setLocation(text);
                        const [latitude, longitude] = text.split(',');
                        setCurrentLocations([{ latitude, longitude }]);
                        setCenter({ lat: +latitude, lng: +longitude });
                        onClickHandler({ lat: +latitude, lng: +longitude });
                      } else {
                        showError(
                          GlobalTranslate.t('Shared:coordinates-are-incorrect')
                        );
                      }
                    } catch (error) {
                      showError(
                        GlobalTranslate.t('Shared:access-clipboard-error')
                      );
                    }
                  }}
                >
                  <span className='p-1 youtube-wraper'>
                    <span className='mdi mdi-content-paste' />
                  </span>
                </Button>
              </>
            }
            endAdornment={
              <>
                <Button
                  title={GlobalTranslate.t('Shared:open-google-map-w')}
                  onClick={() => {
                    try {
                      window.open(
                        `https://www.google.com/maps/search/?api=1&query=${location}`
                      );
                    } catch (error) {
                      showError(
                        GlobalTranslate.t('Shared:this-link-is-not-valid')
                      );
                    }
                  }}
                >
                  <span className='p-1 youtube-wraper'>
                    <span className='mdi mdi-map-legend' />
                  </span>
                </Button>
              </>
            }
          />
        </div>
      )}

      <Inputs
        value={SearchLocations}
        onKeyUp={searchHandler}
        idRef='aCurrentAddresshRef'
        onInputChanged={(e) => {
          setSearchLocations(e.target.value);
          localStorage.setItem('CurrentAddress', `${e.target.value}`);
        }}
        inputPlaceholder={GlobalTranslate.t('Shared:SearchLocations')}
        beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
      />

      <div>
        <span>{GlobalTranslate.t('Shared:view-style')}</span>
        <div className='d-flex '>
          <RadiosGroupComponent
            idRef='viewRef'
            data={[
              {
                key: 1,
                value: GlobalTranslate.t('Shared:default'),
              },
              {
                key: 2,
                value: GlobalTranslate.t('Shared:night'),
              },
              {
                key: 3,
                value: GlobalTranslate.t('Shared:retro'),
              },
              {
                key: 4,
                value: GlobalTranslate.t('Shared:silver'),
              },
              {
                key: 5,
                value: GlobalTranslate.t('Shared:hiding'),
              },
              {
                key: 6,
                value: GlobalTranslate.t('Shared:graa'),
              },
              {
                key: 7,
                value: GlobalTranslate.t('Shared:Hopper'),
              },
            ]}
            wrapperClasses={'w-100'}
            value={viewType}
            labelInput='value'
            valueInput='key'
            themeClass='theme-line'
            onSelectedRadioChanged={(e, newValue) =>
              onViewTypeChangedHandler(e, newValue)
            }
          />
          {loadingIndicator &&
            (isLoading ? loadingOnContent : loadingOffContent)}
          {reCenterOption && (
            <ButtonBase
              className='btns theme-solid mb-1'
              onClick={reCenter}
              disabled={currentLocations?.length === 0}
            >
              {GlobalTranslate.t('Shared:re-center')}
            </ButtonBase>
          )}
        </div>
      </div>
      <div className='google-maps'>
        {currentLocations && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: config.GoogleMapApiKey }}
            hoverDistance={hoverDistance}
            // defaultCenter={{
            //   lat: +defaultCenter.lat,
            //   lng: +defaultCenter.lng,
            // }}
            defaultZoom={defaultZoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleMap(map, maps)}
            onClick={onClickHandler}
            options={mapOptions}
            center={currentCenter}
            onChange={changeHandler}
          >
            {Children.toArray(
              currentLocations?.map((item, index) =>
                propertyView ? (
                  <PropertyPin
                    lat={item.latitude}
                    lng={item.longitude}
                    onPinClick={onPinClick}
                    {...item}
                    forUnits={forUnits}
                  />
                ) : (
                  <MapPoint
                    lat={item.latitude}
                    lng={item.longitude}
                    text={item.name}
                    key={index}
                  />
                )
              )
            )}
          </GoogleMapReact>
        )}
      </div>
    </div>
  );
};
GoogleMapsComponent.propTypes = {
  location: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      text: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.instanceOf(Object)), // for popover content
      component: PropTypes.oneOfType([
        PropTypes.elementType,
        PropTypes.func,
        PropTypes.node,
      ]),
    })
  ).isRequired,
  searchLocationValue: PropTypes.instanceOf(Object),
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  hoverDistance: PropTypes.number,
  defaultZoom: PropTypes.number,
  hideLatitudeLongitude: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};
GoogleMapsComponent.defaultProps = {
  defaultCenter: { lat: 24.414833592365972, lng: 54.59777364239554 },
  hoverDistance: 30,
  defaultZoom: 10,
  onClick: undefined,
  onChange: undefined,
  hideLatitudeLongitude: false,
  center: undefined,
  searchLocationValue: null,
};
