import React from "react";
import { CustomCard } from "../../../../../../Components";

// Styles
import useStyles from "./styles";

function ContactDetails() {
  const styles = useStyles();

  return (
    <CustomCard
      borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
    >
      <div>Contact Details Component</div>
    </CustomCard>
  );
}

export default ContactDetails;
