import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  autoCompleteWrapper: {
    "& .MuiAutocomplete-endAdornment": {
      top: "25%",
    },

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.primary,
    },

    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.primary,
    },
  },
  withDefaultPadding: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "10px 14px !important",
    },
  },
  noPadding: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "0 !important",
    },
  },
  placeholder: {
    cursor: "pointer",
    "&::placeholder": {
      color: theme.palette.text.placeholder,
    },
  },
  selected: {
    fontWeight: 500,
  },

  dropdownIcon: {
    cursor: "pointer",
    color: theme.palette.foreground.quarterary,
    fontSize: "20px",
  },

  inputIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.foreground.quarterary,
  },

  iconVariant: {
    "& .MuiOutlinedInput-root": {
      paddingLeft: theme.spacing(3),
    },
  },
  noBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  option: {
    padding: "0 16px",
  },
  listBox: { display: "flex", gap: "10px", flexDirection: "column" },

  paper: {
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.secondary}`,
    background: theme.palette.background.paper,
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
  },
}));
