import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import {
  ConvertJsonV2Component,
  Spinner,
  TabsComponent,
  PermissionsComponent,
} from '../../../../../../Components';
import {
  leadDetailsPut,
  GetAllFormFieldTabsByFormId,
  GetLeadAuditorByUserIdService,
} from '../../../../../../Services';
import {
  bottomBoxComponentUpdate,
  FormErrorsHandler,
  formItemsBuilder,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import { LookupsRules } from '../../../../../../Rule';
import { FormsIdsEnum, LeadsStatusEnum } from '../../../../../../Enums';
import { LeadInfo } from '../../../../LeadsView/LeadInfo/LeadInfo';
import {
  LeadsSalesPermissions,
  LeadsLeasePermissions,
  LeadsCAllCenterPermissions,
  ContactsPermissions,
  LeadOwnerLeadsPermissions,
} from '../../../../../../Permissions';

export const LeadInformationComponent = ({
  viewType,
  parentTranslationPath,
  translationPath,
  sensitiveFieldsForUser,
  leadWithPolicy,
  leadDataIsLoading,
  isSensitiveLoading,
  isLoading,
  leadEmptyFields,
  reloadData,
  setTargetedStageId,
}) => {
  const { t } = useTranslation(parentTranslationPath, 'Shared');
  const [activeItem, setActiveItem] = useState({
    id: null,
    leadTypeId: null,
  });

  const [isPropertyManagementView, setIsPropertyManagementView] =
    useState(false);
  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0];
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadings, setLoadings] = useState([]);
  const [formData, setFormData] = useState([]);
  const [leadOwner, setLeadOwner] = useState(null);
  const [enumsInitFinished, setEnumsInitFinished] = useState(false);
  const [errors, setErrors] = useState([]);
  const [leadInitDetails, setLeadInitDetails] = useState({});
  const [leadDetails, setLeadDetails] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [formAndTabs, setFormAndTabs] = useState([]);
  const [hiddenTabByIsMortgage, sethiddenTabByIsMortgage] = useState(null);
  const [selectPermission, setSelectPermission] = useState({
    permissionsList: [],
    permissionsId: '',
  });

  const onTabChanged = (e, newTap) => {
    setEnumsInitFinished(false);
    setActiveTab(newTap);
  };
  const getAllFormFieldTabsByFormId = useCallback(async () => {
    setIsLoadingForm(true);
    const result = await GetAllFormFieldTabsByFormId({
      formId:
        (activeItem.leadTypeId === 1 && FormsIdsEnum.leadsOwner.id) ||
        FormsIdsEnum.leadsSeeker.id,
    });
    if (!(result && result.status && result.status !== 200))
      setFormAndTabs(result[0] || []);
    else setFormAndTabs([]);
    setIsLoadingForm(false);
  }, [activeItem.leadTypeId]);

  const getLeadOwner = useCallback(
    async (referredto) => {
      setIsLoadingForm(true);
      const leadDetailsRes = await GetLeadAuditorByUserIdService(
        referredto && referredto.id
      );
      if (
        !(
          leadDetailsRes &&
          leadDetailsRes.status &&
          leadDetailsRes.status !== 200
        )
      )
        setLeadOwner(leadDetailsRes);
      else setLeadOwner(null);

      setIsLoadingForm(false);
    },
    [leadDetails.lead]
  );

  const dataHandler = useCallback(() => {
    if (formData.length === 0 && isSensitiveLoading)
      setFormData(
        formItemsBuilder(leadDetails.lead, formAndTabs, sensitiveFieldsForUser)
      );
  }, [
    leadDetails.lead,
    formAndTabs,
    formData.length,
    sensitiveFieldsForUser,
    isSensitiveLoading,
    activeItem
  ]);
  const cancelHandler = () => {
    localStorage.removeItem('leadStatus');
    GlobalHistory.goBack();
  };
  const saveHandler = async () => {
    localStorage.removeItem('leadStatus');
    setIsSubmitted(true);
    if (errors && errors.length > 0) {
      setEnumsInitFinished(false) ; 
      const firstErrorTapIndex = formData.findIndex(
        (item) =>
          item.findIndex((element) => element.field.id === errors[0].key) !== -1
      );
      if (firstErrorTapIndex !== -1)
        setActiveTab(
          (hiddenTabByIsMortgage !== null &&
            firstErrorTapIndex >= hiddenTabByIsMortgage[0] &&
            activeTab >= hiddenTabByIsMortgage[1] &&
            firstErrorTapIndex + 1) ||
            firstErrorTapIndex
        );
      showError(errors[0].message);
      return;
    }
    const totalItems = formData.reduce(
      (total, items) => total + items.length,
      0
    );
    const totalValues = Object.values(leadDetails.lead).filter(
      (item) =>
        item !== null &&
        item !== '' &&
        item !== undefined &&
        item !== ' ' &&
        !(Object.keys(item).length === 0 && item.constructor === Object)
    ).length;
    const total = ((totalValues - 3) / totalItems) * 100;
    const dataCompleted = Math.round(total);
    leadDetails.lead.data_completed = dataCompleted > 100 ? 100 : dataCompleted;
    setIsLoadingForm(true);
    const isForAutoRotation =
      leadDetails.lead &&
      leadDetails.lead &&
      leadDetails.lead['send_to_rotation'] === 'Yes'
        ? true
        : false;

    const putResponse = await leadDetailsPut({
      id: activeItem.id,
      body: {
        leadJson: leadDetails,
        isForAutoRotation: isForAutoRotation,
      },
    });
    if (!(putResponse && putResponse.status && putResponse.status !== 200)) {
      showSuccess(t(`${translationPath}lead-updated-successfully`));
      setIsLoadingForm(false);
      if (reloadData) reloadData();
    } else {
      setIsLoadingForm(false);
      const errorMessage = putResponse.data.Message.split(':')[1].trim();
      showError(t(`${translationPath}${errorMessage || 'lead-update-failed'}`));
    }
  };
  const onLoadingsChanged = (value, key) => {
    setLoadings((items) => {
      const itemIndex = items.findIndex((item) => item.key === key);
      if (value) {
        const addItem = {
          key,
          value,
        };
        if (itemIndex !== -1) items[itemIndex] = addItem;
        else items.push(addItem);
      } else if (itemIndex !== -1) items.splice(itemIndex, 1);
      return [...items];
    });
  };

  const onItemChanged =
    (item, index) => (newValue, itemIndex, itemKey, parentItemKey) => {
      setFormData((elements) => {
        if (parentItemKey) {
          if (itemIndex !== undefined) {
            elements[
              (hiddenTabByIsMortgage !== null &&
                activeTab >= hiddenTabByIsMortgage[0] &&
                activeTab >= hiddenTabByIsMortgage[1] &&
                activeTab + 1) ||
                activeTab
            ][itemIndex][parentItemKey][itemKey] = newValue;
          } else {
            elements[
              (hiddenTabByIsMortgage !== null &&
                activeTab >= hiddenTabByIsMortgage &&
                activeTab + 1) ||
                activeTab
            ][index][parentItemKey][itemKey] = newValue;
          }
        } else if (itemIndex) {
          elements[
            (hiddenTabByIsMortgage !== null &&
              activeTab >= hiddenTabByIsMortgage &&
              activeTab + 1) ||
              activeTab
          ][itemIndex][itemKey] = newValue;
        } else {
          elements[
            (hiddenTabByIsMortgage !== null &&
              activeTab >= hiddenTabByIsMortgage[0] &&
              activeTab >= hiddenTabByIsMortgage[1] &&
              activeTab + 1) ||
              activeTab
          ][index][itemKey] = newValue;
        }
        return [...elements];
      });
    };

  const onValueChanged = (item) => (newValue, itemIndex, itemKey) => {
    if (item.field.id === 'lead_stage') {
      setTargetedStageId(newValue?.lookupItemId);
      return;
    }

    setLeadDetails((items) => {
      if (itemKey) items.lead[itemKey] = newValue;
      else if ((itemIndex || itemIndex === 0) && itemIndex !== -1) {
        items.lead[
          formData[
            (hiddenTabByIsMortgage !== null &&
              activeTab >= hiddenTabByIsMortgage[0] &&
              activeTab >= hiddenTabByIsMortgage[1] &&
              activeTab + 1) ||
              activeTab
          ][itemIndex].field.id
        ] = newValue;
      } else items.lead[item.field.id] = newValue;
      return { ...items };
    });
  };

  const getIsClosedLead = () => {
    const leadStatus = localStorage.getItem('leadStatus');
    if (leadStatus) {
      const leadStatusJson = JSON.parse(leadStatus);
      if (
        (leadStatusJson && leadStatusJson.lookupItemName === 'Closed') ||
        +LeadsStatusEnum.Closed.status === +leadStatusJson.lookupItemId
      )
        return true;
    }
    return false;
  };

  const lookupInit = useCallback(() => {
    setIsLoadingForm(true);
    const result = LookupsRules(
      formData[
        (hiddenTabByIsMortgage !== null &&
          activeTab >= hiddenTabByIsMortgage[0] &&
          activeTab >= hiddenTabByIsMortgage[1] &&
          activeTab + 1) ||
          activeTab
      ],
      leadDetails.lead,
      onLoadingsChanged,
      sensitiveFieldsForUser
    );
    setFormData((items) => {
      items.splice(
        (hiddenTabByIsMortgage !== null &&
          activeTab >= hiddenTabByIsMortgage[0] &&
          activeTab >= hiddenTabByIsMortgage[1] &&
          activeTab + 1) ||
          activeTab,
        1,
        result
      );
      return [...items];
    });
    setIsLoadingForm(false);
  }, [activeTab, leadDetails.lead, formData]);
  useEffect(() => {
    if (formData.length > 0 && leadDetails.lead)
      setErrors(FormErrorsHandler(formData, leadDetails.lead));
  }, [leadDetails, formData]);
  useEffect(() => {
    if (!enumsInitFinished && formData.length > 0) {
      setEnumsInitFinished(true);
      lookupInit();
    }
  }, [activeTab, enumsInitFinished, formData.length, lookupInit]);
  useEffect(() => {
    if (leadDetails.lead) dataHandler();
  }, [
    leadDetails.lead,
    dataHandler,
    sensitiveFieldsForUser,
    isSensitiveLoading,
  ]);

  useEffect(() => {
    if (activeItem && activeItem.leadTypeId) getAllFormFieldTabsByFormId();
  }, [activeItem, getAllFormFieldTabsByFormId, sensitiveFieldsForUser]);

  useEffect(() => {
    setActiveItem({
      id: GetParams('id'),
      leadTypeId: (GetParams('formType') && +GetParams('formType')) || 1,
    });
    setFormAndTabs([]) ;
    setFormData([]);
    setEnumsInitFinished(false);
    if (pathName === 'Leads-property-management/lead-profile-edit')
      setIsPropertyManagementView(true);
    else setIsPropertyManagementView(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetParams('id')]);

  useEffect(() => {
    if (
      (leadDetails.lead && leadDetails.lead.is_mortgage === 'No') ||
      (leadDetails.lead && !leadDetails.lead.is_mortgage)
    )
      sethiddenTabByIsMortgage([2, 3]);
    else sethiddenTabByIsMortgage(null);
  }, [leadDetails]);

  useEffect(() => {
    if (pathName.includes('sales')) {
      setSelectPermission({
        permissionsList: Object.values(LeadsSalesPermissions),
        permissionsId: LeadsSalesPermissions.EditLeadDetails.permissionsId,
      });
    } else if (pathName.includes('lease')) {
      setSelectPermission({
        permissionsList: Object.values(LeadsLeasePermissions),
        permissionsId: LeadsLeasePermissions.EditLeadDetails.permissionsId,
      });
    } else if (pathName.includes('Contacts-CRM'))
      setSelectPermission({
        permissionsList: Object.values(ContactsPermissions),
        permissionsId: ContactsPermissions.ViewLeads.permissionsId,
      });
    else if (pathName.includes('lead-owner-lead'))
      setSelectPermission({
        permissionsList: Object.values(LeadOwnerLeadsPermissions),
        permissionsId: LeadOwnerLeadsPermissions.ViewLeadDetails.permissionsId,
      });
    else
      setSelectPermission({
        permissionsList: Object.values(LeadsCAllCenterPermissions),
        permissionsId: LeadsCAllCenterPermissions.EditLeadDetails.permissionsId,
      });
  }, [pathName]);

  useEffect(() => {
    if (leadDetails && leadDetails.lead && leadDetails.lead.referredto)
      getLeadOwner(leadDetails.lead.referredto);
  }, [leadDetails.lead]);

  useEffect(() => {
    if (leadWithPolicy) {
      setLeadInitDetails(JSON.parse(JSON.stringify(leadWithPolicy)));
      setLeadDetails(leadWithPolicy);
    } else {
      setLeadInitDetails({});
      setLeadDetails({});
    }
  }, [leadWithPolicy]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-end flex-wrap'>
        <ButtonBase
          className='btns theme-transparent mb-2'
          onClick={cancelHandler}
        >
          <span> {t('Shared:cancel')}</span>
        </ButtonBase>

        <PermissionsComponent
          permissionsList={selectPermission && selectPermission.permissionsList}
          permissionsId={selectPermission && selectPermission.permissionsId}
        >
          <ButtonBase
            className='btns theme-solid mb-2'
            disabled={getIsClosedLead() || isLoading || isLoadingForm}
            onClick={saveHandler}
          >
            <span>{t('Shared:save')}</span>
          </ButtonBase>
        </PermissionsComponent>
      </div>
    );
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  return (
    <div className='lead-information-wrapper childs-wrapper b-0'>
      <Spinner isActive={isLoading || isLoadingForm} isAbsolute />
      <TabsComponent
        data={formAndTabs}
        labelInput='tab'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        themeClasses='theme-curved'
        currentTab={activeTab}
        onTabChanged={onTabChanged}
        hiddenTabIndexes={
          (hiddenTabByIsMortgage !== null && hiddenTabByIsMortgage) || []
        }
      />
      <div className='tabs-content-wrapper'>
        {formData &&
          // enumsInitFinished &&
          formData[activeTab] &&
          formData[
            (hiddenTabByIsMortgage !== null &&
              (activeTab >= hiddenTabByIsMortgage[0] ||
                activeTab >= hiddenTabByIsMortgage[1]) &&
              activeTab + 1) ||
              activeTab
          ]
            .filter(
              (item) =>
                viewType === 1 ||
                (viewType === 2 &&
                  ((leadEmptyFields &&
                    leadEmptyFields.lead &&
                    leadEmptyFields.lead[item.field.id] === null) ||
                    (leadEmptyFields &&
                      leadEmptyFields.lead &&
                      leadEmptyFields.lead[item.field.id] === undefined) ||
                    (leadEmptyFields &&
                      leadEmptyFields.lead &&
                      leadEmptyFields.lead[item.field.id] === '')))
            )
            .map((item, index) => (
              <ConvertJsonV2Component
                // key={`form${index + 1}-${activeTab}`}
                key={`form${index + 1}-${activeTab}-${item.field.id}-${activeItem.id}`}
                item={item}
                allItems={
                  formData[
                    (hiddenTabByIsMortgage !== null &&
                      (activeTab >= hiddenTabByIsMortgage[0] ||
                        activeTab >= hiddenTabByIsMortgage[1]) &&
                      activeTab + 1) ||
                      activeTab
                  ]
                }
                allItemsValues={leadDetails.lead}
                itemValue={
                  leadDetails.lead[item.field.id] === 0
                    ? '0'
                    : leadDetails.lead[item.field.id]
                }
                isSubmitted={isSubmitted}
                onItemChanged={onItemChanged(item, index)}
                onValueChanged={onValueChanged(item, index)}
                helperText={
                  (errors.find((element) => element.key === item.field.id) &&
                    errors.find((element) => element.key === item.field.id)
                      .message) ||
                  ''
                }
                error={
                  errors.findIndex(
                    (element) => element.key === item.field.id
                  ) !== -1
                }
                isLoading={
                  loadings.findIndex(
                    (element) => element.key === item.field.id && element.value
                  ) !== -1
                }
                onLoadingsChanged={onLoadingsChanged}
                sensitiveFieldsForUser={sensitiveFieldsForUser || []}
              />
            ))}
      </div>

      <LeadInfo
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        leadOwner={leadOwner}
      />
    </div>
  );
};

LeadInformationComponent.propTypes = {
  viewType: PropTypes.oneOf([1, 2]).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
