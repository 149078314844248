import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Rating from "@material-ui/lab/Rating";
import { AutocompleteComponent, Inputs } from "../../../../../../Components";
import { showError } from "../../../../../../Helper";
import { ReactComponent as ArrowNarrowRightIcon } from "../../../../../../assets/images/defaults/arrow-narrow-right.svg";
import PlusIcon from "../../../../../../assets/images/defaults/plus.svg";

export const CommissionDetailsValues = ({ parentTranslationPath, translationPath  , state }) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className="px-4 mt-2 w-75">
      <div className="key-nearby-section">
        <div>
          <span className="fw-bold">
            {t(`${translationPath}key-nearby-number`)}
          </span>
        </div>
        <div className="key-nearby-landmarks">
              {state &&
             state.commissions && 
               state.commissions.map(
                      (range, rangeIndex) => (
                        <div
                          className="d-flex-column w-100 mb-2"
                          key={`propertyRatingKeyNearbyRanges${
                            rangeIndex + "1993"
                          }`}
                        >
                          <div className="key-nearby-number w-100 ">
                            <div className="mb-2">
                              <Inputs
                                idRef={`From-${rangeIndex}`}
                                labelValue={t("From")}
                                value={range && range.from}
                                type={"number"}
                                min={1}
                                inputPlaceholde={t("Shared:From")}
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                onInputChanged={(event) => {
                                  const value = event.target.value;
                                    if (value <= 0) {
                                    showError(
                                      t(
                                        `${translationPath}please-enter-number-greater-than-zero`
                                      )
                                    );
                                    return;
                                  }
                                }}
                              />
                            </div>
                            <div className="arrow-part">
                              <ArrowNarrowRightIcon />
                            </div>
                            <div className="">
                              <Inputs
                                idRef={`To-${rangeIndex}`}
                                labelValue={t("To")}
                                inputPlaceholde={t("To")}
                                type={"number"}
                                min={1}
                                value={range && range.to}
                                parentTranslationPath={parentTranslationPath}
                                translationPath={translationPath}
                                onInputChanged={(event) => {
                                  const value = event.target.value;
                                  if (value <= 0) {
                                    showError(
                                      t(
                                        `${translationPath}please-enter-number-greater-than-zero`
                                      )
                                    );
                                    return;
                                  }
                                //   onChangeValues(
                                //     "to",
                                //     value,
                                //     index,
                                //     rangeIndex,
                                //     item
                                //   );
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex d-flex-v-center b-bottom">
                            <div className="px-2">
                              {" "}
                              <span className="">
                                {" "}
                                {t(`${translationPath}commission`)}{" "}
                              </span>{" "}
                            </div>
                            <div className="d-flex d-flex-v-center d-flex-h-between mb-2">
                            <Inputs
                              idRef={`Commission`}
                              labelClasses=""
                              key={`Commission`}
                              labelValue={"commission"}
                             onInputChanged={(e) => {}}
                             multiline
                             rows={3}
                             translationPath={translationPath}
                             parentTranslationPath={parentTranslationPath}
                             />
                              <span
                                className="mdi mdi-delete-outline c-warning delete-button"
                                onClick={() => {
                                //   onChangeValues(
                                //     "delete",
                                //     undefined,
                                //     index,
                                //     rangeIndex,
                                //     item
                                //   );
                                }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  <div
                    className="d-flex d-flex-v-center  mt-2 mb-2"
                    onClick={() => {
                    //   onChangeValues("add", undefined, index, undefined, item);
                    }}
                  >
                    <img
                      className="mr-2 ml-2"
                      src={PlusIcon}
                      alt={t(`${translationPath}permission-denied`)}
                    />
                    <span>{t(`${translationPath}add-another`)}</span>
                  </div>
             
        </div>
      </div>
    </div>
  );
};
CommissionDetailsValues.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
