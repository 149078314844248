import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import { Spinner, Tables, ViewTypes } from '../../../../../../Components';
import { GetAllAssociatedContact, GetAssociatedContactByContactId } from '../../../../../../Services';
import {
  ActionsEnum,
  ContactPreferenceEnum,
  ContactRelationshipEnum,
  ContactTypeEnum,
  TableActions,
  UserAccountTypeEnum,
  ViewTypesEnum,
} from '../../../../../../Enums';
import {
  GetParams, GlobalHistory, showError, WhatsAppMessage, bottomBoxComponentUpdate
} from '../../../../../../Helper';
// import { ContactsPermissions } from '../../../../../../Permissions';
import { AssociatedCardsComponent } from './Utilities/AssociatedCardsComponent';
import { ActiveItemActions } from '../../../../../../store/ActiveItem/ActiveItemActions';
import { ContactsActionDialogsComponent } from '../../../ContactsUtilities/ContactsActionDialogsComponent/ContactsActionDialogsComponent';
import { DeleteAssociatedDialog } from './Dialogs/DeleteAssociatedDialog';
import { AssociatedDiloag } from './Dialogs/AssociatedDiloag';

export const AssociatedContactsComponent = ({
  parentTranslationPath,
  translationPath,
  setActiveTab,
  activeTab
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const location = useLocation();
  // const loginResponse = useSelector((state) => state.login.loginResponse);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isOpenDeleteAssociatedDialog, setIsOpenDeleteAssociatedDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const defaultPrefernces = [
    {
      enum: TableActions.openFile.key,
    },
    {
      enum: TableActions.editText.key,
      // isDisabled: !loginResponse.permissions
      //   .map((item) => item.permissionsId === ContactsPermissions.UpdateContacts.permissionsId)
      //   .includes(true),
    },
    {
      enum: TableActions.deleteText.key,
    }
  ];
  const [sortBy, setSortBy] = useState(null);
  const [activePrefernces, setActiveprefernces] = useState(defaultPrefernces);
  const [activeActionType, setActiveActionType] = useState(ViewTypesEnum.cards.key);
  const [isOpenContactsActionDialog, setisOpenContactsActionDialog] = useState(false);
  const [isOpenAssciatedDialog, setIsOpenAssciatedDialog] = useState(false);
  const [detailedCardAction, setdetailedCardAction] = useState(() => ({
    actionEnum: '',
    item: '',
  }));
  const [associatedContacts, setAssociatedContacts] = useState([]);
  const [filter, setFilter] = useState({
    id: +GetParams('id'),
    pageSize: 25,
    pageIndex: 0,
    filterBy: 'RelatedContactName',
    orderBy: 1,
  });
  const getAccountType = (type) => {
    if (type === 'Normal') return UserAccountTypeEnum.normal.value;
    if (type === 'VIP') return UserAccountTypeEnum.platinum.value;
    if (type === 'VVIP') return UserAccountTypeEnum.gold.value;
    return null;
  };
  const getRelationshipValue = useCallback(
    (item) => {
      const valueIndex = Object.values(ContactRelationshipEnum).findIndex(
        (element) => item === element.key
      );
      if (valueIndex !== -1)
        return t(`${translationPath}${Object.values(ContactRelationshipEnum)[valueIndex].value}`);
      return '';
    },
    [t, translationPath]
  );
  const detailedCardActionClicked = useCallback(
    (actionEnum, item) => (event) => {
      event.stopPropagation();
      setisOpenContactsActionDialog(true);
      setdetailedCardAction({
        actionEnum,
        item,
      });
      if (actionEnum === 'whatsappSolid') {
        const el = document.createElement('a');
        if (item.mobile.phone) {
          el.href = WhatsAppMessage(item?.whatsapp_mobile?.phone || item.mobile.phone);
          el.target = 'blank';
          el.click();
        } else
          showError(t(`${translationPath}Failure-Open-WhatsApp`));
      }

      // eslint-disable-next-line no-console
    },
    []
  );

  const getAllAssociatedContact = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAssociatedContactByContactId(filter);
    if (!(res && res.status && res.status !== 200)) setAssociatedContacts(res);
    else setAssociatedContacts([]);
    setIsLoading(false);
  }, [filter])


  const tableActionClicked = useCallback((actionEnum, item) => {
    setActiveItem(item);
    if (actionEnum === TableActions.openFile.key) {
      setActiveTab(0);
      GlobalHistory.push(
        `/home/Contacts-CRM/contact-profile-edit?formType=${item.contactType}&id=${item.relatedAssociatedContactsId}&isAssociated=${true}`
      );
    }
    else if (actionEnum === TableActions.editText.key) setIsOpen(true);
    else if (actionEnum === TableActions.deleteText.key) setIsOpenDeleteAssociatedDialog(true);


  }, []);

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      event.stopPropagation();
     setActiveItem(activeData);

      if (actionEnum === ActionsEnum.reportEdit.key) {
        setIsOpen(true);
      } else if (actionEnum === ActionsEnum.folder.key) {
        setActiveTab(0);
        GlobalHistory.push(
          `/home/Contacts-CRM/contact-profile-edit?formType=${activeData.contactType}&id=${activeData.relatedAssociatedContactsId}&isAssociated=${true}`
        );
      }
      else if (actionEnum === TableActions.deleteText.key) setIsOpenDeleteAssociatedDialog(true);

    },
    [setActiveTab ]
  );
  const focusedRowChanged = useCallback(
    (rowIndex, item) => {
      if (rowIndex !== -1) {
        const currentActiveActions = defaultPrefernces;
        if (item.contactPreference && rowIndex > -1) {
          item.contactPreference.map((el) => {
            const index = Object.values(ContactPreferenceEnum).findIndex(
              (subItem) => subItem.key === el.lookupItemId
            );
            if (index !== -1) {
              currentActiveActions.push({
                enum: Object.values(ContactPreferenceEnum)[index].actionEnumKey,
              });
            }
          });
          if (currentActiveActions.length > 5) {
            const whatsAppIndex = currentActiveActions.findIndex(
              (subItem) => subItem.enum === 'whatsappSolid'
            );
            if (whatsAppIndex !== -1) {
              currentActiveActions.splice(whatsAppIndex, 1);
              currentActiveActions.push({
                enum: 'dotsHorizontal',
              });
            }
          }
          setActiveprefernces(currentActiveActions);
        }
      }
    },
    [defaultPrefernces]
  );
  const onTypeChanged = useCallback((activeType) => {
    setActiveActionType(activeType);
  }, []);
  useEffect(() => {
    if (GetParams('id')) getAllAssociatedContact();
  }, [getAllAssociatedContact]);

  useEffect(() => {
    if (sortBy)
      setFilter((item) => ({ ...item, filterBy: sortBy.filterBy, orderBy: sortBy.orderBy }));
  }, [sortBy]);

  useEffect(() => {
    setFilter((prevState) => ({
      ...prevState,
      id: +GetParams('id'),
    }));
  }, [location])

  return (
    <div className='associated-contacts-wrapper childs-wrapper p-relative'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='title-section d-flex-v-center fj-between pt-2 px-0'>
        <span className='px-2'>{t(`${translationPath}associated-contacts`)}</span>
        <ViewTypes
          onTypeChanged={onTypeChanged}
          activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
        />
      </div>
      <div>
        <ButtonBase id='action_add-new_plus' className='btns theme-solid px-3 mb-3' onClick={() => setIsOpen(true)}>
          <span className='mdi mdi-plus' />
          {t(`${translationPath}add-new`)}
        </ButtonBase>
      </div>
      <div className='w-100 px-2'>
        {
          // loginResponse &&
          //   loginResponse.permissions &&
          //   loginResponse.permissions
          //     .map((item) => item.permissionsId ===
          // ContactsPermissions.ReadContacts.permissionsId)
          //     .includes(true) &&
          activeActionType === ViewTypesEnum.cards.key && (
            <AssociatedCardsComponent
              activeTab={activeTab}
              data={associatedContacts}
              onFooterActionsClicked={detailedCardSideActionClicked}
              onActionClicked={detailedCardSideActionClicked}
              parentTranslationPath='ContactsView'
              translationPath={translationPath}
              getAllAssociatedContact={getAllAssociatedContact}
            />
          )
        }
        {
          // loginResponse &&
          //   loginResponse.permissions &&
          //   loginResponse.permissions
          //     .map((item) => item.permissionsId ===
          // ContactsPermissions.ReadContacts.permissionsId)
          //     .includes(true) &&
          activeActionType === ViewTypesEnum.tableView.key && (
            <div className='w-100'>
              <Tables
                data={associatedContacts || []}
                headerData={[
                  {
                    id: 1,
                    label: 'name',
                    input: 'RelatedContactName',
                    isSortable: true,
                    component: (item) => item.relatedContactName,
                  },
                  {
                    id: 2,
                    label: 'relationship',
                    input: 'ContactRelationshipName',
                    isSortable: true,
                    component: (item) => item.contactRelationshipName
                  },
                  {
                    id: 3,
                    label: 'creation',
                    input: 'CreatedOn',
                    isDate: true,
                    isSortable: true,
                    component: (item) => item.createdOn
                  },
                  {
                    id: 4,
                    label: 'email',
                    input: 'RelatedContactEmail',
                    isSortable: true,
                    component: (item) => item.relatedContactEmail
                  },
                  {
                    id: 5,
                    label: 'mobile',
                    input: 'RelatedContactMobile',
                    isSortable: true,
                    component: (item) => item.relatedContactMobile
                  },
                  {
                    id: 6,
                    label: 'nationality',
                    input: 'RelatedContactNationality',
                    isSortable: true,
                    component: (item) => item.relatedContactNationality
                  },
                  {
                    id: 7,
                    label: 'language',
                    input: 'RelatedContactLanguage',
                    isSortable: true,
                    component: (item) => item.relatedContactLanguage
                  },
                ]}
                defaultActions={[
                  {
                    enum: TableActions.openFile.key,
                  },
                  {
                    enum: TableActions.editText.key,
                 
                  },
                  {
                    enum: TableActions.deleteText.key,
                  }
                ]}
                actionsOptions={{
                  onActionClicked: tableActionClicked,
                }}
                itemsPerPage={filter.pageSize}
                activePage={filter.pageIndex}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                focusedRowChanged={()=> {}}
                totalItems={associatedContacts.length || 0}
                setSortBy={setSortBy}
              />
            </div>

          )
        }
      </div>
      <ContactsActionDialogsComponent
        isOpen={isOpenContactsActionDialog}
        isOpenChanged={() => setisOpenContactsActionDialog(false)}
        actionEnum={detailedCardAction.actionEnum}
        item={detailedCardAction.item}
        translationPath=''
        parentTranslationPath='ContactsView'
      />
      {isOpenDeleteAssociatedDialog && (
        <DeleteAssociatedDialog
          openDeleteDialog={isOpenDeleteAssociatedDialog}
          t={t}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          setFilter={setFilter}
          setOpenDeleteDialog={setIsOpenDeleteAssociatedDialog}
          filter={filter}
          activeItem={activeItem}
          reloadData={(() => {
            getAllAssociatedContact();
            setActiveItem(null);
          })}
        />
      )}
      {isOpen && (
        <AssociatedDiloag
          openAddDialog={isOpen}
          t={t}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          setFilter={setFilter}
          setOpenAddDialog={setIsOpen}
          filter={filter}
          activeItem={activeItem}
          allAssociated = {associatedContacts}
          reloadData={(() => {
            getAllAssociatedContact();
            setActiveItem(null);
          })}
        />
      )}
    </div>
  );
};

AssociatedContactsComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};
