
export const LeadOwnerUnitSalePermissions = 
{
  SetUnitAsSale : {
    permissionsId: "bb67080c-fe80-4406-8b05-03f12b830931",
    permissionsName: "Set Unit As Sale",
    description: null,
    componentsId: null,
    components: null
  },
  ViewDocumentsForUnit : {
    permissionsId: "c4ae6ad3-32fb-4654-996a-06393d04b9a6",
    permissionsName: "View documents for unit",
    description: null,
    componentsId: null,
    components: null
  },
  ViewSaleDetailsForUnit : {
    permissionsId: "c08062c6-d5a4-4420-8f67-0837b52a85e6",
    permissionsName: "View sale details for unit",
    description: null,
    componentsId: null,
    components: null
  },
  AddrRemindersForSoldReservedTransactions : {
    permissionsId: "bf3f9a83-0d6f-472c-92a6-0e377bacdd1c",
    permissionsName: "Add reminders for sold/reserved transactions",
    description: null,
    componentsId: null,
    components: null
  },
  ViewTransactionDetailsForUnit :{
    permissionsId: "95277374-5b4f-4a65-941f-0e8e28e56570",
    permissionsName: "View transaction details for unit",
    description: null,
    componentsId: null,
    components: null
  },
  ViewUnitMarketingInfo :{
    permissionsId: "bc8005b6-2ad6-4f0b-a578-12bd86a89ba3",
    permissionsName: "View unit marketing info",
    description: null,
    componentsId: null,
    components: null
  },
  ViewPricingDetailsForSoldReservedTransactions :{
    permissionsId: "9f06c4c0-6b19-4709-839d-1356052d57d6",
    permissionsName: "View pricing details for sold/reserved transactions",
    description: null,
    componentsId: null,
    components: null
  },
  ViewReferenceDetailsForUnit :  {
     permissionsId: "ad1dbe10-87d3-4424-9b1d-1381fa9f9434",
     permissionsName: "View Reference details for unit",
     description: null,
     componentsId: null,
     components: null
  },
  AddNewRemarksForUnit :{
     permissionsId: "f95c19ee-461a-4fd3-891a-272a699ac8a1",
     permissionsName: "Add new remarks for unit",
     description: null,
     componentsId: null,
     components: null
  },
  ViewOpenHouseForUnit : {
    permissionsId: "56f58244-e085-4c64-8b4b-2adae2843d8c",
    permissionsName: "View open house for unit",
    description: null,
    componentsId: null,
    components: null
  },
  ViewUnitTemplates : {
    permissionsId: "6839dd80-db7f-433f-9343-2d3b56f55378",
    permissionsName: "View unit templates",
    description: null,
    componentsId: null,
    components: null
  },
  AddParkingForUnit : {
    permissionsId: "a8d6fcf1-b61d-4cab-b2b6-2fba718bc209",
    permissionsName: "Add parking for unit",
    description: null,
    componentsId: null,
     components: null
  },
  ViewPaymentPlansForUnit :  {
    permissionsId: "b8014c87-9dde-4b03-a683-32b8ddfe41e8",
    permissionsName: "View payment plans for unit",
    description: null,
    componentsId: null,
     components: null
  },
  CreateUnitOwner : {
    permissionsId: "efa48142-19ea-4245-bfe6-359696d52f7c",
    permissionsName: "create Unit Owner",
    description: null,
    componentsId: null,
     components: null
  },
  ViewUnitHistory : {
    permissionsId: "24533d70-cce3-4d08-a0cd-36ab8732fe97",
    permissionsName: "View unit history",
    description: null,
    componentsId: null,
     components: null
  },
  EditHotDealMarketing :  {
    permissionsId: "b48770e1-7376-458d-be88-384cfd3092bf",
    permissionsName: "Edit Hot Deal Marketing",
    description: null,
    componentsId: null,
     components: null
  },
  UeditRemarksInUnit :   {
    permissionsId: "e5e0b745-b394-428f-9abc-3d1a0ade6c96",
    permissionsName: "Uedit remarks in unit",
    description: null,
    componentsId: null,
     components: null
  },
  EditUnitImageCategory : {
    permissionsId: "5e2f39e5-efd9-401b-8bcf-3fd37efd487d",
    permissionsName: "Edit Unit Image Category",
    description: null,
    componentsId: null,
     components: null
  },
  ViewUnitLocationOnMap : {
    permissionsId: "39d8bb45-75b1-408a-a06c-41ee9fcdc9f2",
    permissionsName: "View unit location on map",
    description: null,
    componentsId: null,
    components: null
  },
  ViewUnitDetails : {
    permissionsId: "9c9adb18-803f-4a4a-9cab-423db764e020",
    permissionsName: "View unit details",
    description: null,
    componentsId: null,
    components: null
  },
  MakeTransactionOnUnitSaleToBeDraft : {
    permissionsId: "516ff21f-13a6-41ca-bf80-48857d1650bf",
    permissionsName: "Make Transaction On Unit Sale To Be Draft",
    description: null,
    componentsId: null,
    components: null
  },
  UpdateUnitOwner :  {
    permissionsId: "3df06dc4-c091-4cdf-8ac7-50898107a3db",
    permissionsName: "Update Unit Owner",
    description: null,
    componentsId: null,
    components: null
  },
  ViewBuyerSummaryForUnit : {
    permissionsId: "08f1b759-3527-48d2-9427-5c16ab83c3cb",
    permissionsName: "View buyer summary for unit",
    description: null,
    componentsId: null,
     components: null
  },
  ViewRemarksForUnit :{
    permissionsId: "e234f5fe-376f-4947-a460-5c7423e3ebd4",
    permissionsName: "View remarks for unit",
    description: null,
    componentsId: null,
    components: null
  },
  ViewRemindersOnSoldReservedTransactions :{
    permissionsId: "294029bd-adcd-487b-940a-5d194f0434b7",
    permissionsName: "View reminders on sold/reserved transactions",
    description: null,
    componentsId: null,
    components: null
  },
  DeleteRemarksInUnit : {
    permissionsId: "9e8a8eca-4b88-4744-953d-64e3a8c71b89",
    permissionsName: "Delete remarks in unit",
    description: null,
    componentsId: null,
    components: null
  },
  ShareUnit : {
    permissionsId: "27bf1f29-b296-43ba-b92f-671d165395fe",
    permissionsName: "Share Unit",
    description: null,
    componentsId: null,
     components: null
  },
  ViewTheActivitiesForUnit : {
    permissionsId: "a876fcc2-07fe-4150-b4b2-68ee004d91a3",
    permissionsName: "View the activities for unit",
    description: null,
    componentsId: null,
    components: null
  },
  ViewUnitMatching : {
    permissionsId: "f156823e-a192-4764-a6e0-6b3c23d77bbd",
    permissionsName: "View Unit Matching",
    description: null,
    componentsId: null,
    components: null
  },
  UnitBulkUpdate : {
    permissionsId: "18626bd1-6d6f-4e1c-8392-6dc8f704d5a6",
    permissionsName: "Unit bulk update",
    description: null,
    componentsId: null,
    components: null
  },
  UpdateOpenHouseForUnit : {
    permissionsId: "564a51b9-bbc1-4ce9-bc6c-7093fc28efee",
    permissionsName: "Update open house for unit",
    description: null,
    componentsId: null,
    components: null
  },
  ViewUnitImages :{
    permissionsId: "6f1edbfb-8680-41a4-a051-7d321fd8e86b",
    permissionsName: "View unit images",
    description: null,
    componentsId: null,
     components: null
  },
  ViewParkingInfoForUnit : {
    permissionsId: "8677b988-ff9c-4933-905d-8387179fe365",
    permissionsName: "View parking info for unit",
    description: null,
    componentsId: null,
    components: null
  },
  DeletePaymentPlanForUnit : {
    permissionsId: "917423c1-0ec4-4755-8850-84e953148e97",
    permissionsName: "Delete payment plan for unit",
    description: null,
    componentsId: null,
    components: null
  },
  SetUnitAsReserveForSale : {
    permissionsId: "7ada998b-2678-4c80-a8b6-88fe074efd74",
    permissionsName: "Set Unit As Reserve For Sale",
    description: null,
    componentsId: null,
    components: null
  },
  EditReferenceDetailsForUnit : {
    permissionsId: "e25653c7-8b8e-4018-a512-8a4cde8f5da6",
    permissionsName: "Edit reference details for unit",
    description: null,
    componentsId: null,
    components: null
  },
  EditUnitMarketingInfo : {
    permissionsId: "4d5fee8b-fcc8-4c1c-a77d-8ec50049a0b2",
    permissionsName: "Edit unit marketing info",
    description: null,
    componentsId: null,
    components: null
  },
  MakeTransactionOnUnitSaleToBeAvailable : {
    permissionsId: "21df497f-72c7-4661-8e5a-91573cd286b2",
    permissionsName: "Make Transaction On Unit Sale To Be Available",
    description: null,
    componentsId: null,
    components: null
  },
  ImportUnits : {
    permissionsId: "37bc1eed-2b62-4dc0-9c20-927f09dd6134",
    permissionsName: "Import units",
    description: null,
    componentsId: null,
    components: null
  },
  DeleteParkingForUnit : {
    permissionsId: "f011f077-4454-4b71-ad01-92f72c033af8",
    permissionsName: "Delete parking for unit",
    description: null,
    componentsId: null,
    components: null
  },
  DeleteOpenHouseForUnit : {
    permissionsId: "5f8071b7-d43b-49bb-97bd-a7da6fb824eb",
    permissionsName: "Delete open house for unit",
    description: null,
    componentsId: null,
    components: null
  },
  ViewAndSearchInSaleUnits : {
    permissionsId: "b6c72827-39b6-4693-8fe7-a7dcedbb4eb1",
    permissionsName: "View and search in sale units",
    description: null,
    componentsId: null,
    components: null
  },
  OpenFileForUnitOwner :{
    permissionsId: "a589216e-bb50-4941-9af1-ac32371f47c1",
    permissionsName: "Open File For Unit Owner",
    description: null,
    componentsId: null,
    components: null
  },
  OpenUnitTemplates : {
    permissionsId: "9c3c1db5-2ce5-4350-9bf2-b18ad8e1f773",
    permissionsName: "Open unit templates",
    description: null,
    componentsId: null,
    components: null
  },
  DeleteUnitOwner : {
    permissionsId: "b56624a7-3f52-471e-8362-b192b9c1b406",
    permissionsName: "Delete Unit Owner",
    description: null,
    componentsId: null,
    components: null
  },
  EditUnitDetails : {
    permissionsId: "73a239b8-6272-41e1-849f-b42345d3d549",
    permissionsName: "Edit unit details",
    description: null,
    componentsId: null,
    components: null
  },
  EditFeaturedUnitMarketing : {
    permissionsId: "042e3a2a-3f34-49fe-aef8-b77034443a24",
    permissionsName: "Edit Featured Unit Marketing",
    description: null,
    componentsId: null,
    components: null
  },
  AddPaymentPlanOnUnit :{
    permissionsId: "a9b2ffdf-3cfe-4851-b87a-bdf45b4cd693",
    permissionsName: "Add payment plan on unit",
    description: null,
    componentsId: null,
    components: null
  },
  EditPaymentPlanForUnit : {
    permissionsId: "db244ac6-cd2e-445d-94e6-cf3b33b29b81",
    permissionsName: "Edit payment plan for unit",
    description: null,
    componentsId: null,
    components: null
  },
  EditMarketingDescription : {
    permissionsId: "af35655f-48b1-492f-a557-d88eec98404a",
    permissionsName: "Edit Marketing Description",
    description: null,
    componentsId: null,
    components: null
  },
  EdiSalesDetailsForUnit : {
    permissionsId: "2bcf3512-678e-4ca3-af70-dc8728029823",
    permissionsName: "Edit sales details for unit",
    description: null,
    componentsId: null,
    components: null
  },
  AddNewUnit : {
    permissionsId: "67663091-754d-4f68-a10e-e72974b4a575",
    permissionsName: "Add new unit",
    description: null,
    componentsId: null,
    components: null
  },
  EditParkingForUnit :  {
    permissionsId: "85ae32eb-e312-4c5b-a416-eb39ff387098",
    permissionsName: "Edit parking for unit",
    description: null,
    componentsId: null,
    components: null
  },
  EditPublishedToWebsiteMarketing :  {
     permissionsId: "5bfed340-2a80-4aff-9753-ec35f020fae9",
     permissionsName: "Edit Published To Website Marketing",
     description: null,
     componentsId: null,
     components: null
  },
  ViewSellerBuyerNamesForSoldReservedTransactions :  {
    permissionsId: "93cd6870-73ee-4952-9aad-edd2ba6203a8",
    permissionsName: "View Seller/Buyer names for sold/reserved transactions",
    description: null,
    componentsId: null,
    components: null
  },
  GetAllOwnersByUnitId :{
     permissionsId: "d394d3f5-8dca-494c-9372-ef3abff5b7ae",
     permissionsName: "Get All Owners By Unit-Id",
     description: null,
     componentsId: null,
     components: null
  },
  ViewInvoicesForSoldReservedTransactions : {
    permissionsId: "8e35ecb2-3b22-446b-86e8-ef4b68edff2a",
    permissionsName: "View invoices for sold/reserved transactions",
    description: null,
    componentsId: null,
    components: null
  },
  AddNewOpenHouseForUnit : {
    permissionsId: "36cdb728-41e2-4ba8-ac1f-f15dcb26d5c2",
    permissionsName: "Add new open house for unit",
    description: null,
    componentsId: null,
    components: null
  },
  EditMarketingTitle : {
    permissionsId: "d6314802-b867-45fd-9791-f443f65c9af1",
    permissionsName: "Edit Marketing Title",
    description: null,
    componentsId: null,
    components: null
  },
  EditUspMarketing :  {
     permissionsId: "73a76d43-3590-45d6-b65f-ffbfa3caf25e",
     permissionsName: "Edit Usp Marketing",
     description: null,
     componentsId: null,
     components: null
  },

  AddNewActivity : {
    permissionsId: "e4b382eb-af22-4fd7-2874-08dbaf79a1bb",
    permissionsName: "Add New Activity",
    description: null,
    componentsId: null,
    components: null
},
EditReservedSaleTransaction :{
  permissionsId: "a6c4e0b3-a86f-4b18-2b1a-08dbca2fdea8",
  permissionsName: "Edit Reserved Sale Transaction",
  description: null,
  componentsId : null,
  components: null
} , 
AddPublishedUnit : {
   permissionsId: "bb24e251-2177-4f8f-7042-08dbf65badbd",
   permissionsName: "Add Published Unit",
   description: null,
   componentsId: null,
   components: null
},
ShowWebPortals :{
  permissionsId: "5f96c6ef-e426-4028-7044-08dbf65badbd",
  permissionsName: "Show Web Portals",
  description: null,
  componentsId: null,
  components: null
},
AgentTransactionButton : {
  permissionsId: "bcdf2e98-e4bd-4731-8877-08dc0129f7d6",
  permissionsName: "Agent Transaction Button",
  description: null,
  componentsId: null,
  components: null
},

};