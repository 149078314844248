import React, { Children } from 'react';
import { PaginationComponent, Spinner } from '../../../../../Components';
import { makeStyles } from '@material-ui/core/styles';
import UnitCard from './UnitCard';
import './UnitsByProperty.scss';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 'auto',
    alignItems: 'center',
    overflowY: 'auto',
    gap: '20px',
    maxHeight: '65vh',
  },
  title: {
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    height: 'fit-content',
    overflow: 'visible',
    flexDirection: 'column',
    width: '95%',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: '0px 10px',
  },
  content: {
    paddingBottom: '16px !important',
  },
  cover: {
    width: 151,
    height: 50,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
    margin: 'auto',
  },
  flatContentsWrapper: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    justifyItems: 'center',
    marginBottom: '10px',
    gap: '5px',
  },
  flatContentsItem: {
    backgroundColor: '#f2f2f7',
    borderRadius: '100px',
    padding: 2,
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
  },
}));

export const UnitsByProperty = ({
  result,
  totalCount,
  isLoading,
  parentTranslationPath,
  translationPath,
  pageSize,
  pageIndex,
  onPageIndexChange,
  onPageSizeChange,
  selectedCards,
  onSelectCard,
  onRemoveCard,
  propertyName,
  isLease,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.main} units-cards-container`}>
      <Spinner isActive={isLoading} isAbsolute />
      <h3 className={classes.title}>{propertyName}</h3>
      <div className={classes.container}>
        {Children.toArray(
          result.map((item) => (
            <UnitCard
              item={item}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              selectedCards={selectedCards}
              onSelectCard={onSelectCard}
              onRemoveCard={onRemoveCard}
              isLease={isLease}
            />
          ))
        )}
      </div>
      <PaginationComponent
        totalCount={totalCount}
        wrapperClasses={classes.paginationContainer}
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPageIndexChanged={onPageIndexChange}
        onPageSizeChanged={onPageSizeChange}
      />
    </div>
  );
};

export default UnitsByProperty;
