import { AgreementDetails , AuditAndCompliance  , DeveloperDocumentsComponent  ,CommissionDetailsView   } from '../AgreementsAndCompliance' ; 
export const AgreementsAndComplianceVerticalTabsData = [
 {
   label: 'agreement-details',
   component: AgreementDetails ,
 },
 {
   label: 'audit-and-compliance',
   component: AuditAndCompliance ,

 },
 {
  label: 'commission-details' , 
  component: CommissionDetailsView ,
    
},
 
 {
   label: 'documents' , 
   component: DeveloperDocumentsComponent ,
     
 },
 
] ;



